import React, { useState } from 'react';
import { Button, Form, Input, Upload } from 'antd';
import { useSelector, useDispatch } from 'react-redux';


import { CreateLightNtfThunk } from '../../Store/Thunks/CreateLightNtfThunk';
import { EditLightNtfThunk } from '../../Store/Thunks/EditLightNtfThunk';

import { AipGetLightNtfById } from '../../Services';
import { useEffect } from 'react';

import {CoreNfModal} from '../../Modals/CoreNfModal';

export function CreateLightNtfModal({ element }) {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [stateError, setStateError] = useState(null);
    const error = useSelector((state) => {
        return state.CreateLightNtfReducer.light_ntf_error;
    });

    const edit_error = useSelector((state) => {
        return state.EditLightNtfReducer.edit_light_ntf_error;
    });

    const create_light_ntf_success = useSelector((state) => {
        return state.CreateLightNtfReducer.light_ntf_success;
    });

    const edit_light_ntf_success = useSelector((state) => {
        return state.EditLightNtfReducer.edit_light_ntf_success;
    });


    const [fileList, setFileList] = useState([]);
    const [img, setImage] = useState();

    const onFinish = (values) => {
        if (element && element?.id) {
            if(img === null){
                setImage(1);
            }
            dispatch(EditLightNtfThunk(element.id, values.name, values.risk, img, values.collectionSlug));
        }
        else {
            dispatch(CreateLightNtfThunk(values.name, values.risk, img, values.collectionSlug));
        }


    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            setStateError(null)
            form.resetFields();
            setFileList([]);
        }

    };


    const onChange = ({ fileList: newFileList }) => {
        if(!newFileList.length){
            setImage(0);
        }
        setFileList(newFileList);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const beforeUpload = (file) => {
        setImage(file)
        return false;
    }


    useEffect(() => {
        if (element && element?.id) {
            AipGetLightNtfById(element?.id)
                .then((result) => {
                    if(result?.data?.data?.image){
                        setFileList([{
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            url: result?.data?.data?.image,
                        },
                        ])
                    }
                  
                    form.setFieldsValue({
                        name: result?.data?.data?.name,
                        risk: Math.round(result?.data?.data?.defaultRisk),
                        image: result?.data?.data?.image,
                        collectionSlug: result?.data?.data?.collectionSlug,
                    });
                })
                .catch((error) => { });
        }

    }, [element]);


    useEffect(() => {
        if (error && !element) {
            setStateError(error)
        }
    }, [error, element]);

    useEffect(() => {
        if (edit_error && element) {
            setStateError(edit_error)
        }

    }, [edit_error, element]);

    useEffect(() => {
        if (create_light_ntf_success) {
            form.resetFields();
            setFileList([]);
        }
    }, [create_light_ntf_success]);

    useEffect(() => {
        if (edit_light_ntf_success) {
            form.resetFields();
            setFileList([]);
        }
    }, [edit_light_ntf_success]);



    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };
    useEffect(() => {
        if (!element && !element?.id) {
            form.resetFields();
            setFileList([]);
            setStateError(null);
        }

    }, [element]);



    return (
        <>
         
                <div style={{width:'80%'}}>

                    {error ?
                        <p className='error_container'>{stateError}</p> : null
                    }
                    {
                        edit_error ?
                            <p className='error_container'>{stateError}</p> : null
                    }
                    <Form
                    style={{ width: '100%' }}
                        form={form}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Image"
                            name="image"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your Image!!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('image')}
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                beforeUpload={(file) => beforeUpload(file)}
                                accept=".png,.jpg"
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your name!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('name')}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Collection Slug"
                            name="collectionSlug"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your collection slug!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('collectionSlug')}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Collection Value"
                            name="risk"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Collection Value!',
                                },
                                {
                                    pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                                    message: 'Invalid value.'
                                }
                            ]}
                            onFocus={() => deleteErrorButton('risk')}

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 12,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                {
                                    element && element.id ? 'Submit' : 'Create'
                                }

                            </Button>
                        </Form.Item>
                    </Form>
                </div>
        </>
    )
}