import { React, useState, useEffect, useRef } from 'react';

import { Button, Form, } from 'antd';

import { useDispatch, useSelector } from "react-redux";

import { ProdictRiskCalculationAsset, ProductRiskCalculationBlockchain, ProductRiskCalculationPlatform } from '../../Components/index';

import { ProductRiskCalculation} from '../../Services/index';

import { PRODUCT_RISK_CALCULATION_SUCCESS, PRODUCT_RISK_CALCULATION_ERROR } from '../../Store/Reducers/ProductRiskcalculationReducer';



export function ProductSecurityCalculationModal() {


    const assetCalcRisk = useRef({});
    const blockchainCalcRisk = useRef({});
    const platformCalcRisk = useRef({});

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const [stateError, setStateError] = useState(null);


    const calc_product_success = useSelector((state) => {
        return state.ProductRiskCalculationReducer.product_risk_calculation_success;
    });

    const calc_product_error = useSelector((state) => {
        return state.ProductRiskCalculationReducer.product_risk_calculation_error;
    });


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };
    useEffect(() => {
        if (calc_product_success) {
            form.resetFields();
            setStateError(null);

        }

    }, [calc_product_success]);


    useEffect(() => {

        if (calc_product_error) {
            setStateError(calc_product_error)

        }
        else {
            setStateError(null)
        }

    }, [calc_product_error])

    useEffect(() => {
        if (!calc_product_success) {
            setStateError(null)
            form.resetFields();
        }
    }, [calc_product_success]);
    return (
        <>
            <div>
                <div className='calc__modal'>
                    <div>
                        {
                            calc_product_error ?
                                <p className='error_container'>{stateError}</p> : null
                        }
                        <h2 className='calc_modal_description_title'>Product Risk calculation</h2>
                        <div className='calc_modal_title'>
                            <div style={{ display: 'flex' }}>
                                <b style={{ color: '#0070c0', margin: '0 8px' }}>Product Risk</b>  = <b style={{ color: '#0070c0', marginLeft: '5px' }}>Asset Risk </b>
                                <b style={{ margin: '0 5px' }}>×</b>
                                <ProdictRiskCalculationAsset
                                    assetCalcProps={((event) => {
                                        assetCalcRisk.current = event;
                                    })} />
                                <b style={{ margin: '0 5px' }}>+ </b><b style={{ color: '#0070c0', margin: '0 5px' }}>Blockchain Risk </b><b style={{ margin: '0 5px' }}>×</b>

                                <ProductRiskCalculationBlockchain
                                    blockchainCalcProps={((event) => {
                                        blockchainCalcRisk.current = event;
                                    })}
                                />
                                <b style={{ margin: '0 5px' }}>+</b> <b style={{ color: '#0070c0', margin: '0 8px' }}>Platform Risk </b>
                                <b style={{ margin: '0 5px' }}>×</b>
                                <ProductRiskCalculationPlatform
                                    platformCalcProps={((event) => {
                                        platformCalcRisk.current = event;
                                    })}
                                />
                                <p style={{ marginTop: '10px', marginLeft: "5px" }}>.</p>
                            </div>

                        </div>
                        <Button type="primary" htmlType="submit"
                            style={{ margin: '10px 0' }}
                            onClick={(() => {
                                const dataArr = [assetCalcRisk.current, blockchainCalcRisk.current, platformCalcRisk.current]
                               const a = dataArr.filter(item => Object.keys(item).length && item.scoreDividend && item.scoreDivisor) 
                                ProductRiskCalculation(dataArr.filter(item => Object.keys(item).length && item.scoreDividend && item.scoreDivisor))
                                    .then((result) => {
                                        dispatch({
                                            type: PRODUCT_RISK_CALCULATION_SUCCESS
                                        })
                                    })
                                    .catch((error) => {
                                        dispatch({
                                            type: PRODUCT_RISK_CALCULATION_ERROR,
                                            payload: {
                                                error: error?.response?.data?.error?.message,
                                            }
                                        })
                                    })
                            })}>Submit</Button>
                    </div>
                </div>
            </div>
        </>
    )
}