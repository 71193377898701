export const LISTING_ASSETS_LIST = "LISTING_ASSETS_LIST";
export const LISTING_SINGLE_ASSET = "LISTING_SINGLE_ASSET";
export const LISTING_SUCCESS = "LISTING_SUCCESS";
export const LISTING_CREATING_ASSET = "LISTING_CREATING_ASSET";
export const LISTING_UPDATING_ASSET = "LISTING_UPDATING_ASSET";
export const LISTING_DELETING_ASSET = "LISTING_DELETING_ASSET";
export const LISTING_ASSET_ERROR = "LISTING_ASSET_ERROR";
export const LISTING_ASSET_RESET_ERROR = "LISTING_ASSET_RESET_ERROR";

const listingInitialState = {
  assetsList: [],
  singleAsset: [],
  created: false,
  updated: false,
  deleted: false,
  singleAssetErrors: "",
};

export function ListingAssetsReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_ASSETS_LIST:
      return {
        ...state,
        assetsList: payload.result,
        created: false,
        updated: false,
        deleted: false,
      };
    case LISTING_SINGLE_ASSET:
      return {
        ...state,
        singleAsset: payload.result,
      };
    case LISTING_CREATING_ASSET:
      return {
        ...state,
        created: true,
      };
    case LISTING_UPDATING_ASSET:
      return {
        ...state,
        updated: true,
      };
    case LISTING_DELETING_ASSET:
      return {
        ...state,
        deleted: true,
      };
    case LISTING_ASSET_ERROR:
      return {
        ...state,
        singleAssetErrors: payload?.errors,
      };
    case LISTING_ASSET_RESET_ERROR:
      return {
        ...state,
        singleAssetErrors: "",
      };
    default:
      return state;
  }
}
