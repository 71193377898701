import React, { useState, useEffect } from 'react';
import { Avatar, List, Button, Modal } from 'antd';

import { CreateLightNtfModal } from '../../Modals/index';

import { useSelector, useDispatch } from 'react-redux';

import { GetLightNtfThunk } from '../../Store/Thunks/GetLightNtfReducerThunk';

import {CLOSE_LIGHT_NTF_CALC_MODAL_CLOSE_ERROR} from '../../Store/Reducers/LightNtfCalcReducer';

export function LightDefiItemComponent({ element, deleteItem }) {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);


    const edit_light_ntf_success = useSelector((state) => {
        return state.EditLightNtfReducer.edit_light_ntf_success;
    });

    const calc_light_ntf_success = useSelector((state) => {
        return state.LightNtfCalcReducer.calc_light_ntf_success;
    });


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (edit_light_ntf_success) {
            setIsModalOpen(false);
            // dispatch(GetLightNtfThunk());
        }
    }, [edit_light_ntf_success]);

    useEffect(()=>{
        if(calc_light_ntf_success){
            setIsModalOpen(false);
        }
      
    },[calc_light_ntf_success]);

    

    const maskClosableCalcModal = () => {
        dispatch({
            type: CLOSE_LIGHT_NTF_CALC_MODAL_CLOSE_ERROR
        })
    }

    return (
        <>
            <div>
                <List.Item  className='light_defi_item'>
                    <List.Item.Meta
              
                        avatar={<Avatar src={element?.image} />}
                        title={<div style={{ marginTop:5 }}>
                            <a>{element?.name}</a>
                            <span style={{ color: "#9d9fa1" , marginLeft:10 }}>{Math.round(element?.defaultRisk)}</span>
                        </div>}
                        // description={Math.round(element?.defaultRisk)}
                    />
                    <Button onClick={showModal}>Edit</Button>
                    <Button style={{ margin: "0 10px 0" }} onClick={(() => {
                        deleteItem(element)
                    })}>Delete</Button>
                </List.Item>

                <Modal title="Edit Light NFT" width={700} visible={isModalOpen} footer={false} onCancel={handleCancel} destroyOnClose={true}  afterClose={maskClosableCalcModal}>
                    <CreateLightNtfModal element={element} />
                </Modal>
            </div>
        </>
    )
}




