import { React, useState, useEffect } from 'react';

import { Button, Form, Input } from 'antd';

import { useDispatch, useSelector } from "react-redux";

import { BlockchainRiskCalcThunk } from '../../Store/Thunks/BlockchainCalcThunk';

import { GetBlockchaiCalcParams, GetBlockchainCalcParamsById } from '../../Services/index';

import { EDIT_CLOSE_Blockchain_CALC_MODAL, CLOSE_Blockchain_CALC_MODAL } from '../../Store/Reducers/BlockchainRiskCalcReducer';

export function BlockchainRiskCalculation({ element }) {

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const [stateError, setStateError] = useState(null);


    const calc_blockchain_success = useSelector((state) => {
        return state.BlockchainRiskCalcReducer.calc_blockchain_success;
    });

    const calc_blockchain_error = useSelector((state) => {
        return state.BlockchainRiskCalcReducer.calc_blockchain_error;
    });


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    const onFinish = (values) => {
        if (element && element?.id) {
            dispatch(BlockchainRiskCalcThunk(4, values.scoreDividend, values.scoreDivisor, values.percent, values.scoreMultiplier, 'edit', element.blockchainId.id));
            dispatch({
                type: EDIT_CLOSE_Blockchain_CALC_MODAL
            })
        }
        else {
            dispatch(BlockchainRiskCalcThunk(8, values.scoreDividend, values.scoreDivisor, values.percent, values.scoreMultiplier, 'create'));
            dispatch({
                type: CLOSE_Blockchain_CALC_MODAL
            })
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            setStateError(null)
            form.resetFields();
        }
    };

    useEffect(() => {
        if (calc_blockchain_success) {
            setStateError(null);
            if (!element) {
                GetBlockchaiCalcParams()
                    .then((result) => {
                    })
            }
            else {
                GetBlockchainCalcParamsById(element?.platformId?.id)
                    .then((result) => { })
            }
        }

    }, [calc_blockchain_success]);





    useEffect(() => {

        if (calc_blockchain_error) {
            setStateError(calc_blockchain_error)

        }
        else {
            setStateError(null)
        }

    }, [calc_blockchain_error])

    useEffect(() => {
        if (!calc_blockchain_success) {
            setStateError(null)
            // form.resetFields();
        }
    }, [calc_blockchain_success]);


    useEffect(() => {

        if (!element) {
            GetBlockchaiCalcParams()
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.calcParams[0].scoreDividend || '',
                        scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor || '',
                        percent: result?.data?.data?.calcParams[0].percent || '',
                        scoreMultiplier: result?.data?.data?.calcParams[0].scoreMultiplier || ''
                    })
                })
                .catch(() => {

                })
        }
        else {
            GetBlockchainCalcParamsById(element?.blockchainId?.id)
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.scoreDividend || '',
                        scoreDivisor: result?.data?.data?.scoreDivisor || '',
                        percent: result?.data?.data?.percent || '',
                        scoreMultiplier: result?.data?.data?.scoreMultiplier || '',
                    })
                })
        }

    }, [element]);




    return (
        <>

            <div className='calc__form'>
                {
                    calc_blockchain_error ?
                        <p className='error_container'>{stateError}</p> : null
                }

                <h2 className='calc_modal_description_title'>Blockchain Risk calculation</h2>
                <Form
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className='no_stable_coin_form calc__form__modal'>

                        <b style={{ margin: '0 2px' }}> Blockchain_Risk:</b> <p style={{ margin: '0 2px' }}>Every </p>    
                        <Form.Item
                            label=""
                            name="percent"
                            rules={[{ required: false, message: 'Please input your percent!' }]}
                            onFocus={() => deleteErrorButton('percent')}
                        >
                            <Input style={{ width: '50px' }} />
                        </Form.Item> 
                         <p style={{ margin: '0 2px' }}>%</p> <p style={{ margin: '0 2px' }}>decrease in</p> <b style={{ margin: '0 2px' }}>Blockchain_Asset_Current_Price</b> <p style={{ margin: '0 2px' }}>below</p>
                        <br />
                        <div className='no_stable_coin_form calc__form__modal'>
                            <b style={{ margin: '0 2px' }}>Blockchain_Asset_7_14DaysAvg_Price</b> <p style={{ margin: '0 2px' }}>subtracts</p>   
                              <Form.Item
                                label=""
                                name="scoreDividend"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your scoreDividend!',
                                    },
                                ]}
                                onFocus={() => deleteErrorButton('scoreDividend')}
                            >
                                <Input style={{ width: '50px' }} />
                            </Form.Item> <b className="score__line">/</b>
                            <Form.Item
                                name="scoreDivisor"
                                rules={[
                                    {
                                        required:false,
                                        message: 'Please input your scoreDivisor!',
                                    },
                                ]}
                                onFocus={() => deleteErrorButton('scoreDivisor')}
                            >
                                <Input style={{ width: '50px' }} />
                            </Form.Item>
                            
                              <p style={{ margin: '0 2px' }}>%</p> <p style={{ margin: '0 2px' }}>from the initial</p> <b style={{ margin: '0 2px' }}>Base_Blockchain_Risk</b> <p style={{ margin: '0 2px' }}>estimate.</p>

                        </div>
                    </div>

                    <Form.Item style={{ margin: '20px auto' }}>
                            <Button type="primary" htmlType="submit">Submit
                            </Button>
                        </Form.Item>
                </Form>
            </div>

        </>
    )
}



