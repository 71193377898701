import {
  LISTING_REALVOLUME_KEYS_FAIL,
  LISTING_REALVOLUME_KEYS_REQUEST,
  LISTING_REALVOLUME_KEYS_SUCCESS,
  LISTING_REALVOLUME_VALUE_REQUEST,
  LISTING_REALVOLUME_VALUE_SUCCESS,
  LISTING_REALVOLUME_VALUE_FAIL,
} from "../Actions";

const listingInitialState = {
  ListingRealVolumeKeyData: [],
  ListingRealVolumeKeyLoading: false,
  ListingRealVolumeKeyError: null,

  ListingRealVolumeValueData: [],
  ListingRealVolumeValueLoading: false,
  ListingRealVolumeValueError: null,
};

export function ListingRealVolumeReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_REALVOLUME_KEYS_REQUEST:
      return {
        ...state,
        ListingRealVolumeKeyLoading: true,
        ListingRealVolumeKeyData: [],
      };
    case LISTING_REALVOLUME_KEYS_SUCCESS:
      return {
        ...state,
        ListingRealVolumeKeyLoading: false,
        ListingRealVolumeKeyData: payload,
      };
    case LISTING_REALVOLUME_KEYS_FAIL:
      return {
        ...state,
        ListingRealVolumeKeyLoading: false,
        ListingRealVolumeKeyError: payload,
      };

    case LISTING_REALVOLUME_VALUE_REQUEST:
      return {
        ...state,
        ListingRealVolumeValueLoading: true,
        ListingRealVolumeValueData: [],
      };
    case LISTING_REALVOLUME_VALUE_SUCCESS:
      return {
        ...state,
        ListingRealVolumeValueLoading: false,
        ListingRealVolumeValueData: payload,
      };
    case LISTING_REALVOLUME_VALUE_FAIL:
      return {
        ...state,
        ListingRealVolumeValueLoading: false,
        ListingRealVolumeValueError: payload,
      };

    default:
      return state;
  }
}
