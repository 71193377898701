import React from 'react';


export function WalletShield() {
    return (
        <>
            <div className="tabs_container">
            Custody Shield
            </div>
        </>
    )
};