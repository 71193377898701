import React from "react";
import { Select } from 'antd';

export const APIProvider = ({ defiShieldGeneral, onChange }) => {

    return (
        <div>
              <p><span style={{marginLeft:5}}></span></p>
            <Select
                defaultValue={defiShieldGeneral}
                placeholder="Choose Provider"
                style={{
                    width: 120,
                }}
                onChange={ev => onChange(ev)}
                options={[
                    {
                        value: 'snowtrace',
                        label: 'SNOWTRACE',
                    },
                    {
                        value: 'etherscan',
                        label: 'ETHERSCAN',
                    },
                    {
                        value: 'polygonscan',
                        label: 'POLYGONSCAN',
                    },
                    {
                        value: 'trongrid',
                        label: 'TRONGRID',
                    },
                ]}
            />
        </div >
    )
} 
