import React, { useState } from 'react';

import { Button, Modal } from 'antd';

import { CreateLightNtfModal } from '../../Modals/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { GetLightNtfThunk } from '../../Store/Thunks/GetLightNtfReducerThunk';

import { LightDefiItemComponent } from '../../Components/LightDefiItem';

import { ApiDeleteLightNtf } from '../../Services';

import { CLOSE_LIGHT_NTF_CREATE_MODAL } from '../../Store/Reducers/CreateLightNTFReducer';

import { CLOSE_LIGHT_NTF_CALC_MODAL_CLOSE_ERROR } from '../../Store/Reducers/LightNtfCalcReducer';
import { CoreNfModal } from '../../Modals/CoreNfModal';


export const LightNtf = () => {
    const dispatch = useDispatch();

    const lightNtfResut = useSelector((state) => {
        return state.GetLightNtfReducer.lightNtfResult;
    });

    const create_light_ntf_success = useSelector((state) => {
        return state.CreateLightNtfReducer.light_ntf_success;
    });

    const edit_light_ntf_success = useSelector((state) => {
        return state.EditLightNtfReducer.edit_light_ntf_success;
    });

    const calc_light_ntf_success = useSelector((state) => {
        return state.LightNtfCalcReducer.calc_light_ntf_success;
    });


    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isOpenCalcModal, setIsOpenCalcModal] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showCalcModal = () => {
        setIsOpenCalcModal(true);
    }

    const maskClosable = () => {
        dispatch({
            type: CLOSE_LIGHT_NTF_CREATE_MODAL
        })
    }

    const maskClosableCalcModal = () => {
        dispatch({
            type: CLOSE_LIGHT_NTF_CALC_MODAL_CLOSE_ERROR
        })
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const calchandleCancel = () => {
        setIsOpenCalcModal(false)
    }

    useEffect(() => {
        dispatch(GetLightNtfThunk());
    }, []);

    useEffect(() => {
        if (edit_light_ntf_success) {
            dispatch(GetLightNtfThunk());
        }
    }, [edit_light_ntf_success]);


    useEffect(() => {
        if (create_light_ntf_success) {
            setIsModalOpen(false);
            dispatch(GetLightNtfThunk());
        }
    }, [create_light_ntf_success]);


    useEffect(() => {
        if (calc_light_ntf_success) {
            setIsOpenCalcModal(false);
        }

    }, [calc_light_ntf_success]);


    return (
        <>
            <div className="tabs_container">
                <div className='header__container'>
                    <Button style={{ width: '25%' }} onClick={showModal}>Create  NFT Collection</Button>
                    <Button style={{ width: '15%', marginLeft: '10px' }} onClick={showCalcModal}>Calc params</Button>
                </div>


                <Modal title="Create NFT Collection" visible={isModalOpen} footer={false} onCancel={handleCancel} afterClose={maskClosable }  destroyOnClose={true}>
                    <CreateLightNtfModal />
                </Modal>


                <Modal title="Calc params"  width={700} visible={isOpenCalcModal} footer={false} onCancel={calchandleCancel} afterClose={maskClosableCalcModal} destroyOnClose={true}>
                    <CoreNfModal />
                </Modal>
                {

                    lightNtfResut?.length ?
                        <>
                            {
                                lightNtfResut.map((element, index) => {
                                    return <LightDefiItemComponent key={index + 1} element={element} deleteItem={((element) => {
                                        ApiDeleteLightNtf(element.id)
                                            .then((result) => {
                                                if (result) {
                                                    dispatch(GetLightNtfThunk());
                                                }
                                            })
                                            .catch((error) => { })
                                    })} />
                                })

                            }

                        </>
                        :
                        <p>No Details</p>
                }

            </div>
        </>
    )

}