import {
  LISTING_GENERAL_KEYS_REQUEST,
  LISTING_GENERAL_KEYS_SUCCESS,
  LISTING_GENERAL_KEYS_FAIL,
  LISTING_GENERAL_VALUE_REQUEST,
  LISTING_GENERAL_VALUE_SUCCESS,
  LISTING_GENERAL_VALUE_FAIL,
} from "../Actions";

const listingInitialState = {
  ListingGeneralKeyData: [],
  ListingGeneralKeyLoading: false,
  ListingSecurityKeyError: null,

  ListingGeneralValueData: [],
  ListingGeneralValueLoading: false,
  ListingGeneralValueError: null,
};

export function ListingGeneralReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_GENERAL_KEYS_REQUEST:
      return {
        ...state,
        ListingGeneralKeyLoading: true,
        ListingGeneralKeyData: [],
      };
    case LISTING_GENERAL_KEYS_SUCCESS:
      return {
        ...state,
        ListingGeneralKeyLoading: false,
        ListingGeneralKeyData: payload,
      };
    case LISTING_GENERAL_KEYS_FAIL:
      return {
        ...state,
        ListingGeneralKeyLoading: false,
        ListingGeneralKeyData: payload,
      };

    case LISTING_GENERAL_VALUE_REQUEST:
      return {
        ...state,
        ListingGeneralValueLoading: true,
        ListingGeneralValueData: [],
      };
    case LISTING_GENERAL_VALUE_SUCCESS:
      return {
        ...state,
        ListingGeneralValueLoading: false,
        ListingGeneralValueData: payload,
      };
    case LISTING_GENERAL_VALUE_FAIL:
      return {
        ...state,
        ListingGeneralValueLoading: false,
        ListingGeneralValueError: payload,
      };

    default:
      return state;
  }
}
