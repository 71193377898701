import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { USER_LOG_OUT } from "../Store/Actions";

const Header = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="header_box">
        <Button
          className="add_button"
          onClick={() => {
            dispatch({
              type: USER_LOG_OUT,
            });
          }}
        >
          Log out
        </Button>
      </div>
      <p style={{ textAlign: "end" }} className="version__title">
        Version 1.2
      </p>
    </>
  );
};

export default Header;
