import { React,useState,useEffect } from 'react';

import { useDispatch } from 'react-redux';


import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';

import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';

import {ApiDeleteLightDefi,ApiLightDefiAlertUpdate} from '../../Services/index';
import { Modal } from "antd";
import { useParams } from "react-router-dom";

import {UserLightDefiThunk} from '../../Store/Thunks/UserLightDefiThunk';

import chart from '../../Assets/img/icons/chart__icon.svg';
import chart_open from '../../Assets/img/icons/chart__icon__open.svg';

import OutsideClickHandler from 'react-outside-click-handler';

import {ChartModal} from '../../Modals/ChartModal';

export function UserLightDefiCard({ item }) {
    const dispatch = useDispatch();
       const { id: userIdParam } = useParams();
    const [alertvalue, setAlertValue] = useState();
    const [openProgress, setOpenProgress] = useState(false);
    const [openChart, setOpenChart] = useState(false);


    const coldprops = {
        percent: Math.round(item?.risks?.productRisk ? item?.risks?.productRisk : item.productRisk), // is require
        colorSlice: '#4DC7B5',
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const riskprops = {
        percent: Math.round(item?.risks?.productRisk ? item?.risks?.productRisk : item.productRisk), // is require
        colorSlice: '#EFB661',
        fontColor: '#EFB661',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const holdprops = {
        percent: Math.round(item?.risks?.productRisk ? item?.risks?.productRisk : item.productRisk), // is require
        colorSlice: '#F27281',
        fontColor: '#F27281',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const whiteprops = {
        percent: 0, // is require
        colorSlice: '#f0f0f3',
        fontColor: '#f0f0f3',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 178,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }



    const renderClassName = (color) => {
        switch (color) {
            case 'green':
                return {
                    className: 'card cold defi',
                    props: coldprops
                };
            case 'yellow':
                return {
                    className: 'card hold defi',
                    props: riskprops
                };
            case 'red':
                return {
                    className: 'card risky defi',
                    props: holdprops
                };
            case 'white':
                return {
                    props: whiteprops,
                    color: '#C4C4C4'
                };
        }
    }


    const renderColorClassName = (risk) => {
        if (risk <= 100 && risk >= 80) {
            return {
                className: 'card__product__number card__product__green',
            };
        }

        else if (risk < 80 && risk >= 60) {
            return {
                className: 'card__product__number card__product__yellow',
            };
        }

        else if (risk < 60) {
            return {
                className: 'card__product__number card__product__red',
            };
        }
        else if (risk === null || 0) {
            return {
                className: 'card__product__number card__product__white',
            };
        }
    }





    const handleChange = (value, e) => {
        //e.preventDefault()
        setAlertValue(value)

    }
    const alertOnChangeComplete = (value) => {
        ApiLightDefiAlertUpdate(item.id, +alertvalue,userIdParam)
            .then((result) => {
            })
            .catch((error) => {
            })
    }


    const DeFiShieldDeleteBtn = (id) => {
        Modal.confirm({
            okText: 'Delete',
            onOk: async (close) => {
                await ApiDeleteLightDefi(item?.id,userIdParam).then((date) => {
                    if (date.data.success) {
                        dispatch(UserLightDefiThunk(userIdParam))
                    }
                })
                close();
            },
            onCancel: (close) => {
                close();
            },
            content: <div>You are going to delete the Investment Product. Are you sure?</div>
        });
    };


    return (
        <>
            <div className={renderClassName(item?.risks?.trustScore)?.className}>
                <div className="card__header">
                    <div className="row">
                    <div className="chart__open__btn" onClick={(() => {
                            setOpenChart(!openChart)
                        })}>

                            {
                                openChart ?
                                    <img src={chart} alt="" className="chart__opened__btn__icon" /> :
                                    <img src={chart_open} alt="" className="chart__open__btn__icon" />

                            }


                        </div>
                        <h2 className="card__title">
                            {item.assetId?.symbol} Staking
                        </h2>
                        <div className='close__card__content' >
                            <span className="card__close" onClick={DeFiShieldDeleteBtn}>
                                <span></span><span></span>
                            </span>
                        </div>


                    </div>
                </div>
                <div className="card__price">
                    <div className="card__wallet__row">
                        <div className="card__wallet__rates">Investment</div>
                    </div>
                </div>

                <div className="card__product__wrapper light_defi_title_content">
                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Asset:<div className='title_item'><p className='card__product__title_item'>{item?.assetId?.symbol}</p></div> </div>
                        <div
                            className={renderColorClassName(Number(Math.round(item?.risks?.assetRisk)) ? Number(Math.round(item?.risks?.assetRisk)) : Number(Math.round(item?.assetId?.value)) ? Number(Math.round(item?.assetId?.value)) : Number(Math.round(item?.assetId?.defaultValue)))?.className}
                        >{Math.round(item?.risks?.assetRisk ? item?.risks?.assetRisk : item?.assetId?.value ? item?.assetId?.value : item?.assetId?.defaultValue)}</div>
                    </div>

                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Blockchain: <div className='title_item'><p className='card__product__title_item'>{item?.blockchainId?.name}</p></div></div>
                        <div className={renderColorClassName(Number(Math.round(item?.risks?.blockchainRisk)) ? Number(Math.round(item?.risks?.blockchainRisk)) : Number(Math.round(item?.blockchainId?.value)) ? Number(Math.round(item?.blockchainId?.value)) : Number(Math.round(item?.blockchainId?.defaultValue)))?.className}>
                            {Math.round(item?.blockchainId?.value ? item?.blockchainId?.value : item?.blockchainId?.defaultValue)}</div>
                    </div>
                    <div className="card__product__row">
                        <div className="card__product__title" style={{ display: 'flex', justifyContent: 'space-between', width: '215px' }}>Platform: <div className='title_item'><p className='card__product__title_item'>{item?.platformId?.name}</p></div></div>
                        <div className={renderColorClassName(Number(Math.round(item?.risk?.platformRisk)) ? Number(Math.round(item?.risk?.platformRisk)) : Number(Math.round(item?.platformId?.value)) ? Number(Math.round(item?.platformId?.value)) : Number(Math.round(item?.platformId?.defaultValue)))?.className}>
                            {Math.round(item?.platformId?.value ? item?.platformId?.value : item?.platformId?.defaultValue)}</div>
                    </div>
                </div>
                <div className="card__risk" style={{ marginTop: '25px' }}>
                    <h3 className="card__risk__title">Product Risk</h3>
                    <div className={'card__risk__alert' + ' ' + (openProgress ? 'active' : 'card__risk__alert')}
                        onClick={((event) => {
                            if (openProgress) {
                                alertOnChangeComplete()
                            }
                            setOpenProgress(!openProgress)


                        })}>
                        <p className="card__risk__alert__title">Alert</p>
                        <p className={'card__risk__alert__number' + ' ' + (alertvalue === 0 || null ? 'card__risk__alert__not_number' : 'card__risk__alert__number')}>{alertvalue === null || 0 ? 0 : alertvalue}</p>
                    </div>
                    {
                        openProgress ?
                            <div className={'card__risk__range active card__slider' + ' ' + (alertvalue === 0 || null ? 'card__slider_not_value' : 'card__risk__range active card__slider')}>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={alertvalue === null || 0 ? 0 : alertvalue}
                                    orientation='vertical'
                                    onChange={handleChange}
                                    tooltip={true}
                                    onChangeComplete={alertOnChangeComplete}
                                    step={1}
                                />
                            </div> : ''
                    }
                    {item && item?.risks?.trustScore ?
                        <div className="card__risk__chart">
                            <CircularProgressBar {...renderClassName(item?.risks?.trustScore)?.props}>
                                <div className='card__round__shadow'></div>
                                <div className='card__risk__chart__min__wrapper'>
                                    <p className="card__risk__chart__number">{renderClassName(item?.risks?.trustScore)?.props.percent}</p>
                                    <p className="card__risk__chart__number__of">of 100</p>
                                </div>
                            </CircularProgressBar>
                        </div>
                        : null
                    }

                    <div className="card__risk__info" >
                        <i className="icon-info-icon"></i>
                    </div>
                </div>
            </div>
            <OutsideClickHandler onOutsideClick={(event) => {
             setOpenChart(false)

            }}>
                {
                    openChart ?

                        <ChartModal element={item} closeModal={(() => {
                            setOpenChart(false)
                        })} />

                        : ''
                }
            </OutsideClickHandler>
        </>
    )
}



