import {CREATE_PARTNER_GUARD_SUCCESS,CREATE_PARTNER_GUARD_LOADING,CREATE_PARTNER_GUARD_ERROR} from '../Reducers/CreatePartnerGuardReducer';

import {ApiCreatePartnerGuard} from '../../Services/index';



export function CreatePartnerGuardThunk(name, risk, image,tokenId,token) {
    return (dispatch, getState) => {
        dispatch({
            type: CREATE_PARTNER_GUARD_LOADING
        });

        ApiCreatePartnerGuard(name,+risk,image,tokenId,token)
            .then((result) => {
                dispatch({
                    type: CREATE_PARTNER_GUARD_SUCCESS
                })
            })
            .catch((error)=>{
                dispatch({
                    type:CREATE_PARTNER_GUARD_ERROR,
                    payload:{
                        error:error?.response?.data?.error?.message,
                    }
                })
            })
    }
}