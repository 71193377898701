import React, {memo, useCallback, useContext, useMemo} from 'react';
import { Slider } from 'antd';

const MySlider  =memo( ({ colorBG, value, setValue, onAfterChange }) => {

    return (
        <div className={`card__risk__range active`}>
            <Slider className={colorBG ? "slider_box" : "slider_box_auto_unstacking" }
                    value={value}
                    onChange={ (ev)=>setValue(ev) }
                    vertical
                    onAfterChange={onAfterChange}
            />
        </div>
    );
}
);

export default MySlider;
