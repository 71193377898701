export const PLATFORM_CALC_SUCCESS ='PLATFORM_CALC_SUCCESS';
export const PLATFORM_CALC_ERROR ='PLATFORM_CALC_ERROR';
export const CLOSE_PLATFORM_CALC_MODAL = 'CLOSE_PLATFORM_CALC_MODAL';
export const EDIT_CLOSE_PLATFORM_CALC_MODAL ='EDIT_CLOSE_PLATFORM_CALC_MODAL';
export const CLOSE_PLATFORM_CALC_MODAL_CLOSE_ERROR = 'CLOSE_PLATFORM_CALC_MODAL_CLOSE_ERROR';


const createPlatformCalcState={
    calc_platform_success:false,
    calc_platform_error:null,
}


export function PlatformRiskCalcReducer(state=createPlatformCalcState,action){
     if(action.type === PLATFORM_CALC_SUCCESS){
        return {
            ...createPlatformCalcState,
            calc_platform_success:true,
            calc_platform_error:null,
    
        }
    }
    else if(action.type === PLATFORM_CALC_ERROR){
        return {
            ...createPlatformCalcState,
            calc_platform_success:false,
            calc_platform_error:action.payload.error,
        }
    }
    
    else if(action.type === CLOSE_PLATFORM_CALC_MODAL){
        return {
            ...createPlatformCalcState,
            calc_platform_success:false,
            calc_platform_error:null,
    
        }
    }

    else if(action.type === EDIT_CLOSE_PLATFORM_CALC_MODAL){
        return {
            ...createPlatformCalcState,
            calc_platform_success:false,
            calc_platform_error:null,
    
        }
    }

    else if(action.type === CLOSE_PLATFORM_CALC_MODAL_CLOSE_ERROR){
        return {
            ...createPlatformCalcState,
            calc_platform_error:null,
    
        }
    }
    return state;
}