import { React, useState,useEffect } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';

import {StablecoinEditAsset,editAsset} from '../../Services/index';

import { showErrorMessage } from '../../Helpers/ErrorMessages';

import {AssetRiskCalculationModal} from '../../Modals/AssetRiskCalculationModal';

export function StableCoinEditAssetModal({closeModal}) {

    const [stableCointext,setStableCointext] = useState(true);

    const [form] = Form.useForm();

    useEffect(() => {

        let id = localStorage.getItem('assetId')
        StablecoinEditAsset(id)
            .then((result) => {
                form.setFieldsValue({
                    stableCoin:result.data?.data?.stableCoin,
                    symbol:result.data?.data?.symbol,
                    name:result.data?.data?.name,
                    defaultValue:result.data?.data?.defaultValue,
                });
                if(!result.data?.data?.stableCoin){
                    setStableCointext(false);
                }
                else {
                    setStableCointext(true);
                }
            })
            .catch((error) => { });


    }, []);



    const onFinish = async (values) => {
        let id = localStorage.getItem('assetId')
        editAsset({id:id,stableCoin:values.stableCoin,name:values.name,symbol:values.symbol, defaultValue:values.defaultValue})
        .then(((result)=>{
            closeModal(false)
        }))
        .catch((error)=>{
            const { response } = error;
            const { data } = response;
            showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]);
        })
      }

    return (
        <>
            <div className='edit_asser__modal' style={{ flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                <Form
                    name="basic"
                    form={form}
                    className="calc__form"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                >
                    <div className='stableCoin__content'>
                        <Form.Item
                            name="stableCoin"
                            label="Stable coin:"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: false,
                                    message: 'This field is required.'
                                },
                            ]}
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </div>
                    <Form.Item
                        name="symbol"
                        label="Asset Name (Symbol):"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.'
                            },
                        ]}
                    >
                        <Input placeholder='Symbol...' />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="Asset ID:"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.'
                            },
                        ]}
                    >
                        <Input placeholder="Name..." />
                    </Form.Item>


                    <Form.Item
                        name="defaultValue"
                        label="Asset Default Risk:"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required.'
                            },
                            {
                                pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                                message: 'Invalid value.'
                            }
                        ]}
                    >
                        <Input placeholder='Default value...' />
                    </Form.Item>
                    <div className="lightcore_login_submit_button">
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
                <div className='calc_modal_description' style={{ width: '100%' }}>
          {/* <AssetRiskCalculationModal element={data} /> */}
          <AssetRiskCalculationModal/>
        </div>
            </div>


        </>
    )
}