import {
  LISTING_MATURITY_KEYS_FAIL,
  LISTING_MATURITY_KEYS_REQUEST,
  LISTING_MATURITY_KEYS_SUCCESS,
  LISTING_MATURITY_VALUE_REQUEST,
  LISTING_MATURITY_VALUE_SUCCESS,
  LISTING_MATURITY_VALUE_FAIL,
} from "../Actions";

const listingInitialState = {
  ListingMaturityKeyData: [],
  ListingMaturityKeyLoading: false,
  ListingMaturityKeyError: null,

  ListingMaturityValueData: [],
  ListingMaturityValueLoading: false,
  ListingMaturityValueError: null,
};

export function ListingMaturityReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_MATURITY_KEYS_REQUEST:
      return {
        ...state,
        ListingMaturityKeyLoading: true,
        ListingMaturityKeyData: [],
      };
    case LISTING_MATURITY_KEYS_SUCCESS:
      return {
        ...state,
        ListingMaturityKeyLoading: false,
        ListingMaturityKeyData: payload,
      };
    case LISTING_MATURITY_KEYS_FAIL:
      return {
        ...state,
        ListingMaturityKeyLoading: false,
        ListingMaturityKeyData: payload,
      };

    case LISTING_MATURITY_VALUE_REQUEST:
      return {
        ...state,
        ListingMaturityValueLoading: true,
        ListingMaturityValueData: [],
      };
    case LISTING_MATURITY_VALUE_SUCCESS:
      return {
        ...state,
        ListingMaturityValueLoading: false,
        ListingMaturityValueData: payload,
      };
    case LISTING_MATURITY_VALUE_FAIL:
      return {
        ...state,
        ListingMaturityValueLoading: false,
        ListingMaturityValueError: payload,
      };

    default:
      return state;
  }
}
