import { React } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

export function DeleteAssetItem({ element, deleteItem }) {
    return (
        <>
            <tr className='dellete__asset__table_tr'>
                <td className='dellete__asset__table_td'>{element?.name}</td>
                <td className='dellete__asset__table_td'>{element?.symbol}</td>
                <td className='dellete__asset__table_td' style={{ cursor: 'pointer' }}>
                    <Popconfirm
                        title={`Delete  ${element?.name}`}
                        description="Are you sure to delete this item?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={(() => {
                            deleteItem(element?.id)
                        })}
                    >
                        <DeleteOutlined />
                    </Popconfirm>


                </td>
            </tr>

        </>
    )
}