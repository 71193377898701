export const STABLECOIN_EDIT_ASSET_SUCCESS ='STABLECOIN_EDIT_ASSET_SUCCESS';
export const STABLECOIN_EDIT_ASSET_CLOSE_MODAL ='STABLECOIN_EDIT_ASSET_CLOSE_MODAL';



const stableCoinState={
    stable_coin_success:false,
}

export function StableCoinEditAssetVisiblityReducer(state=stableCoinState,action){

    if(action.type === STABLECOIN_EDIT_ASSET_SUCCESS){
       return {
           ...stableCoinState,
           stable_coin_success:true,
   
       }
   }
   else if(action.type === STABLECOIN_EDIT_ASSET_CLOSE_MODAL){
       return {
           ...stableCoinState,
           stable_coin_success:false,
       }
   }
   return state;
}