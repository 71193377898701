import { Modal, InputNumber, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { calcParamsThunk } from "../../Store/Thunks/CreateLightNtfThunk";

export const CalcRisk = ({ visible, setVisible, calcRiskVaule, getCalcRiskVaule }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if (getCalcRiskVaule.length)
            getCalcRiskVaule.filter((item) => {
                if (item.type == calcRiskVaule) {
                    setNumerator(item.scoreDividend)
                    setDenominator(item.scoreDivisor)
                }
            })
    }, [getCalcRiskVaule])

    const [numerator, setNumerator] = useState(1)
    const [denominator, setDenominator] = useState(1)

    const Calculate = async () => {
        dispatch(calcParamsThunk(+calcRiskVaule, numerator, denominator))
    }

    return (
        <Modal visible={visible} width={720} footer={null} onCancel={() => setVisible(false)} >
          <div className='edit_asser__modal'>
            <div>
                <h2>{ }</h2>
                <InputNumber controls={false} value={numerator} onChange={(ev) => setNumerator(ev)} />
                <span style={{ fontSize: 24, margin: "0px 10px" }}>/</span>
                <InputNumber controls={false} value={denominator} onChange={(ev) => setDenominator(ev)} />
                <span style={{ fontSize: 24, margin: "0px 10px" }}></span>
                <Button onClick={Calculate} style={{ color: "#5968da" }} > Save </Button>
            </div>
            <div className='calc_modal_description'>
                <h2 className='calc_modal_description_title'>Product Risk calculation</h2>
                <p className='calc_modal_title'>
                    Product Security = Asset Risk * <span className='diferent__title'>1/3</span> + Blockchain Risk * <span className='diferent__title'>1/3</span> + Platform Risk * <span className='diferent__title'>1/3</span>

                </p>
            </div>
            </div>
        </Modal>
    )
}

