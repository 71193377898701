export const GET_PARTNER_GUARD_SUCCESS = 'GET_PARTNER_GUARD_SUCCESS';

const getPartnerGuardState = {
    partnerGuardResult: [],
}

export function GetPartnerGuardReducer(state = getPartnerGuardState, action) {
    if (action.type === GET_PARTNER_GUARD_SUCCESS) {
        return {
            ...getPartnerGuardState,
            partnerGuardResult: action.payload.result,
        }
    }
    return state;
}