// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_btn_box{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.add_button:hover {
    cursor: pointer;
}

.create_btn_box_block {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.add_button {
    color:rgb(89, 104, 218);
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/DefiShieldConnections/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".create_btn_box{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n}\n\n.add_button:hover {\n    cursor: pointer;\n}\n\n.create_btn_box_block {\n    display: flex;\n    flex-direction: column;\n    margin-right: 25px;\n}\n\n.add_button {\n    color:rgb(89, 104, 218);\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
