import { React, useState, useRef, useEffect } from 'react';

import { Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import {AssetMarketCapRiskStableCoin} from './Component/AssetMarketCapRiskStableCoin';
import {AssetPriceRiskWithStableCoins} from './Component/AssetPriceRiskWithStableCoins';

import {
    ASSET_RISK_CALCULATION_ERROR, 
    ASSET_RISK_CALCULATION_SUCCESS, 
    CLOSE_ASSET_RISK_CALCULATION_MODAL,
    EDIT_CLOSE_ASSET_RISK_CALCULATION_MODAL
} from '../../Store/Reducers/AssetRiskCalculationAllReducer';
import { STABLECOIN_EDIT_ASSET_CLOSE_MODAL } from '../../Store/Reducers/StableCoinEditAssetVisiblityReducer';

import { AssetRiskCalculation, EditAssetRiskCalculation } from '../../Services/index';


export function AssetRiskCalculationStableCoinModal({ element }) {
    const dispatch = useDispatch();
    const [stateError, setStateError] = useState(null);

    const asset_risk_calc_error = useSelector((state) => {
        return state.AssetRiskCalculationAllReducer.asset_risk_calculation_error;
    });

    const assetMarketCapRisk = useRef({});

    const assetPriceRisk = useRef({});



    useEffect(() => {

        if (asset_risk_calc_error) {
            setStateError(asset_risk_calc_error)

        }
        else {
            setStateError(null)
        }

    }, [asset_risk_calc_error]);

    return (
        <>
            <div>
                {
                    asset_risk_calc_error ?
                        <p className='error_container'>{stateError}</p> : null
                }

                <AssetPriceRiskWithStableCoins
                    element={element}
                    assetPriceRiskProps={((event) => {
                        assetPriceRisk.current = event;
                    })} />
                <br></br>
                <AssetMarketCapRiskStableCoin
                    element={element}
                    assetMarketCapRiskProps={((event) => {
                        assetMarketCapRisk.current = event;
                    })} />


                <Button type="primary" htmlType="submit"
                    style={{ margin: '10px auto', display: 'flex' }}
                    onClick={(() => {
                        if(element && element?.id){
                            dispatch({
                                type: EDIT_CLOSE_ASSET_RISK_CALCULATION_MODAL
                            }) 
                            const dataArr = [assetMarketCapRisk.current, assetPriceRisk.current]
                            const requestData = dataArr.map(item => ({
                                ...item,
                                typeId: element?.id
                            }))
                            EditAssetRiskCalculation(requestData.filter(item => Object.keys(item).length && item.percent && item.scoreMultiplier))
                            .then((result) => {
                              dispatch({
                                    type: ASSET_RISK_CALCULATION_SUCCESS
                                })
                            })
                                .catch((error) => {
                                    dispatch({
                                        type: ASSET_RISK_CALCULATION_ERROR,
                                        payload: {
                                            error: error?.response?.data?.error?.message,
                                        }
                                    })
                                })
                        }
                        else{
                            dispatch({
                                type: CLOSE_ASSET_RISK_CALCULATION_MODAL
                            }) 
                            const dataArr = [assetMarketCapRisk.current, assetPriceRisk.current]
                            AssetRiskCalculation(dataArr.filter(item => Object.keys(item).length && item.percent && item.scoreMultiplier))
                            .then((result) => {
                              dispatch({
                                    type: ASSET_RISK_CALCULATION_SUCCESS
                                })
                                dispatch({
                                    type: STABLECOIN_EDIT_ASSET_CLOSE_MODAL
                                })
                            })
                                .catch((error) => {
                                    dispatch({
                                        type: ASSET_RISK_CALCULATION_ERROR,
                                        payload: {
                                            error: error?.response?.data?.error?.message,
                                        }
                                    })
                                })
                        }
                    })}
                    >Submit</Button>

            </div>
        </>
    )
}