export const numberWithComma = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const stringToNumber = (value) => {
  // Check if the string is a valid number and does not contain any symbols
  if (/^\d+(\.\d+)?$/.test(value)) {
    return numberWithComma(parseFloat(value));
  }
  return value;
};

export const splitStringIntoTwoLines = (inputString) => {
  // Split the input string by the colon character
  const parts = inputString.split("(");

  // Check if the input string contains the colon character
  if (parts.length !== 2) {
    // throw new Error("Input string must contain exactly one colon character.");
    return inputString;
  }

  // Return the new string with two lines
  // return `${parts[0]}\r\n(${parts[1]}`;
  return (
    <div>
      <div>{parts[0]}</div>
      <div>({parts[1]}</div>
    </div>
  );
};
