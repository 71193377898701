import { React, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteAssetItem } from '../../Components/DeletAssetItem';

import { getAssets } from '../../Services';

import {AssetDeleteItemThunk} from '../../Store/Thunks/AssetDeleteItemThunk';

export function DeleteAssetModal({ visiblete }) {

    const dispatch = useDispatch();

    const [assets, setAssets] = useState([]);


    // get assets
    const getAssetsAPI = async () => {
        try {
            const response = await getAssets();
            setAssets(response.data.data);
        }
        catch (error) {
            //
        }
    }


    useEffect(() => {
        if (visiblete) {
            getAssetsAPI();
        }

    }, [visiblete]);

    return (
        <>
            <div className='asset__delete__item__content'>
                <table className='dellete__asset__table'>
                    <tr className='dellete__asset__table_tr'>
                        <th className='dellete__asset__table_th'>name</th>
                        <th className='dellete__asset__table_th'>Symbol</th>
                        <th className='dellete__asset__table_th'>Delete</th>
                    </tr>
                    {
                        assets.map((element, index) => {
                            return <DeleteAssetItem key={index + 1} element={element} 
                            deleteItem={((event) => {
                                dispatch(AssetDeleteItemThunk(event,1));
                            })} />
                        })
                    }


                </table>
            </div>
        </>
    )
}