import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Clients,
  UserInfo,
  DefiShieldConnections,
  Trash,
  LightNtf,
  WalletShield,
  PartnerGuard,
  Listing,
} from "../Pages";
import { MainLayout } from "../Layouts/MainLayout";
import { AssetsLists } from "../Components/AssetInsight/AssetsList";

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Listing />} />
          <Route path="/list" element={<AssetsLists />} />

          <Route path="/clients" element={<Clients />} />
          <Route path="/user/:id" element={<UserInfo />} />
          <Route path="/user/trash" element={<Trash />} />
          <Route path="/light-ntf" element={<LightNtf />} />
          <Route path="/wallet-shield" element={<WalletShield />} />
          <Route path="/counterparty-risk" element={<PartnerGuard />} />
          <Route path="/defi-shield" element={<DefiShieldConnections />} />
          <Route path="/login" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
