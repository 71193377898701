import { React, useEffect, useState} from 'react';

import {Form, Input } from 'antd';

import {GetAssetParams,GetAssetCalcParamsById} from '../../Services/index';


export function AssetRiskColculationMultiplierComponet({ scoreDividend, scoreDivisor, setAssetPriceRiskMultiplierProps,element}) {

    const [form] = Form.useForm();

    const [assetPriceRiskMultiplier,setAssetPriceRiskMultiplier] = useState({type:'6',scoreDividend:'',scoreDivisor:''});


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
        }
    };


    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };

useEffect(()=>{
    if(!element){
        GetAssetParams()
        .then((result)=>{
           form.setFieldsValue({
            scoreDividend:result?.data?.data?.calcParams[0].scoreDividend || '',
             scoreDivisor:result?.data?.data?.calcParams[0].scoreDivisor || '',
           })
           setAssetPriceRiskMultiplier({type:'6',scoreDividend:result?.data?.data?.calcParams[0].scoreDividend,scoreDivisor:result?.data?.data?.calcParams[0].scoreDivisor})
           scoreDivisor(result?.data?.data?.calcParams[0].scoreDivisor || '')
           scoreDividend(result?.data?.data?.calcParams[0].scoreDividend || '')
        })
    }
else {
    GetAssetCalcParamsById(element?.id,1)
    .then((result)=>{
        form.setFieldsValue({
            scoreDividend:result?.data?.data?.scoreDividend || '',
             scoreDivisor:result?.data?.data?.scoreDivisor || '',
           })
           setAssetPriceRiskMultiplier({type:'1',scoreDividend:result?.data?.data?.scoreDividend,scoreDivisor:result?.data?.data?.scoreDivisor})
           scoreDivisor(result?.data?.data?.scoreDivisor || '')
           scoreDividend(result?.data?.data?.scoreDividend || '')
    })
}
},[element]);



    return <>

        {/* <span>Asset Price Risk Multiplier</span> */}

        <Form
            form={form}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
        >
            <div className="asset__calculate_form_modal">
            <div ><b style={{ color: '#0070c0', margin:'0 8px' }}>Asset Risk</b>  = <b style={{ color: '#0070c0',margin:'0 8px' }}>Base Asset Risk <span>-</span> ( </b> </div>
                <Form.Item
                    style={{ width: '50px' }}
                    name="scoreDividend"
                    rules={[
                        {
                            required: false,
                            message: 'Please input your scoreDividend!',
                        },
                    ]}
                    onFocus={() => deleteErrorButton('scoreDividend')}

                >
                    <Input onChange={((event) => {
                        if(element){
                            scoreDividend(event.target.value)
                            const changedData = {
                                ...assetPriceRiskMultiplier,
                                type:'1',
                                scoreDividend:event.target.value,
                            }
                            setAssetPriceRiskMultiplier(changedData);
                            setAssetPriceRiskMultiplierProps(changedData);
                        }
                        else {
                            scoreDividend(event.target.value)
                            const changedData = {
                                ...assetPriceRiskMultiplier,
                                type:'6',
                                scoreDividend:event.target.value,
                            }
                            setAssetPriceRiskMultiplier(changedData);
                            setAssetPriceRiskMultiplierProps(changedData); 
                        }
                       

                    })} />
                </Form.Item> 
                <b className="score__line">/</b>
                <Form.Item
                    style={{ width: '50px' }}
                    name="scoreDivisor"
                    rules={[
                        {
                            required: false,
                            message: 'Please input your scoreDivisor!',
                        },
                    ]}
                    onFocus={() => deleteErrorButton('scoreDivisor')}
                >
                    <Input onChange={((event)=>{
                        if(element){
                            scoreDivisor(event.target.value)
                            const changedData = {
                                ...assetPriceRiskMultiplier,
                                type:'1',
                                scoreDivisor:event.target.value,
                            }
                            setAssetPriceRiskMultiplier(changedData);
                            setAssetPriceRiskMultiplierProps(changedData);
                        }
                        else{
                            scoreDivisor(event.target.value)
                        const changedData = {
                            ...assetPriceRiskMultiplier,
                            type:'6',
                            scoreDivisor:event.target.value,
                        }
                        setAssetPriceRiskMultiplier(changedData);
                        setAssetPriceRiskMultiplierProps(changedData);
                        }
                        
                    })} />
                </Form.Item>
                <b style={{ color: '#0070c0',margin:'0 0 0 8px' }}>* Asset Price Risk + </b>
            </div>
           
        </Form>
    </>
}