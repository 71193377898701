import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { LISTING_ASSET_RESET_ERROR } from "../../Store/Reducers/ListingAssetsReducer";
import {
  CreateListingAssetsThunk,
  GetListingSingleAssetThunk,
  UpdateListingAssetsThunk,
} from "../../Store/Thunks/ListingAssetsThunk";

export function ListingAction({ assetId }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { error } = useSelector(
    (state) => state.ListingAssetsReducer.singleAssetErrors
  );

  const findErrorsFromServer = () => {
    if (error) {
      if (typeof error === "string") {
        return <h2 style={{ color: "red" }}>{error}</h2>;
      } else if (error === null) {
        <h2 style={{ color: "red" }}>Not Traded on the platform</h2>;
      } else if ("message" in error && typeof error.message === "object") {
        return error.message.map((e) => (
          <p style={{ color: "red", marginBottom: "10px" }}>{e}</p>
        ));
      }
    }
  };

  const singleAssetData = useSelector(
    (state) => state.ListingAssetsReducer.singleAsset
  );

  useEffect(() => {
    dispatch({
      type: LISTING_ASSET_RESET_ERROR,
    });

    if (assetId) {
      dispatch(GetListingSingleAssetThunk(assetId));
    }
  }, []);

  useEffect(() => {
    if (assetId && singleAssetData) {
      const { assetName, assetSlug } = singleAssetData;
      form.setFieldsValue({
        assetName,
        assetSlug,
      });
    }
  }, [singleAssetData]);

  const onFinish = (values) => {
    if (!assetId) {
      dispatch(CreateListingAssetsThunk(values));
    } else {
      dispatch(UpdateListingAssetsThunk(values, assetId));
    }
  };

  return (
    <>
      {findErrorsFromServer()}
      <Form
        name="basic"
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Asset Name"
          name="assetName"
          rules={[
            { required: true, message: "Asset name is required" },
            { whitespace: true, message: "Asset name is required" },
            { max: 30, message: "Length must be less than 30 characters" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Asset Slug"
          name="assetSlug"
          rules={[
            { required: true, message: "Asset slug is required" },
            { whitespace: true, message: "Asset slug is required" },
            { max: 30, message: "Length must be less than 30 characters" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {!assetId ? "Create" : "Update"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
