// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    font-size: 48px;
    font-weight: 600;
    display: inline-block;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.74);
    box-sizing: border-box;
    animation: spotlight 2s linear infinite alternate;
}

@keyframes spotlight {
    0% , 20% {
        opacity: 1;
        letter-spacing: 2px;
    }
    80% , 100% {
        opacity: 0;
        letter-spacing: 32px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Components/Loading/loading.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,mBAAmB;IACnB,yCAAyC;IACzC,0BAA0B;IAC1B,sBAAsB;IACtB,iDAAiD;AACrD;;AAEA;IACI;QACI,UAAU;QACV,mBAAmB;IACvB;IACA;QACI,UAAU;QACV,oBAAoB;IACxB;AACJ","sourcesContent":[".loader {\n    font-size: 48px;\n    font-weight: 600;\n    display: inline-block;\n    letter-spacing: 2px;\n    font-family: Arial, Helvetica, sans-serif;\n    color: rgba(0, 0, 0, 0.74);\n    box-sizing: border-box;\n    animation: spotlight 2s linear infinite alternate;\n}\n\n@keyframes spotlight {\n    0% , 20% {\n        opacity: 1;\n        letter-spacing: 2px;\n    }\n    80% , 100% {\n        opacity: 0;\n        letter-spacing: 32px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
