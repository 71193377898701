import {
    AUTO_UNSTACKING_UPDATE_FAIL,
    AUTO_UNSTACKING_UPDATE_REQUEST,
    AUTO_UNSTACKING_UPDATE_SUCCESS,
    DE_FI_SHIELD_ALERT_UPDATE_FAIL,
    DE_FI_SHIELD_ALERT_UPDATE_REQUEST,
    DE_FI_SHIELD_ALERT_UPDATE_SUCCESS, DE_FI_SHIELD_DELETE_FAIL,
    DE_FI_SHIELD_DELETE_REQUEST,
    DE_FI_SHIELD_DELETE_SUCCESS,
    GET_DEFISHIELD_FAIL,
    GET_DEFISHIELD_REQUEST,
    GET_DEFISHIELD_SUCCESS,
    GET_USER_SHIELDS_FAIL,
    GET_USER_SHIELDS_REQUEST,
    GET_USER_SHIELDS_SUCCESS,
    UN_STAKE_FAIL,
    UN_STAKE_REQUEST,
    UN_STAKE_SUCCESS,
    USER_SHIELD_FAIL,
    USER_SHIELD_REQUEST,
    USER_SHIELD_SUCCESS,
} from "../Actions";
import {
    ApiAutoUnstackingUpdate,
    ApiDeFiShieldAlertUpdate, ApiDeFiShieldDelete,
    ApiUnStake,
    defishieldUsetListApi,
    GetUserShieldApi,
    userShieldApi
} from "../../Services";
import {showErrorMessage, showSuccessMessage} from "../../Helpers/ErrorMessages";


export const getDefishieldThunk = (id) => {
    return async (dispatch) => {
        dispatch({
            type: GET_DEFISHIELD_REQUEST
        });
        try {
            const response = await defishieldUsetListApi(id);
            const {data} = response;
            dispatch({
                type: GET_DEFISHIELD_SUCCESS,
                payload: data
            });

        } catch ({response}) {
            const {data} = response;
            showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0])
            dispatch({
                type: GET_DEFISHIELD_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
            });
        }
    }
};
export const userShieldThunk = (userId, type, allow) => {
    return async (dispatch) => {
        dispatch({
            type: USER_SHIELD_REQUEST
        });
        try {
            const response = await userShieldApi(userId, type, allow);
            const {data} = response;

            dispatch({
                type: USER_SHIELD_SUCCESS,
                payload: data
            });

        } catch ({response}) {
            const {data} = response;
            showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0])
            dispatch({
                type: USER_SHIELD_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]

            });
        }
    }
};
export const getUserShieldsThunk = (userId) => {
    return async (dispatch) => {
        dispatch({
            type: GET_USER_SHIELDS_REQUEST
        });
        try {
            const response = await GetUserShieldApi(userId);
            const {data} = response;
            dispatch({
                type: GET_USER_SHIELDS_SUCCESS,
                payload: data
            });

        } catch ({response}) {
            const {data} = response;
            showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0])
            dispatch({
                type: GET_USER_SHIELDS_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
            });
        }
    }
};
export const UnStakeThunk = (id, userId) => {
    return async (dispatch) => {
        dispatch({
            type: UN_STAKE_REQUEST
        });
        try {
            const response = await ApiUnStake(id, userId);
            showSuccessMessage("Unstake has been performed successfully.")
            const {data} = response;
            dispatch({
                type: UN_STAKE_SUCCESS,
                payload: data
            });

        } catch ({response}) {
            const {data} = response;
            showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0])
            dispatch({
                type: UN_STAKE_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
            });
        }
    }
};
export const DeFiShieldAlertUpdateThunk = (defishildId, alert, userId) => {
    return async (dispatch) => {
        dispatch({
            type: DE_FI_SHIELD_ALERT_UPDATE_REQUEST
        });
        try {
            const response = await ApiDeFiShieldAlertUpdate(defishildId, alert, userId);
            const {data} = response;
            // showSuccessMessage("Alert has been performed successfully.")

            dispatch({
                type: DE_FI_SHIELD_ALERT_UPDATE_SUCCESS,
                payload: data
            });


        } catch ({response}) {
            const {data} = response;
            dispatch({
                type: DE_FI_SHIELD_ALERT_UPDATE_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
            });
        }
    }
};
export const AutoUnstackingUpdateThunk = (defishildId, autoUnstacking, userId) => {
    return async (dispatch) => {
        dispatch({
            type: AUTO_UNSTACKING_UPDATE_REQUEST
        });
        try {
            const response = await ApiAutoUnstackingUpdate(defishildId, autoUnstacking, userId);
            const {data} = response;
            // showSuccessMessage("Auto unstacking has been performed successfully.")

            dispatch({
                type: AUTO_UNSTACKING_UPDATE_SUCCESS,
                payload: data
            });


        } catch ({response}) {
            const {data} = response;
            dispatch({
                type: AUTO_UNSTACKING_UPDATE_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
            });
        }
    }
};


// export const DeFiShieldDeleteThunk = (id, userId) => {
//     return async (dispatch) => {
//         dispatch({
//             type: DE_FI_SHIELD_DELETE_REQUEST
//         });
//         try {
//             const response = await ApiDeFiShieldDelete(id, userId);
//             const {data} = response;
//             dispatch({
//                 type: DE_FI_SHIELD_DELETE_SUCCESS,
//                 payload: data
//             });
//
//
//         } catch ({response}) {
//             const {data} = response;
//             showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0])
//             dispatch({
//                 type: DE_FI_SHIELD_DELETE_FAIL,
//                 payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
//             });
//         }
//     }
// };

