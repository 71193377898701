import React from 'react';
import  "./loading.css"
const MyLoading = () => {
    return (
        <div>
            <span className="loader">Loading...</span>
        </div>
    );
};

export default MyLoading;
