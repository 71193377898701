export const LIGHT_NTF_CALC_SUCCESS = 'LIGHT_NTF_CALC_SUCCESS';
export const LIGHT_NTF_CALC_ERROR = 'LIGHT_NTF_CALC_ERROR';
export const CLOSE_LIGHT_NTF_CALC_MODAL = 'CLOSE_LIGHT_NTF_CALC_MODAL';
export const EDIT_CLOSE_LIGHT_NTF_CALC_MODAL = 'EDIT_CLOSE_LIGHT_NTF_CALC_MODAL';
export const CLOSE_LIGHT_NTF_CALC_MODAL_CLOSE_ERROR = 'CLOSE_LIGHT_NTF_CALC_MODAL_CLOSE_ERROR';


const createLightNtfCalcState = {
    calc_light_ntf_success: false,
    calc_light_ntf_error: null,
}


export function LightNtfCalcReducer(state = createLightNtfCalcState, action) {
    if (action.type === LIGHT_NTF_CALC_SUCCESS) {
        return {
            ...createLightNtfCalcState,
            calc_light_ntf_success: true,
            calc_light_ntf_error: null,

        }
    }
    else if (action.type === LIGHT_NTF_CALC_ERROR) {
        return {
            ...createLightNtfCalcState,
            calc_light_ntf_success: false,
            calc_light_ntf_error: action.payload.error,
        }
    }

    else if (action.type === CLOSE_LIGHT_NTF_CALC_MODAL) {
        return {
            ...createLightNtfCalcState,
            calc_light_ntf_success: false,
            calc_light_ntf_error: null,

        }
    }

    else if (action.type === EDIT_CLOSE_LIGHT_NTF_CALC_MODAL) {
        return {
            ...createLightNtfCalcState,
            calc_light_ntf_success: false,
            calc_light_ntf_error: null,

        }
    }

    else if (action.type === CLOSE_LIGHT_NTF_CALC_MODAL_CLOSE_ERROR) {
        return {
            ...createLightNtfCalcState,
            calc_light_ntf_error: null,

        }
    }
    return state;
}