import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";

 export const MainLayout = ({ children }) => {

    return (
        <>
            <div className="big_container">
              <Header/>
                <div className="main_box">
                    <Sidebar/>
                    <Outlet />
                </div>
                { children }
            </div>
        </>
    );
};

