export const PRODUCT_SECURITY_CALCULATION_SUCCESS ='PRODUCT_SECURITY_CALCULATION_SUCCESS';
export const PRODUCT_SECURITY_CALCULATION_ERROR ='PRODUCT_SECURITY_CALCULATION_ERROR';
export const CLOSE_PRODUCT_SECURITY_CALCULATION_MODAL = 'CLOSE_PRODUCT_SECURITY_CALCULATION_MODAL';


const createProducetSecurityState={
    calc_security_success:false,
    calc_security_error:null,
}


export function ProductSecurityCalculationReducer(state=createProducetSecurityState,action){
     if(action.type === PRODUCT_SECURITY_CALCULATION_SUCCESS){
        return {
            ...createProducetSecurityState,
            calc_security_success:true,
            calc_security_error:null,
    
        }
    }
    else if(action.type === PRODUCT_SECURITY_CALCULATION_ERROR){
        return {
            ...createProducetSecurityState,
            calc_security_success:false,
            calc_security_error:action.payload.error,
        }
    }
    
    else if(action.type === CLOSE_PRODUCT_SECURITY_CALCULATION_MODAL){
        return {
            ...createProducetSecurityState,
            calc_security_success:false,
            calc_security_error:null,
        }
    }
    return state;
}