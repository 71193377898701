export const ASSET_DELETE_ITEM_SUCCESS = 'ASSET_DELETE_ITEM_SUCCESS';

export const ASSET_DELETE_ITEM_CLOSE_MODAL = 'ASSET_DELETE_ITEM_CLOSE_MODAL';


const assetDeleteItemStatisticState = {
    assetDeleteItemCloseModal:false,
};

export function AssetDeleteItemReducer(state=assetDeleteItemStatisticState, action) {

    if (action.type === ASSET_DELETE_ITEM_SUCCESS) {
        return {
            ...assetDeleteItemStatisticState,
            assetDeleteItemCloseModal:true,
        }
    }
    else if (action.type === ASSET_DELETE_ITEM_CLOSE_MODAL){
        return {
            ...assetDeleteItemStatisticState,
            assetDeleteItemCloseModal:false,
        }
    }

    return state;
}