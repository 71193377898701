import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login } from "../Pages";

export const AuthRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Navigate to='/login' />}/>
                <Route path='/login' element={<Login/>}/>
            </Routes>
        </BrowserRouter>
    )
};
