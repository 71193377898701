import {ASSET_DELETE_ITEM_SUCCESS}  from '../Reducers/AssetDeleteItemReducer';

import { DeleteAssetItem } from '../../Services/index';

export function AssetDeleteItemThunk(id,type) {
    return (dispatch, getState) => {

        DeleteAssetItem(id,type)
            .then((result) => {
                dispatch({
                    type: ASSET_DELETE_ITEM_SUCCESS
                })
            })
            .catch((error) => {})
    }
}