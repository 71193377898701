export const USER_LIGHT_NTF_GET_SUCCESS = 'USER_LIGHT_NTF_GET_SUCCESS';


const userLightNTFState = {
    userLightNTFResult: [],
}

export const UseLightNTFReducer = (state=userLightNTFState, action) => {

    if (action.type === USER_LIGHT_NTF_GET_SUCCESS) {
        return {
            ...userLightNTFState,
            userLightNTFResult: action.payload.result,
        }

    }
    return state;
}