import {
  LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL,
  LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST,
  LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS,
  LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST,
  LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS,
  LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL,
} from "../Actions";

const listingInitialState = {
    ListingHoldersConcentrationKeyData: [],
    ListingHoldersConcentrationKeyLoading: false,
    ListingHoldersConcentrationKeyError: null,

    ListingHoldersConcentrationValueData: [],
    ListingHoldersConcentrationValueLoading: false,
    ListingHoldersConcentrationValueError: null,

};

export function ListingHoldersConcentrationReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST:
      return {
        ...state,
        ListingHoldersConcentrationKeyLoading: true,
        ListingHoldersConcentrationKeyData: []
      };
    case LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS:
      return {
        ...state,
        ListingHoldersConcentrationKeyLoading: false,
        ListingHoldersConcentrationKeyData: payload

      };
    case LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL:
      return {
        ...state,
        ListingHoldersConcentrationKeyLoading: false,
        ListingHoldersConcentrationKeyError: payload

      };


      case LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST:
        return {
          ...state,
          ListingHoldersConcentrationValueLoading: true,
          ListingHoldersConcentrationValueData: []
        };
      case LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS:
        return {
          ...state,
          ListingHoldersConcentrationValueLoading: false,
          ListingHoldersConcentrationValueData: payload
  
        };
      case LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL:
        return {
          ...state,
          ListingHoldersConcentrationValueLoading: false,
          ListingHoldersConcentrationValueError: payload
  
        };

    default:
      return state;
  }
}
