import { React, useState,useEffect} from 'react';

import { Form, Input } from 'antd';

import {GetProtuctRiskCalculatioanBlockchainParams} from '../../Services/index';

export function ProductRiskCalculationBlockchain({ blockchainCalcProps }) {


    const [form] = Form.useForm();

    const [blockchaiCalcRisk, setblockchaiCalcRisk] = useState({ type: '2', scoreDividend: '', scoreDivisor: '' });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };

    useEffect(() => {
        GetProtuctRiskCalculatioanBlockchainParams()
            .then((result) => {
                form.setFieldsValue({
                    scoreDividend: result?.data?.data?.calcParams[0].scoreDividend || '',
                    scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor || '',
                })
                setblockchaiCalcRisk({type:'2',scoreDividend:result?.data?.data?.calcParams[0].scoreDividend,scoreDivisor:result?.data?.data?.calcParams[0].scoreDivisor})
            })
    }, []);



    return (
        <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div style={{ display: 'flex' }}>
                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="scoreDividend"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your scoreDividend!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('scoreDividend')}
                    >
                        <Input onChange={((event) => {
                      
                                const changedData = {
                                    ...blockchaiCalcRisk,
                                    type: '2',
                                    scoreDividend: event.target.value,
                                }
                                setblockchaiCalcRisk(changedData)
                                blockchainCalcProps(changedData)

                        })} />
                    </Form.Item> 
                    <b className="score__line">/</b>
                    <Form.Item
                        style={{ width: '50px' }}
                        name="scoreDivisor"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your scoreDivisor!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('scoreDivisor')}
                    >
                        <Input onChange={((event) => {
                           
                                const changedData = {
                                    ...blockchaiCalcRisk,
                                    type: '2',
                                    scoreDivisor: event.target.value,
                                }
                                setblockchaiCalcRisk(changedData)
                                blockchainCalcProps(changedData)
                        })} />
                    </Form.Item>
                </div>
            </Form>
        </>
    )
}