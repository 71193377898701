import { EDIT_LIGHT_NTF_SUCCESS, EDIT_LIGHT_NTF_LOADING, EDIT_LIGHT_NTF_ERROR } from '../Reducers/EditLightDefiReducer';
import { ApiEditLightNtf } from '../../Services';

export function EditLightNtfThunk( id,name, risk, image,collectionSlug,score ) {
    return (dispatch, getState) => {
        dispatch({
            type: EDIT_LIGHT_NTF_LOADING
        });

        ApiEditLightNtf( id,name,+risk,image,collectionSlug, score )
            .then((result) => {
                dispatch({
                    type: EDIT_LIGHT_NTF_SUCCESS
                })
            })
            .catch((error)=>{
                dispatch({
                    type:EDIT_LIGHT_NTF_ERROR,
                    payload:{
                        error:error?.response?.data?.error?.message,
                    }
                })
            })
    }
};