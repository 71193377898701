import { CREATE_LIGHT_NTF_SUCCESS, CREATE_LIGHT_NTF_LOADING, CREATE_LIGHT_NTF_ERROR } from '../Reducers/CreateLightNTFReducer';
import { ApiCreateLightNtf, calcParamsApi } from '../../Services';
import { CALE_PARAMS_FAIL, CALE_PARAMS_REQUEST, CALE_PARAMS_SUCCESS } from '../Actions';
import { showErrorMessage, showSuccessMessage } from '../../Helpers/ErrorMessages';

export function CreateLightNtfThunk(name, risk, image,collectionSlug) {
    return (dispatch, getState) => {
        dispatch({
            type: CREATE_LIGHT_NTF_LOADING
        });

        ApiCreateLightNtf(name,+risk,image,collectionSlug)
            .then((result) => {
                dispatch({
                    type: CREATE_LIGHT_NTF_SUCCESS
                })
            })
            .catch((error)=>{
                dispatch({
                    type:CREATE_LIGHT_NTF_ERROR,
                    payload:{
                        error:error?.response?.data?.error?.message,
                    }
                })
            })
    }
}

export function calcParamsThunk( type, scoreDividend, scoreDivisor ){
    return async (dispatch) => {
        dispatch({
            type: CALE_PARAMS_REQUEST
        });

        try{
            const response = await calcParamsApi( type, scoreDividend, scoreDivisor);
            const {data} = response;
            showSuccessMessage("Everything went well.")

            dispatch({
                type: CALE_PARAMS_SUCCESS,
                payload: data
            });
        }
        catch({response}){
            const {data} = response;
            showErrorMessage (typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0])
            dispatch({
                type: CALE_PARAMS_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
            });
        }
    }
}