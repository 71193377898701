import React, { useState } from "react";
import { Button, Input } from 'antd';
import { putSmartContractAddressApi } from "../../Services";
import { showErrorMessage, showSuccessMessage } from "../../Helpers/ErrorMessages";


export const SmartContractAddress = ({ defiShieldGeneral, id, tokenId, tokenIdDefault ,decimals,decimalsDefault,apiProvider,apiProviderDefault }) => {

    const [value, setValue] = useState(defiShieldGeneral)

    const submit = async () => {
        try {
            const response = await putSmartContractAddressApi(value, 
                tokenId.current ? tokenId.current : tokenIdDefault, id,
                decimals.current ? decimals.current:decimalsDefault,
                apiProvider.current ? apiProvider.current:apiProviderDefault
                )
            showSuccessMessage("Everything went well.")
        } catch (e) {
            showErrorMessage(e.response.data.error.message[0])
        }

    }

    return (
        <div>
                <p><span style={{marginLeft:5}}></span></p>
            <Input.Group compact>
                <Input onChange={ev => setValue(ev.target.value)} style={{ width: '80%',fontSize:'13px' }} defaultValue={defiShieldGeneral} />
                <Button onClick={submit} type="primary">Submit</Button>
            </Input.Group>
        </div>
    )
} 