import { React, useState, useEffect } from 'react';

import { Form, Input } from 'antd';

import { GetAssetPriceRiskCalcParams, GetAssetCalcParamsById } from '../../Services/index';


export function AssetrPriceRisk({ assetPriceRiskProps, element }) {

    const [form] = Form.useForm();

    const [assetPriceRisk, setAssetPriceRisk] = useState({ type: '4', scoreDividend: '', scoreDivisor: '', percent: '', scoreMultiplier: '' });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };



    useEffect(() => {
        if (!element) {
            GetAssetPriceRiskCalcParams()
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.calcParams[0].scoreDividend || '',
                        scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor || '',
                        percent: result?.data?.data?.calcParams[0].percent || '',
                        scoreMultiplier: result?.data?.data?.calcParams[0].scoreMultiplier || '',
                    })
                    setAssetPriceRisk({ type: '4', scoreDividend: result?.data?.data?.calcParams[0].scoreDividend, scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor, percent: result?.data?.data?.calcParams[0].percent, scoreMultiplier: result?.data?.data?.calcParams[0].scoreMultiplier })
                })
        }
        else {
            GetAssetCalcParamsById(element?.id, 1)
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.scoreDividend || '',
                        scoreDivisor: result?.data?.data?.scoreDivisor || '',
                        percent: result?.data?.data?.percent || '',
                        scoreMultiplier: result?.data?.data?.percent || '',
                    })
                    setAssetPriceRisk({
                        type: '1', scoreDividend: result?.data?.data?.scoreDividend,
                        scoreDivisor: result?.data?.data?.scoreDivisor,
                        percent: result?.data?.data?.percent,
                        scoreMultiplier: result?.data?.data?.scoreMultiplier,
                    })
                })
        }

    }, [element]);


    return (
        <>
            {/* <span>Asset Price Risk</span> */}

            <Form
                form={form}
                name="basic"
                // wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                // onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="asset__calculate_form_modal">
                    <p style={{ margin: '0 8px' }}>  <b style={{ color: '#0070c0', margin: '0 8px' }}>


                        Asset Price Risk   {
                            !element?.stableCoin ?
                                '(Non-Stable Coins):' : '(Stable Coins): '
                        }</b> <span style={{ margin: '0 3px 0 0' }}>Every</span>
                    </p>

                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="percent"
                        rules={[{ required: false, message: 'Please input your percent!' }]}
                        onFocus={() => deleteErrorButton('percent')}
                    >
                        <Input onChange={((event) => {
                            if (element) {
                                const changedData = {
                                    ...assetPriceRisk,
                                    type: '1',
                                    percent: event.target.value,
                                }
                                setAssetPriceRisk(changedData)
                                assetPriceRiskProps(changedData)
                            }
                            else {
                                const changedData = {
                                    ...assetPriceRisk,
                                    type: '4',
                                    percent: event.target.value,
                                }
                                setAssetPriceRisk(changedData)
                                assetPriceRiskProps(changedData)
                            }

                        })} />
                    </Form.Item>
                    <p style={{ marginLeft: '5px' }}>%</p>

                    <p style={{ margin: '0 8px' }}>decrease in <b style={{ color: '#0070c0', margin: '0 8px' }}> Asset Current Price  </b>below </p>
                    <b style={{ margin: '0 5px', color: '#0070c0' }}>
                        {
                            element?.stableCoin ?
                                '1 USD' : 'Asset_7_14DaysAvg_Price'
                        }
                    </b>
                    <span style={{ margin: '0 3px' }}>subtracts</span>

                    {/* <b style={{ color: '#0070c0' }}>Asset Price Risk </b> by</p> */}

                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            style={{ width: '50px', display: 'none' }}
                            label=""
                            name="scoreDividend"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your scoreDividend!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('scoreDividend')}
                        >
                            <Input onChange={((event) => {
                                if (element) {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '1',
                                        scoreDividend: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }
                                else {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '4',
                                        scoreDividend: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }

                            })} />
                        </Form.Item>
                        {/* <b className="score__line">/</b> */}
                        <Form.Item
                            style={{ width: '50px', display: 'none' }}
                            name="scoreDivisor"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your scoreDivisor!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('scoreDivisor')}
                        >
                            <Input onChange={((event) => {
                                if (element) {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '1',
                                        scoreDivisor: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }
                                else {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '4',
                                        scoreDivisor: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }


                            })} />
                        </Form.Item>

                        <Form.Item
                            style={{ width: '50px' }}
                            label=""
                            name="scoreMultiplier"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your scoreMultiplier!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('scoreMultiplier')}
                        >
                            <Input onChange={((event) => {
                                if (element) {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '1',
                                        scoreMultiplier: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }
                                else {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '4',
                                        scoreMultiplier: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }

                            })} />
                        </Form.Item>
                        <p style={{marginLeft:'5px'}}>%</p>
                    </div>
                </div>
                <p style={{ marginTop: '10px', marginLeft: "5px", fontSize: '17px', fontWeight: '500' }}>from the initial <b>Asset_Basic_Risk</b> estimate.</p>
            </Form>
            <div>

            </div>

        </>
    )
}

/////////////////////////////////////uyiyuiiuiuiuyi