import React from "react";
import { Input } from 'antd';

export const CustomInput = ({defiShieldGeneral, onChange }) => {

    return (
        <div>
                <p><span style={{marginLeft:5}}></span></p>
            <Input onChange={ev => onChange(ev.target.value)} style={{ width: '75%' }}  defaultValue={defiShieldGeneral}/>
        </div>
    )
} 