import {
  ApiGetListingRealVolumeKeys,
  ApiGetListingRealVolumeValue,
} from "../../Services";
import {
  LISTING_REALVOLUME_KEYS_FAIL,
  LISTING_REALVOLUME_KEYS_REQUEST,
  LISTING_REALVOLUME_KEYS_SUCCESS,
  LISTING_REALVOLUME_VALUE_REQUEST,
  LISTING_REALVOLUME_VALUE_SUCCESS,
  LISTING_REALVOLUME_VALUE_FAIL,
} from "../Actions";

export const ListingRealVolumeKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_REALVOLUME_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingRealVolumeKeys();
      const { data } = response;
      dispatch({
        type: LISTING_REALVOLUME_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_REALVOLUME_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingRealVolumeValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_REALVOLUME_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingRealVolumeValue();
      const { data } = response;
      dispatch({
        type: LISTING_REALVOLUME_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_REALVOLUME_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
