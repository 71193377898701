import { Modal } from 'antd';
import { Form, Input, Button } from 'antd';

import {useDispatch, useSelector} from 'react-redux';
import './modal.css';
import React, { useEffect } from 'react';
import { editBlockChain } from '../../Services';
import { showErrorMessage } from '../../Helpers/ErrorMessages';
import {BlockchainRiskCalculation} from '../../Modals/BlockchainRiskCalculationModal';
import {PlatformRiskCalculationModal} from '../../Modals/PlatformRiskCalculationModal';


import {CLOSE_Blockchain_CALC_MODAL_CLOSE_ERROR} from '../../Store/Reducers/BlockchainRiskCalcReducer';
import {CLOSE_PLATFORM_CALC_MODAL_CLOSE_ERROR} from '../../Store/Reducers/PlatformRiskCalcReducer';


export const EditBlockChain = ({ visible, setVisible, dataProps, successCb }) => {
const dispatch = useDispatch();

  const [form] = Form.useForm();

  const calc_blockchain_success = useSelector((state) => {
    return state.BlockchainRiskCalcReducer.calc_blockchain_success;
});

const calc_platform_success = useSelector((state)=>{
  return state.PlatformRiskCalcReducer.calc_platform_success;
});


  useEffect(() => {
    form.setFieldsValue({
      blockChainName: dataProps?.blockchainId?.name,
      blockChaindefaultValue: dataProps?.blockchainId?.defaultValue,
      platformName: dataProps?.platformId?.name,
      platformdefaultValue: dataProps?.platformId?.defaultValue,
      blockchainSymbol: dataProps?.blockchainId?.symbol,
      platformSymbol: dataProps?.platformId?.symbol,
    });
  }, [dataProps]);

  const onFinish = async (values) => {
    const data = {
      blockchain: {
        id: dataProps.blockchainId.id,
        name: values.blockChainName,
        defaultValue: +values.blockChaindefaultValue,
        symbol: values.blockchainSymbol,
      },
      platform: {
        id: dataProps?.platformId?.id,
        name: values?.platformName,
        defaultValue: +values?.platformdefaultValue,
        symbol: values?.platformSymbol,
      }
    };
    try {
      const response = await editBlockChain({ ...data });
      successCb();
      setVisible(false);

    }
    catch (error) {
      const { response } = error;
      const { data } = response;
      showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]);
    }
  }

  useEffect(()=>{

    if(calc_blockchain_success){
      setVisible(false)
    }
  },[calc_blockchain_success]);

  useEffect(()=>{

    if(calc_platform_success){
      setVisible(false)
    }
  },[calc_platform_success]);





  const maskClosableBlockchain = () => {
    form.resetFields();
    dispatch({
        type: CLOSE_Blockchain_CALC_MODAL_CLOSE_ERROR
    });

    
    dispatch({
      type: CLOSE_PLATFORM_CALC_MODAL_CLOSE_ERROR
  });


}

  return (
    <Modal title='Edit BlockChain/Platform' width={1160} visible={visible} footer={null} onCancel={() => setVisible(false)}   afterClose={maskClosableBlockchain}>
      <div className='edit_asser__modal'>
        <Form
          name="basic"
          form={form}
          className="lightcore_login_form edit__blochain__form"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            blockChainName: dataProps?.blockchainId?.name,
            blockChaindefaultValue: dataProps?.blockchainId?.defaultValue,
            blockchainSymbol: dataProps?.blockchainId?.symbol,
            platformName: dataProps?.platformId?.name,
            platformdefaultValue: dataProps?.platformId?.defaultValue,
            platformSymbol: dataProps?.platformId?.symbol,

          }}
          layout="vertical"
        >
          <p className='calc_modal_description_title' style={{textAlign:'center'}}>Edit BlockChain parameters</p>
          <Form.Item
            label="BlockChain Name:"
            name="blockChainName"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder='Blockchain Name...' />
          </Form.Item>

          <Form.Item
            label="Blockchain ID:"
            name="blockchainSymbol"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder='Blockchain ID...' />
          </Form.Item>

          <Form.Item
            label="BlockChain Default Risk:"
            name="blockChaindefaultValue"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
              {
                pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                message: 'Invalid value.'
              }
            ]}
          >
            <Input placeholder='BlockChain Default value...' />
          </Form.Item>
          <p className='calc_modal_description_title'  style={{textAlign:'center'}}>Edit Platform parameters</p>
          <Form.Item
            name="platformName"
            label="Platform Name:"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder='Platform Name...' />
          </Form.Item>

          <Form.Item
            label="Platform ID:"
            name="platformSymbol"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder='Platform ID...' />
          </Form.Item>

          <Form.Item
            name="platformdefaultValue"
            label="Platform Default Risk:"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
              {
                pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                message: 'Invalid value.'
              }
            ]}
          >
            <Input placeholder='Platform Default value...' />
          </Form.Item>


          <div className="lightcore_login_submit_button">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className='platform__calc__modal'>
          <BlockchainRiskCalculation element={dataProps}/>


          <div className='platform__calc__modal'style={{marginTop:'90px'}}>
         <PlatformRiskCalculationModal element={dataProps}/>
          </div>
        </div>
      </div>
    </Modal>
  );
};
