import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// css
import './login.css';

// custom modules
import { LoginThunk } from '../../Store/Thunks/LoginThunk';
import { showErrorMessage } from '../../Helpers/ErrorMessages';
import { DELETE_FAILED_STATUS } from '../../Store/Actions';
import {useNavigate} from "react-router-dom";

export const Login = () => {

    const history=useNavigate()
    // redux
    const { loginError, loading } = useSelector(store => store.LoginReducer);
    const dispatch = useDispatch();

    // submit form 
    const onFinish = (values) => {
        dispatch(LoginThunk(values));
    };

    useEffect(() => {
        if (loginError) {
            showErrorMessage(loginError);
            dispatch({
                type: DELETE_FAILED_STATUS
            });
        }
    }, [loginError]);



          return (
            <div className="big_container">
                <div style={{display:"flex",justifyContent:"center"}}>
            <Form
              name="basic"
              className="lightcore_login_form"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  }
                ]}
              >
                <Input placeholder="Email..."/>
              </Form.Item>
        
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password placeholder='Password...'/>
              </Form.Item>
            <div className="lightcore_login_submit_button">
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </div>
            </Form>
                </div>
            </div>
          );
};
