import React, { useEffect, useRef, useState } from 'react';
import { Button, Select, Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";

import './index.css';
import { CreateAssetModal } from '../../Components/Modal';
import { CreateBlockChain } from '../../Components/Modal/CreateBlockChain';
import { TableComponent } from '../Table';
import { getcalcParamsApi, getTableData } from '../../Services';
import { TableColumn } from '../../Components/TableColumn';
import { EditAssetModal } from '../../Components/Modal/EditAsset';
import { EditBlockChain } from '../../Components/Modal/EditBlockChain';
import { CalcRisk } from '../../Components/Modal/CalcRisk';
import { SmartContractAddress } from '../../Components/TableColumn/SmartContractAddress';

import { AssetRiskCalculationModal, PlatformRiskCalculationModal, BlockchainRiskCalculation, ProductSecurityCalculationModal } from '../../Modals/index';

import { CLOSE_PLATFORM_CALC_MODAL } from '../../Store/Reducers/PlatformRiskCalcReducer';
import { CLOSE_Blockchain_CALC_MODAL_CLOSE_ERROR } from '../../Store/Reducers/BlockchainRiskCalcReducer';

import { CLOSE_PRODUCT_RISK_CALCULATION_MODAL } from '../../Store/Reducers/ProductRiskcalculationReducer';

import { CustomInput } from '../../Components/CustomInput';

import { DecimalsInput } from '../../Components/DecimalsInput';

import { APIProvider } from '../../Components/APIProvider';

import { CLOSE_ASSET_CALC_MODAL_CLOSE_ERROR } from '../../Store/Reducers/AssetRiskCalculationAllReducer';


import { DeleteAssetModal } from '../../Modals/DeleteAssetsModal';

import {ASSET_DELETE_ITEM_CLOSE_MODAL} from '../../Store/Reducers/AssetDeleteItemReducer';

import {AssetRiskCalculationNoStableCoinModal} from '../../Modals/AssetRiskCalculationNoStableCoinModal';




export const DefiShieldConnections = () => {


    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [assetEditVisibe, setAssetEditVisible] = useState(false);
    const [blockChainVisible, setBlockChainVisible] = useState(false);

    const [productSecurityCalculationVisible, setproductSecurityCalculationVisible] = useState(false);

    const [deleteAssetModalVisible, setDeleteAssetModalVisible] = useState(false);


    const [blockChainVisibleEdit, setBlockChainVisibleEdit] = useState(false);
    const [calcRisk, setCalcRisk] = useState(false);
    const [calcRiskVaule, setCalcRiskVaule] = useState("");
    const [editableItem, setEditableItem] = useState({});
    const [editableItemBlockChain, setEditableItemBlockChain] = useState({});
    const [getCalcRiskVaule, setGetCalcRiskVaule] = useState([]);

    const [openAssetRiskCalculation, setOpenAssetRiskCalculation] = useState(false);
    const [openBlockchainRiskCalculation, setOpenBlockchainRiskCalculation] = useState(false);
    const [openPlatformRiskCalculation, setOpenPlatformRiskCalculation] = useState(false);

    const tokenId = useRef('');

    const decimals = useRef('');

    const apiProvider = useRef('');

    const asset_risk_calculation_success = useSelector((state) => {
        return state.AssetRiskCalculationAllReducer.asset_risk_calculation_success;
    });


    const calc_platform_success = useSelector((state) => {
        return state.PlatformRiskCalcReducer.calc_platform_success;
    });



    const calc_blockchain_success = useSelector((state) => {
        return state.BlockchainRiskCalcReducer.calc_blockchain_success;
    });



    const calc_security_success = useSelector((state) => {
        return state.ProductSecurityCalculationReducer.calc_security_success;
    });


    const calc_product_success = useSelector((state) => {
        return state.ProductRiskCalculationReducer.product_risk_calculation_success;
    });


    const stableCoinEditAssetVisiblity = useSelector((state) => {
        return state.StableCoinEditAssetVisiblityReducer.stable_coin_success;

    });


    const assetDeleteItemCloseModal = useSelector((state) => {
        return state.AssetDeleteItemReducer.assetDeleteItemCloseModal;
    });



    useEffect(() => {
        getData();
    }, []);



    useEffect(() => {
        if (assetDeleteItemCloseModal) {
            setDeleteAssetModalVisible(false);
            getData();
        }

    }, [assetDeleteItemCloseModal]);



    const getData = async () => {

        // getting table data, rendering blocks for assets/platforms/blockchains
        try {
            const response = await getTableData();
            setTableData(response.data.data.map((item, index) => {
                return ({
                    key: item.id,
                    number: <div>{index + 1 + ")"}</div>,
                    platform: <TableColumn defaultValue={item?.platformId.defaultValue} title={item?.platformId.name} type='platform' blockChainId={+item.blockchainId.id} platformId={+item?.platformId.id} cb={() => getData()} onPressEdit={() => {
                        setEditableItemBlockChain(item);
                        setBlockChainVisibleEdit(true)
                    }} />,
                    asset: <TableColumn defaultValue={item.assetId.defaultValue} title={item.assetId.symbol} type='asset' 
                    assetId={+item.assetId.id} cb={() => getData()} 
                    onPressEdit={() => {
                        setEditableItem({ ...item.assetId });
                        setAssetEditVisible(true)
                    }} />,
                    blockchain: <TableColumn defaultValue={item.blockchainId.defaultValue} title={item.blockchainId.name} type='blockchain' blockChainId={+item.blockchainId.id} platformId={+item?.platformId.id} cb={() => getData()} onPressEdit={() => {
                        setEditableItemBlockChain(item);
                        setBlockChainVisibleEdit(true)
                    }} />,


                    APIProvider: <APIProvider onChange={(val) => apiProvider.current = val} defiShieldGeneral={item.apiProvider} />,
                    tokenId: <CustomInput onChange={(val) => tokenId.current = val} defiShieldGeneral={item.apiId} />,
                    decimals: <DecimalsInput onChange={(val) => decimals.current = val} defiShieldGeneral={item.decimals} />,
                    SmartContractAddress: <SmartContractAddress defiShieldGeneral={item.contractAddress} id={item.id} decimals={decimals} tokenId={tokenId} tokenIdDefault={item.apiId}
                        decimalsDefault={item.decimals} apiProvider={apiProvider} apiProviderDefault={item.apiProvider} />
                })
            }));
        }
        catch (error) {

        }
    };

    const handleChange = (value) => {
        setCalcRiskVaule(value)
        setCalcRisk(true)
        getcalcParamsApi(calcRiskVaule).then((ev) => {
            setGetCalcRiskVaule(ev.data.data.calcParams)
        })

    };



    const cancelAsset = () => {
        setOpenAssetRiskCalculation(false);
    };

    const cancelPlatform = () => {
        setOpenPlatformRiskCalculation(false);
    };


    const cancelSecurity = () => {
        setproductSecurityCalculationVisible(false);
    }

    const cancelBlockchain = () => {
        setOpenBlockchainRiskCalculation(false);
    };

    const cencelDeletAsset = () => {
        setDeleteAssetModalVisible(false);
    }



    const maskClosablePlatform = () => {
        dispatch({
            type: CLOSE_PLATFORM_CALC_MODAL
        })
    }


    const maskClosaSecurity = () => {

        // dispatch({
        //     type: CLOSE_PRODUCT_SECURITY_CALCULATION_MODAL
        // })
        dispatch({
            type: CLOSE_PRODUCT_RISK_CALCULATION_MODAL
        })

    }

    const maskClosableBlockchain = () => {
        dispatch({
            type: CLOSE_Blockchain_CALC_MODAL_CLOSE_ERROR
        });
    }


    const makCloseAsset = () => {
        dispatch({
            type: CLOSE_ASSET_CALC_MODAL_CLOSE_ERROR
        })
    };


    const maskClosableDeleteAsset = () => {
        dispatch({
            type: ASSET_DELETE_ITEM_CLOSE_MODAL
        });

        
    }


    useEffect(() => {
        if (calc_platform_success) {
            setOpenPlatformRiskCalculation(false);
        }

    }, [calc_platform_success]);


    useEffect(() => {
        if (calc_blockchain_success) {
            setOpenBlockchainRiskCalculation(false);
            setBlockChainVisibleEdit(false)
        }

    }, [calc_blockchain_success]);

    useEffect(() => {
        if (calc_security_success) {
            setproductSecurityCalculationVisible(false);
        }

    }, [calc_security_success]);


    useEffect(() => {
        if (asset_risk_calculation_success) {
            setOpenAssetRiskCalculation(false);
            setAssetEditVisible(false)

        }

    }, [asset_risk_calculation_success]);



    useEffect(() => {
        if (calc_product_success) {
            setproductSecurityCalculationVisible(false);
        }

    }, [calc_product_success]);



    return (
        <div className="deFi_shield_connections_container" >

            <CreateAssetModal
                visible={visible}
                setVisible={setVisible}
                successCb={() => getData()}
            />

            <Modal title="Asset Risk Calculation" width={1260} visible={openAssetRiskCalculation} footer={false}
                onCancel={cancelAsset}
                destroyOnClose={true}
                afterClose={makCloseAsset}
            >
                {/* <AssetRiskCalculationModal /> */}
                <AssetRiskCalculationNoStableCoinModal/>
            </Modal>
            <Modal title="Platform Risk Calculation" width={950}
                visible={openPlatformRiskCalculation}
                footer={false} onCancel={cancelPlatform}
                afterClose={maskClosablePlatform}
                destroyOnClose={true}
            >
                <PlatformRiskCalculationModal />
            </Modal>


            <Modal title="Product Risk calculation" width={1030}
                visible={productSecurityCalculationVisible}
                footer={false} onCancel={cancelSecurity}
                afterClose={maskClosaSecurity}
                destroyOnClose={true}
            >
                <ProductSecurityCalculationModal />
            </Modal>



            <Modal title="Blockchain Risk Calculation" width={950}
                visible={openBlockchainRiskCalculation}
                footer={false}
                onCancel={cancelBlockchain}
                afterClose={maskClosableBlockchain}
                destroyOnClose={true}
            >
                <BlockchainRiskCalculation />
            </Modal>

            <EditAssetModal
                visible={assetEditVisibe}
                setVisible={setAssetEditVisible}
                successCb={() => getData()}
                data={editableItem}
            />
            <CreateBlockChain
                visible={blockChainVisible}
                setVisible={setBlockChainVisible}
                successCb={() => getData()}
            />
            <EditBlockChain
                visible={blockChainVisibleEdit}
                setVisible={setBlockChainVisibleEdit}
                successCb={() => getData()}
                dataProps={editableItemBlockChain}
            />
            <CalcRisk
                visible={calcRisk}
                setVisible={setCalcRisk}
                calcRiskVaule={calcRiskVaule}
                getCalcRiskVaule={getCalcRiskVaule}
            />

            <Modal title="Delete Asset" width={700}
                visible={deleteAssetModalVisible}
                footer={false}
                onCancel={cencelDeletAsset}
                afterClose={maskClosableDeleteAsset}
                destroyOnClose={true}
            >
                <DeleteAssetModal visiblete={deleteAssetModalVisible}  />
            </Modal>


            <div className="table_component_container">
                <div className="create_btn_box">
                    <div className="create_btn_box_block">
                        <Button className="add_button" onClick={() => setVisible(!visible)}>Create Asset</Button>
                        <Button className="add_button" onClick={() => setBlockChainVisible(!blockChainVisible)}>Create Blockchain/Platform</Button>


                        <div className='calculate__buttons'>
                            <Button className="add_button" style={{ margin: '0 35px' }} onClick={(() => {
                                setOpenAssetRiskCalculation(!openAssetRiskCalculation)
                            })}>Asset Risk Calculation</Button>
                            <Button className="add_button" style={{ margin: '0 25px' }} onClick={(() => {
                                setOpenBlockchainRiskCalculation(!openBlockchainRiskCalculation)
                            })}>Blockchain Risk Calculation</Button>
                            <Button className="add_button platform__btn" onClick={(() => {
                                setOpenPlatformRiskCalculation(!openPlatformRiskCalculation)
                            })}>Platform Risk Calculation</Button>

                        </div>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>


                        <Button className="add_button" onClick={() => setproductSecurityCalculationVisible(!productSecurityCalculationVisible)}>Product Risk Calculation</Button>
                        <Button className="add_button" onClick={() => setDeleteAssetModalVisible(!deleteAssetModalVisible)} >Delete Asset</Button>
                    </div>
                    {/* <div className='asset__select__content'>
                        <span className='asset__select__content_span'>Product Security Calculation:</span>
                        <Select
                            defaultValue="AssetRisk"
                            style={{ width: 180 }}
                            onSelect={handleChange}
                            options={[
                                {
                                    value: '1',
                                    label: 'Asset Risk',
                                },
                                {
                                    value: '2',
                                    label: 'Blockchain Risk',
                                },
                                {
                                    value: '3',
                                    label: 'Platform Risk',
                                },

                            ]}
                        />
                    </div> */}

                </div>
                <TableComponent tableData={tableData} />
            </div>
            <>
            </>
        </div>
    )
};
