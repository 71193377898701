import {
  LISTING_SECURITY_KEYS_FAIL,
  LISTING_SECURITY_KEYS_REQUEST,
  LISTING_SECURITY_KEYS_SUCCESS,
  LISTING_SECURITY_VALUE_REQUEST,
  LISTING_SECURITY_VALUE_SUCCESS,
  LISTING_SECURITY_VALUE_FAIL,
} from "../Actions";

const listingInitialState = {
  ListingSecurityKeyData: [],
  ListingSecurityKeyLoading: false,
  ListingSecurityKeyError: null,

  ListingSecurityValueData: [],
  ListingSecurityValueLoading: false,
  ListingSecurityValueError: null,
};

export function ListingSecurityReducer(
  state = listingInitialState,
  { payload, type }
) {
  switch (type) {
    case LISTING_SECURITY_KEYS_REQUEST:
      return {
        ...state,
        ListingSecurityKeyLoading: true,
        ListingSecurityKeyData: [],
      };
    case LISTING_SECURITY_KEYS_SUCCESS:
      return {
        ...state,
        ListingSecurityKeyLoading: false,
        ListingSecurityKeyData: payload,
      };
    case LISTING_SECURITY_KEYS_FAIL:
      return {
        ...state,
        ListingSecurityKeyLoading: false,
        ListingSecurityKeyData: payload,
      };

    case LISTING_SECURITY_VALUE_REQUEST:
      return {
        ...state,
        ListingSecurityValueLoading: true,
        ListingSecurityValueData: [],
      };
    case LISTING_SECURITY_VALUE_SUCCESS:
      return {
        ...state,
        ListingSecurityValueLoading: false,
        ListingSecurityValueData: payload,
      };
    case LISTING_SECURITY_VALUE_FAIL:
      return {
        ...state,
        ListingSecurityValueLoading: false,
        ListingSecurityValueError: payload,
      };

    default:
      return state;
  }
}
