export const CREATE_LIGHT_NTF_SUCCESS = 'CREATE_LIGHT_NTF_SUCCESS';
export const CREATE_LIGHT_NTF_LOADING = 'CREATE_LIGHT_NTF_LOADING';
export const CREATE_LIGHT_NTF_ERROR ='CREATE_LIGHT_NTF_ERROR';

export const CLOSE_LIGHT_NTF_CREATE_MODAL = 'CLOSE_LIGHT_NTF_CREATE_MODAL';

const createLightNtfState={
    light_ntf_success:false,
    light_ntf_error:null,
    light_ntf_loadin:false,
}

export function CreateLightNtfReducer(state=createLightNtfState,action){
if(action.type === CREATE_LIGHT_NTF_LOADING){
    return {
        ...createLightNtfState,
        light_ntf_success:false,
        light_ntf_error:null,
        light_ntf_loadin:true,

    }
}
else if(action.type === CREATE_LIGHT_NTF_SUCCESS){
    return {
        ...createLightNtfState,
        light_ntf_success:true,
        light_ntf_error:null,
        light_ntf_loadin:false,

    }
}
else if(action.type === CREATE_LIGHT_NTF_ERROR){
    return {
        ...createLightNtfState,
        light_ntf_success:false,
        light_ntf_error:action.payload.error,
        light_ntf_loadin:false,

    }
}

else if(action.type === CLOSE_LIGHT_NTF_CREATE_MODAL){
    return {
        ...createLightNtfState,
        light_ntf_success:false,
        light_ntf_error:null,
        light_ntf_loadin:false,

    }
}


    return state;

}