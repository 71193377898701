
import { React, useState, useEffect } from 'react';

import { Form, Input } from 'antd';

import { GetAssetCalcParamsById } from '../../../../Services/index';


export function AssetPriceRiskWithStableCoins({assetPriceRiskProps, element }) {

    const [form] = Form.useForm();
    const [assetPriceRisk, setAssetPriceRisk] = useState({ type: '1', percent: '', scoreMultiplier: '' });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };

    useEffect(() => {
        GetAssetCalcParamsById(element?.id, 1)
            .then((result) => {
                form.setFieldsValue({
                    percent: result?.data?.data?.percent || '',
                    scoreMultiplier: result?.data?.data?.percent || '',
                })
                setAssetPriceRisk({
                    type: '1',
                    percent: result?.data?.data?.percent,
                    scoreMultiplier: result?.data?.data?.scoreMultiplier,
                })
            })
    }, [element]);

    return (
        <>
            <div className='no_stable_coin_form'>
            <h2>
                        Asset Risk Calculation (Stable Coins)
                    </h2>
                <Form
                    style={{ display: 'flex', alignItems: 'center' }}
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                  

                    <b style={{ margin: '0 2px' }}>Asset_Price_Risk (Stable Coins):</b>  <p style={{ margin: '0 2px' }}>Every</p>   <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="percent"
                        rules={[{ required: false, message: 'Please input your percent!' }]}
                        onFocus={() => deleteErrorButton('percent')}
                    >
                        <Input style={{ margin: '0' }}
                            onChange={((event) => {

                                const changedData = {
                                    ...assetPriceRisk,
                                    type: '1',
                                    percent: event.target.value,
                                }
                                setAssetPriceRisk(changedData)
                                assetPriceRiskProps(changedData)


                            })} />
                    </Form.Item>  <p style={{ margin: '0 2px' }}>% decrease in</p> <b style={{ margin: '0 2px' }}>Asset_Current_Price below</b>
                    <p style={{ margin: '0 2px' }}>1 USD subtracts</p>
                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="scoreMultiplier"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your scoreMultiplier!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('scoreMultiplier')}
                    >
                        <Input onChange={((event) => {
                            const changedData = {
                                ...assetPriceRisk,
                                type: '1',
                                scoreMultiplier: event.target.value,
                            }
                            setAssetPriceRisk(changedData)
                            assetPriceRiskProps(changedData)


                        })} />
                    </Form.Item>
                    <p style={{ margin: '0 2px' }}>%  from the initial</p> <b style={{ margin: '0 2px' }}>Asset_Basic_Risk</b> estimate.

                </Form>
            </div>
        </>
    )
}
