import { GET_LIGHT_NTF_SUCCESS } from '../Reducers/GetLightNTFReducer';
import { ApiGetLightNtf } from '../../Services';

export function GetLightNtfThunk() {
    return (dispatch, getState) => {
        ApiGetLightNtf()
            .then((result) => {
                dispatch({
                    type: GET_LIGHT_NTF_SUCCESS,
                    payload:{
                result:result.data.data
                    }
                })
            })
            .catch((error)=>{})
    }
}