import {EDIT_PARTNER_GUARD_SUCCESS,EDIT_PARTNER_GUARD_LOADING,EDIT_PARTNER_GUARD_ERROR} from '../Reducers/EditPartnerGuardReducer';

import { ApiEditPartnerGuard } from '../../Services';

export function EditPartnerGuardThunk( id,name, risk, image,tokenId,withtoken) {
    return (dispatch, getState) => {
        dispatch({
            type: EDIT_PARTNER_GUARD_LOADING
        });

        ApiEditPartnerGuard(id,name, risk, image,tokenId,withtoken)
            .then((result) => {
                dispatch({
                    type: EDIT_PARTNER_GUARD_SUCCESS
                })
            })
            .catch((error)=>{
                dispatch({
                    type:EDIT_PARTNER_GUARD_ERROR,
                    payload:{
                        error:error?.response?.data?.error?.message,
                    }
                })
            })
    }
};

