export const CREATE_PARTNER_GUARD_SUCCESS = 'CREATE_PARTNER_GUARD_SUCCESS';
export const CREATE_PARTNER_GUARD_LOADING = 'CREATE_PARTNER_GUARD_LOADING';
export const CREATE_PARTNER_GUARD_ERROR = 'CREATE_PARTNER_GUARD_ERROR';
export const CLOSE_PARTNER_GUARD_CREATE_MODAL = 'CLOSE_PARTNER_GUARD_CREATE_MODAL';

const createPartnerGuardState = {
    partner_guard_success: false,
    partner_guard_error: null,
    partner_guard_loadin: false,
}

export function CreatePartnerGuardReducer(state = createPartnerGuardState, action) {
    if (action.type === CREATE_PARTNER_GUARD_LOADING) {
        return {
            ...createPartnerGuardState,
            partner_guard_success: false,
            partner_guard_error: null,
            partner_guard_loadin: true,

        }
    }
    else if (action.type === CREATE_PARTNER_GUARD_SUCCESS) {
        return {
            ...createPartnerGuardState,
            partner_guard_success: true,
            partner_guard_error: null,
            partner_guard_loadin: false,

        }
    }
    else if (action.type === CREATE_PARTNER_GUARD_ERROR) {
        return {
            ...createPartnerGuardState,
            partner_guard_success: false,
            partner_guard_error: action.payload.error,
            partner_guard_loadin: false,

        }
    }

    else if (action.type === CLOSE_PARTNER_GUARD_CREATE_MODAL) {
        return {
            ...createPartnerGuardState,
            partner_guard_success: false,
            partner_guard_error: null,
            partner_guard_loadin: false,

        }
    }


    return state;

}