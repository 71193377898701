import React from 'react';
import { Table } from 'antd';

export const TableComponent = ({ tableData }) => {
  const columns = [
    {
      title: '',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
    },
    {
      title: 'Blockchain',
      dataIndex: 'blockchain',
      key: 'blockchain',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'API Provider',
      dataIndex: 'APIProvider',
      key: 'APIProvider',
    },

    {
      title: 'Token ID',
      dataIndex: 'tokenId',
      key: 'tokenId',
      width: 200
    },
    {
      title: 'Decimals',
      dataIndex: 'decimals',
      key: 'decimals',
      width: 90
    },
    {
      title: 'Smart Contract Address',
      dataIndex: 'SmartContractAddress',
      key: 'SmartContractAddress',
    },
  ];

  return (
    <Table columns={columns} dataSource={tableData} pagination={false} />
  )
}