import {DeleteUserApi, loginRequest, userAccessApi, userAllApi, userDeletedAllApi} from "../../Services";
import {
    DELETE_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    LOGIN_FAIL,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_SUCESS_STATE,
    SOFT_DELETE_USER_FAIL,
    SOFT_DELETE_USER_REQUEST,
    SOFT_DELETE_USER_SUCCESS,
    USER_ACCESS_FAIL,
    USER_ACCESS_REQUEST,
    USER_ACCESS_SUCCESS,
    USER_ALL_FAIL,
    USER_ALL_REQUEST,
    USER_ALL_SUCCESS
} from "../Actions";
import {showErrorMessage} from "../../Helpers/ErrorMessages";

// login request
export const LoginThunk = (values) => {
    return async (dispatch) => {
        dispatch({
            type: LOGIN_LOADING
        });
        try {
            const response = await loginRequest(values);
            const {data} = response;
            dispatch({
                type: LOGIN_SUCCESS, 
                payload: data?.data?.access_token,
                refresh_token:data?.data?.refresh_token
            });
            if (data?.data?.access_token) {
                dispatch({
                    type: LOGIN_SUCESS_STATE,
                });
            }

        } catch ({response}) {
            const {data} = response;
            dispatch({
                type: LOGIN_FAIL,
                payload: typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]
            });
        }
    }
};



// get all users thunk
export const UserAllGetThunk = () => {
    return async (dispatch) => {
        dispatch({
            type: USER_ALL_REQUEST
        });
        try {
            const response = await userAllApi();
            const {data} = response;
            dispatch({
                type: USER_ALL_SUCCESS, payload: data
            });
        } catch ({response}) {
            const {data} = response;
            dispatch({
                type: USER_ALL_FAIL, payload: data?.error?.message[0]
            });
        }
    }
}

// user access
export const userAccessThunk = (id) => {
    return async (dispatch) => {
        dispatch({
            type: USER_ACCESS_REQUEST
        });
        try {
            const response = await userAccessApi(id);
            const {data} = response;
            dispatch({
                type: USER_ACCESS_SUCCESS, payload: data
            });

        } catch ({response}) {
            const {data} = response;
            dispatch({
                type: USER_ACCESS_FAIL, payload: data?.error?.message[0]
            });
        }
    }
};

// delete User
export const deleteUserThunk = (id, type) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_USER_REQUEST
        });
        try {
            const response = await DeleteUserApi(id, type);
            const {data} = response;
            dispatch({
                type: DELETE_USER_SUCCESS, payload: {data, type}
            });

        } catch ({response}) {
            const {data} = response;
            showErrorMessage(data?.error?.message[0])
            dispatch({
                type: DELETE_USER_FAIL, payload: data?.error?.message[0]
            });
        }
    }
};

// user list for soft delete
export const softDeleteUserThunk = () => {
    return async (dispatch) => {
        dispatch({
            type: SOFT_DELETE_USER_REQUEST
        });
        try {
            const response = await userDeletedAllApi();
            const {data} = response;
            dispatch({
                type: SOFT_DELETE_USER_SUCCESS, payload: data
            });
        } catch ({response}) {
            const {data} = response;
            dispatch({
                type: SOFT_DELETE_USER_FAIL, payload: data?.error?.message[0]
            });
        }
    }
}
