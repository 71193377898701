export const EDIT_PARTNER_GUARD_SUCCESS = 'EDIT_PARTNER_GUARD_SUCCESS';
export const EDIT_PARTNER_GUARD_LOADING = 'EDIT_PARTNER_GUARD_LOADING';
export const EDIT_PARTNER_GUARD_ERROR = 'EDIT_PARTNER_GUARD_ERROR';
export const CLOASE_MODAL_EDIT_PARTNER_GUARD ='CLOASE_MODAL_EDIT_PARTNER_GUARD';

const editPartnerGuardState={
    edit_partner_guard_success:false,
    edit_partner_guard_error:null,
    edit_partner_guard_loadin:false,
}

export function EditPartnerGuardReducer(state=editPartnerGuardState,action){
if(action.type === EDIT_PARTNER_GUARD_LOADING){
    return {
        ...editPartnerGuardState,
        edit_partner_guard_success:false,
        edit_partner_guard_error:null,
        edit_partner_guard_loadin:true,

    }
}
else if(action.type === EDIT_PARTNER_GUARD_SUCCESS){
    return {
        ...editPartnerGuardState,
        edit_partner_guard_success:true,
        edit_partner_guard_error:null,
        edit_partner_guard_loadin:false,

    }
}
else if(action.type === EDIT_PARTNER_GUARD_ERROR){
    return {
        ...editPartnerGuardState,
        edit_partner_guard_success:false,
        edit_partner_guard_error:action.payload.error,
        edit_partner_guard_loadin:false,

    }
}

else if(action.type === CLOASE_MODAL_EDIT_PARTNER_GUARD){
    return {
        ...editPartnerGuardState,
        edit_partner_guard_success:false,
        edit_partner_guard_error:null,
        edit_partner_guard_loadin:false,

    }
}


    return state;

}