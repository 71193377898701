export const COUNTER_PARTY_CALC_SUCCESS = 'COUNTER_PARTY_CALC_SUCCESS';
export const COUNTER_PARTY_CALC_ERROR = 'COUNTER_PARTY_CALC_ERROR';
export const CLOSE_COUNTER_PARTY_CALC_MODAL = 'CLOSE_COUNTER_PARTY_CALC_MODAL';
export const EDIT_CLOSE_COUNTER_PARTY_CALC_MODAL = 'EDIT_CLOSE_COUNTER_PARTY_CALC_MODAL';
export const CLOSE_COUNTER_PARTY_CALC_MODAL_CLOSE_ERROR = 'CLOSE_COUNTER_PARTY_CALC_MODAL_CLOSE_ERROR';


const createCounterPartyCalcState = {
    calc_counter_party_success: false,
    calc_conter_party_error: null,
}


export function CounterpartyRiskcalculationReducer(state = createCounterPartyCalcState, action) {
    if (action.type === COUNTER_PARTY_CALC_SUCCESS) {
        return {
            ...createCounterPartyCalcState,
            calc_counter_party_success: true,
            calc_conter_party_error: null,

        }
    }
    else if (action.type === COUNTER_PARTY_CALC_ERROR) {
        return {
            ...createCounterPartyCalcState,
            calc_counter_party_success: false,
            calc_conter_party_error: action.payload.error,
        }
    }

    else if (action.type === CLOSE_COUNTER_PARTY_CALC_MODAL) {
        return {
            ...createCounterPartyCalcState,
            calc_counter_party_success: false,
            calc_conter_party_error: null,

        }
    }

    else if (action.type === EDIT_CLOSE_COUNTER_PARTY_CALC_MODAL) {
        return {
            ...createCounterPartyCalcState,
            calc_counter_party_success: false,
            calc_conter_party_error: null,

        }
    }

    else if (action.type === CLOSE_COUNTER_PARTY_CALC_MODAL_CLOSE_ERROR) {
        return {
            ...createCounterPartyCalcState,
            calc_conter_party_error: null,

        }
    }
    return state;
}