import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingRealVolumeValue } from "../../Services";
import {
  ListingRealVolumeKeysThunk,
  ListingRealVolumeValueThunk,
} from "../../Store/Thunks/ListingRealVolumeThunk";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        style={{ width: 200 }}
        className="editable-field"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input className="editable-field" />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          // rules={[{ required: true, message: `Please Input ${title}!` }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const RealVolume = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    dispatch(ListingRealVolumeKeysThunk());
    dispatch(ListingRealVolumeValueThunk());
  }, [dispatch]);

  const {
    ListingRealVolumeKeyData,
    ListingRealVolumeKeyLoading,
    ListingRealVolumeValueData,
    ListingRealVolumeValueLoading,
  } = useSelector((state) => state.ListingRealVolumeReducer);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const dataSource = () => {
    const arr = [];
    ListingRealVolumeValueData.forEach((item, index) => {
      const obj = {};
      ListingRealVolumeKeyData.forEach((element) => {
        obj[element.key] = item.listingRealVolume[element.key];
        obj.id = item.listingRealVolume.id;
      });
      arr.push({ name: item.assetName, ...obj, key: index });
    });
    setData(arr);
    setOriginalData(arr);
    return arr;
  };

  useEffect(() => {
    if (ListingRealVolumeKeyData?.length && ListingRealVolumeValueData.length) {
      dataSource();
    }
  }, [ListingRealVolumeKeyData, ListingRealVolumeValueData]);

  const editAssetValue = () => {
    setLoading(true);
    const changedData = data.filter((item, index) => {
      const originalItem = originalData[index];
      return Object.keys(item).some((key) => item[key] !== originalItem[key]);
    });

    // const editData = changedData.map((item) => {
    //   const newItem = {};
    //   for (const key in item) {
    //     if (key !== "key" && key !== "name") {
    //       newItem[key] = parseFloat(item[key]);
    //     }
    //   }
    //   newItem.id = item.id;
    //   return newItem;
    // });
    const editData = changedData.map((item) => {
      return {
        id: item.id,
        allTimePeakVolume: parseFloat(item.allTimePeakVolume),
      };
    });

    const payload = { volumes: editData };

    ApiPutListingRealVolumeValue(payload)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isSaveClicked) {
      editAssetValue();
    }
    setIsSaveClicked(false);
  }, [isSaveClicked]);

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      setIsSaveClicked(true);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = () => [
    { title: "Asset", dataIndex: "name", fixed: "left" },
    ...ListingRealVolumeKeyData.filter(
      (item) => item.key === "allTimePeakVolume"
    ).map((item) => ({
      title: item.name,
      dataIndex: item.key,
      editable: true,
      // width: 100,
      render: (text) => (
        <span>
          {text ? text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
        </span>
      ),
    })),
    {
      title: "Operation",
      dataIndex: "operation",
      fixed: "right",
      // width: 100,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      {/* <Button className="asset_save" onClick={editAssetValue}>
        SAVE
      </Button> */}

      <Form form={form} component={false}>
        <Table
          // style={{ width: "70%" }}
          loading={
            ListingRealVolumeKeyLoading ||
            ListingRealVolumeValueLoading ||
            loading
          }
          components={{ body: { cell: EditableCell } }}
          pagination={false}
          bordered
          dataSource={data}
          className="custom-scroll"
          columns={mergedColumns}
          rowClassName="editable-row"
          scroll={{
            // x: "80vw", // Horizontal scroll
            y: 500, // Vertical scroll
          }}
        />
      </Form>
    </div>
  );
};
