export const EDIT_LIGHT_NTF_SUCCESS = 'EDIT_LIGHT_NTF_SUCCESS';
export const EDIT_LIGHT_NTF_LOADING = 'EDIT_LIGHT_NTF_LOADING';
export const EDIT_LIGHT_NTF_ERROR ='EDIT_LIGHT_NTF_ERROR';

const editLightNtfState={
    edit_light_ntf_success:false,
    edit_light_ntf_error:null,
    edit_light_ntf_loadin:false,
}

export function EditLightNtfReducer(state=editLightNtfState,action){
if(action.type === EDIT_LIGHT_NTF_LOADING){
    return {
        ...editLightNtfState,
        edit_light_ntf_success:false,
        edit_light_ntf_error:null,
        edit_light_ntf_loadin:true,

    }
}
else if(action.type === EDIT_LIGHT_NTF_SUCCESS){
    return {
        ...editLightNtfState,
        edit_light_ntf_success:true,
        edit_light_ntf_error:null,
        edit_light_ntf_loadin:false,

    }
}
else if(action.type === EDIT_LIGHT_NTF_ERROR){
    return {
        ...editLightNtfState,
        edit_light_ntf_success:false,
        edit_light_ntf_error:action.payload.error,
        edit_light_ntf_loadin:false,

    }
}


    return state;

}