import {PLATFORM_CALC_SUCCESS,PLATFORM_CALC_ERROR}  from '../Reducers/PlatformRiskCalcReducer';


import { lightNTFCalc,EditCalcParamsById } from '../../Services/index';

export function PlatformRiskCalcThunk(type, scoreDividend, scoreDivisor, percent,scoreMultiplier,platfortType,platformId) {
    return (dispatch, getState) => {

        if(platfortType === 'create'){
            lightNTFCalc(type, +scoreDividend, +scoreDivisor, +percent,+scoreMultiplier)
            .then((result) => {
                dispatch({
                    type: PLATFORM_CALC_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: PLATFORM_CALC_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
        }
        else {
            EditCalcParamsById(platformId,type, +scoreDividend, +scoreDivisor, +percent,+scoreMultiplier)
            .then((result) => {
                dispatch({
                    type: PLATFORM_CALC_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: PLATFORM_CALC_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
        }

      
    }
}