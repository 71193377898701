import { React, useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { AuthRoutes, AppRoutes } from "./Routes";
import moment from "moment";

function App() {
  // checking token state, rendering routes
  const { token } = useSelector((store) => store.LoginReducer);

  const [events, setEvents] = useState([
    "click",
    "load",
    "scroll",
    "change",
    "keydown",
  ]);
  const logOutTime = 30; // minute

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 1000 * 60 * logOutTime);
  };

  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = logOutTime;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes() || 0;
      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("assetId");
        localStorage.removeItem("isAuth");
        window.location.href = "/login";
      }
    }, 1000);
  };

  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (token) {
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
  }, [token]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  return token ? <AppRoutes /> : <AuthRoutes />;
}

export default App;
