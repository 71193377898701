import { React, useState, useEffect } from 'react';

import { Button, Checkbox, Form, Input, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { CreatePartnerGuardThunk } from '../../Store/Thunks/CreatePartnerGuardThunk';

import { ApiGetPartnerGuardById } from '../../Services/index';

import { EditPartnerGuardThunk } from '../../Store/Thunks/EditPartnerGuardThunk';

export function CreatePartnerGuardModal({ element }) {

    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [img, setImage] = useState();

    const [stateError, setStateError] = useState(null);


    const create_partner_guard_success = useSelector((state) => {
        return state.CreatePartnerGuardReducer.partner_guard_success;
    });

    const create_partner_guard_error = useSelector((state) => {
        return state.CreatePartnerGuardReducer.partner_guard_error;
    });

    const edit_partner_guard_success = useSelector((state) => {
        return state.EditPartnerGuardReducer.edit_partner_guard_success;
    });


    const edit_partner_guard_error = useSelector((state) => {
        return state.EditPartnerGuardReducer.edit_partner_guard_error;
    });


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    const onFinish = (value) => {

        if (element && element?.id) {
            if(img === null){
                setImage(1);
            }
            dispatch(EditPartnerGuardThunk(element?.id, value.name, value.risk, img, value.tokenId,value.withtoken));
        }
        else {
            dispatch(CreatePartnerGuardThunk(value.name, value.risk, img, value.tokenId,value.withtoken));
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            setStateError(null)
            form.resetFields();
            setFileList([]);
        }
    }

    const onChange = ({ fileList: newFileList }) => {
        if(!newFileList.length){
            setImage(0);
        }
        setFileList(newFileList);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const beforeUpload = (file) => {
        setImage(file)
        return false;
    }

    useEffect(() => {
        if (create_partner_guard_success) {
            form.resetFields();
            setFileList([]);
        }
    }, [create_partner_guard_success]);

    useEffect(() => {
        if (edit_partner_guard_success) {
            form.resetFields();
            setFileList([]);
        }
    }, [edit_partner_guard_success]);


    useEffect(() => {
        if (element && edit_partner_guard_error) {
            setStateError(edit_partner_guard_error)
        }
    }, [element, edit_partner_guard_error]);

    useEffect(() => {
        if (!element && create_partner_guard_error) {
            setStateError(create_partner_guard_error)
        }
    }, [create_partner_guard_error]);


    useEffect(() => {
        if (element && element.id) {
            
            ApiGetPartnerGuardById(element?.id)
                .then((result) => {
                    if(result?.data?.data?.image){
                        setFileList([{
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            url: result?.data?.data?.image,
                        },
                        ])
                    }
                    
                    form.setFieldsValue({
                        name: result?.data?.data?.name,
                        risk: Math.round(result?.data?.data?.defaultRisk),
                        image: result?.data?.data?.image,
                        tokenId: result?.data?.data?.tokenId,
                        withtoken:result?.data?.data?.withToken
                    });
                }).catch((error) => { });
        }

    }, [element]);



    return (
        <>
            <div style={{ width: '330px' }}>
                {create_partner_guard_error ?
                    <p className='error_container'>{stateError}</p> : null
                }

                {
                    edit_partner_guard_error ?
                        <p className='error_container'>{stateError}</p> : null
                }

                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 9,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Image"
                        name="image"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your Image!!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('image')}
                    >
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            beforeUpload={(file) => beforeUpload(file)}
                            accept=".png,.jpg"
                        >
                            {fileList.length < 1 && '+ Upload'}
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label="With Token"
                        name="withtoken"
                        valuePropName="checked"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your Witht Token!',
                            },
                        ]}
                         onFocus={() => deleteErrorButton('withtoken')}
                    >
                         <Checkbox></Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Counterparty"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Counterparty!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('partner')}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Counterparty ID"
                        name="tokenId"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your Counterparty ID!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('tokenId')}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Counterparty Risk"
                        name="risk"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Counterparty Risk!',
                            },
                            {
                                pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                                message: 'Invalid value.'
                            }
                        ]}
                        onFocus={() => deleteErrorButton('risk')}

                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 12,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            {
                                element && element?.id ?
                                    'Edit' : 'Create'
                            }

                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}