import { React, useState, useEffect } from 'react';

import { Button, Form, Input } from 'antd';

import { useDispatch, useSelector } from "react-redux";

import { PlatformRiskCalcThunk } from '../../Store/Thunks/PlatformCalcThunk';

import { GetPlatformCalcParams, GetPlatformCalcParamsById } from '../../Services/index';
import { CLOSE_PLATFORM_CALC_MODAL, EDIT_CLOSE_PLATFORM_CALC_MODAL } from '../../Store/Reducers/PlatformRiskCalcReducer';

export function PlatformRiskCalculationModal({ element }) {

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const [stateError, setStateError] = useState(null);


    const calc_platform_success = useSelector((state) => {
        return state.PlatformRiskCalcReducer.calc_platform_success;
    });

    const calc_platform_error = useSelector((state) => {
        return state.PlatformRiskCalcReducer.calc_platform_error;
    });


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    const onFinish = (values) => {
        if (element && element?.id) {
            dispatch(PlatformRiskCalcThunk(5, values.scoreDividend, values.scoreDivisor, values.percent, values.scoreMultiplier, 'edit', element?.platformId?.id));
            dispatch({
                type: EDIT_CLOSE_PLATFORM_CALC_MODAL
            })
        }
        else {

            dispatch(PlatformRiskCalcThunk(9, values.scoreDividend, values.scoreDivisor, values.percent, values.scoreMultiplier, 'create'));
            dispatch({
                type: CLOSE_PLATFORM_CALC_MODAL
            })
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            setStateError(null)
            form.resetFields();
        }
    };

    useEffect(() => {
        if (calc_platform_success) {
            // form.resetFields();
            setStateError(null);
            if (!element) {
                GetPlatformCalcParams()
            }
            else {
                GetPlatformCalcParamsById(element?.platformId?.id)
            }

        }

    }, [calc_platform_success]);


    useEffect(() => {

        if (calc_platform_error) {
            setStateError(calc_platform_error)

        }
        else {
            setStateError(null)
        }

    }, [calc_platform_error])

    useEffect(() => {
        if (!calc_platform_success) {
            setStateError(null)
            // form.resetFields();
        }
    }, [calc_platform_success]);

    useEffect(() => {
        if (element) {

            GetPlatformCalcParamsById(element?.platformId?.id)
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.scoreDividend || '',
                        scoreDivisor: result?.data?.data?.scoreDivisor || '',
                        percent: result?.data?.data?.percent || '',
                        scoreMultiplier: result?.data?.data?.scoreMultiplier || '',
                    })
                })
        }

        else {
            GetPlatformCalcParams()
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.calcParams[0].scoreDividend || '',
                        scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor || '',
                        percent: result?.data?.data?.calcParams[0].percent || '',
                        scoreMultiplier: result?.data?.data?.calcParams[0].scoreMultiplier || '',
                    })
                })
                .catch(() => {

                })
        }
    }, [element]);

    return (
        <>

            <div className='calc__modal'>
                {
                    calc_platform_error ?
                        <p className='error_container'>{stateError}</p> : null
                }


                <h2 className='calc_modal_description_title'>Platform Risk calculation</h2>

                <Form
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">

                    <div className='no_stable_coin_form calc__form__modal'>
                        <b style={{ margin: '0 2px' }}>Platform_Risk:</b> <p style={{ margin: '0 2px' }}>Every</p>

                        <Form.Item
                            label=""
                            name="percent"
                            rules={[{ required: true, message: '' }]}
                            onFocus={() => deleteErrorButton('percent')}
                        >
                            <Input style={{ width: '50px' }} />
                        </Form.Item> <p style={{ margin: '0 2px' }}>     %</p> <p style={{ margin: '0 2px' }}> decrease in</p> <b style={{ margin: '0 2px' }}> Pool_Current_TVL</b> <p style={{ margin: '0 2px' }}> below</p>
                        <br />
                        <div className='no_stable_coin_form calc__form__modal'>
                            <b style={{ margin: '0 2px' }}>Pool_7Days_TVL</b>
                            <p style={{ margin: '0 2px' }}> subtracts </p>

                            <Form.Item
                                name="scoreMultiplier"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your scoreMultiplier!',
                                    },
                                ]}
                                onFocus={() => deleteErrorButton('scoreMultiplier')}
                            >
                                <Input style={{ width: '50px' }} />
                            </Form.Item> <p style={{ margin: '0 2px' }}>  %</p> <p style={{ margin: '0 2px' }}>from the initial </p> <b style={{ margin: '0 2px' }}>Base_Platform_Risk</b> <p style={{ margin: '0 2px' }}>estimate.</p>
                        </div>
                    </div>
                    <Form.Item style={{ margin: '20px auto' }}>
                        <Button type="primary" htmlType="submit">Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>


        </>
    )
}