import { React, useState,useEffect } from 'react';

import { Form, Input } from 'antd';

import {GetAssetParamsCap,GetAssetCalcParamsById} from '../../Services/index';


export function AssetMarketCapRiskColculationMultiplierComponet({assetMarketCapRiskMultiplierProps,scoreDividend,scoreDivisor,element}) {

    const [form] = Form.useForm();

    const [assetMarketCapRiskMultiplier,setAssetMarketCapRiskMultiplier] = useState({type:'7',scoreDividend:'',scoreDivisor:''});

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {

        }
    };


    
    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };


    useEffect(()=>{
        if(!element){

        GetAssetParamsCap()
        .then((result)=>{
           form.setFieldsValue({
            scoreDividend:result?.data?.data?.calcParams[0].scoreDividend || '',
             scoreDivisor:result?.data?.data?.calcParams[0].scoreDivisor || '',
           })
           setAssetMarketCapRiskMultiplier({type:'7',scoreDividend:result?.data?.data?.calcParams[0].scoreDividend,scoreDivisor:result?.data?.data?.calcParams[0].scoreDivisor})
           scoreDivisor(result?.data?.data?.calcParams[0].scoreDivisor || '')
           scoreDividend(result?.data?.data?.calcParams[0].scoreDividend || '')
        })
    }

    else {
        GetAssetCalcParamsById(element?.id,2)
        .then((result)=>{
           form.setFieldsValue({
            scoreDividend:result?.data?.data?.scoreDividend || '',
             scoreDivisor:result?.data?.data?.scoreDivisor || '',
           })
           setAssetMarketCapRiskMultiplier({type:'2',scoreDividend:result?.data?.data?.scoreDividend,scoreDivisor:result?.data?.data?.scoreDivisor})
           scoreDivisor(result?.data?.data?.scoreDivisor || '')
           scoreDividend(result?.data?.data?.scoreDividend || '')
        })
    }
    },[element]);



    return <>
        {/* <span>Asset Market Cap Risk Multiplier</span> */}

        <Form
            form={form}
            initialValues={{ remember: true }}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
        >
            <div  className="asset__calculate_form_modal">
                <Form.Item
                    style={{ width: '50px' }}
                    name="scoreDividend"
                    rules={[
                        {
                            required: false,
                            message: 'Please input your scoreDividend!',
                        },
                    ]}
                    onFocus={() => deleteErrorButton('scoreDividend')}
                >
                    <Input onChange={((event) => {
                        if(element){
                            scoreDividend(event.target.value)
                            const changedData = {
                              ...assetMarketCapRiskMultiplier,
                              type:'2',
                              scoreDividend:event.target.value,
                          }
                          setAssetMarketCapRiskMultiplier(changedData)
                          assetMarketCapRiskMultiplierProps(changedData)
                        }
                     else{
                        scoreDividend(event.target.value)
                        const changedData = {
                          ...assetMarketCapRiskMultiplier,
                          type:'7',
                          scoreDividend:event.target.value,
                      }
                      setAssetMarketCapRiskMultiplier(changedData)
                      assetMarketCapRiskMultiplierProps(changedData)
                     }

                    })}
                    />
                </Form.Item> <b className="score__line">/</b>
                <Form.Item
                    style={{ width: '50px' }}
                    name="scoreDivisor"
                    rules={[
                        {
                            required: false,
                            message: 'Please input your scoreDivisor!',
                        },
                    ]}
                    onFocus={() => deleteErrorButton('scoreDivisor')}
                >
                    <Input  onChange={((event)=>{
                        if(element){
                            scoreDivisor(event.target.value)
                            const changedData = {
                             ...assetMarketCapRiskMultiplier,
                             type:'2',
                             scoreDivisor:event.target.value,
                         }
                         setAssetMarketCapRiskMultiplier(changedData)
                         assetMarketCapRiskMultiplierProps(changedData)
                        }
                        else{
                            scoreDivisor(event.target.value)
                            const changedData = {
                             ...assetMarketCapRiskMultiplier,
                             type:'7',
                             scoreDivisor:event.target.value,
                         }
                         setAssetMarketCapRiskMultiplier(changedData)
                         assetMarketCapRiskMultiplierProps(changedData)
                        }
                          
                    })}/>
                </Form.Item>
                <p><b style={{ color: '#0070c0',margin:'0 8px' }}>* Asset Market Cap Risk).</b></p>
            </div>
        
        </Form>
    </>
}