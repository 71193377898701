import { USER_LIGHT_DEFI_GET_SUCCESS } from '../Reducers/UserLightDefiReducer';

import { ApiGetUserLightDeFi } from '../../Services';


export function UserLightDefiThunk(userId) {
    return (dispatch, getState) => {
        ApiGetUserLightDeFi(userId)
            .then((result) => {
                dispatch({
                    type: USER_LIGHT_DEFI_GET_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
    }
}