import { Modal } from 'antd';
import { Form, Input, Button, Checkbox } from 'antd';
import './modal.css';
import React, { useEffect } from 'react';
import { editAsset } from '../../Services';

import { AssetRiskCalculationModal } from '../../Modals/AssetRiskCalculationModal';

import { showErrorMessage } from '../../Helpers/ErrorMessages';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CLOSE_ASSET_CALC_MODAL_CLOSE_ERROR } from '../../Store/Reducers/AssetRiskCalculationAllReducer';

import { AssetRiskCalculationNoStableCoinModal } from '../../Modals/AssetRiskCalculationNoStableCoinModal';
import {AssetRiskCalculationStableCoinModal} from '../../Modals/AssetRiskCalculationStableCoinModal';


export const EditAssetModal = ({ visible, setVisible, data, successCb }) => {

  const dispatch = useDispatch();

  const [form] = Form.useForm();



  useEffect(() => {
    form.setFieldsValue({ ...data });
  }, [data]);

  const onFinish = async (values) => {

    const datas = {};
    if (values.defaultValue !== data.defaultValue) {
      datas.defaultValue = +values.defaultValue;
    }
    if (values.name !== data.name) {
      datas.name = values.name;
    }
    if (values.symbol !== data.symbol) {
      datas.symbol = values.symbol;
    }

    if (values.stableCoin !== data.stableCoin) {
      datas.stableCoin = values.stableCoin;
    }
    try {
      const response = await editAsset({ ...datas, id: data.id });
      successCb();
      setVisible(false);

    }
    catch (error) {
      const { response } = error;
      const { data } = response;
      showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]);
    }
  }

  const makCloseAssetAEdit = () => {
    dispatch({
      type: CLOSE_ASSET_CALC_MODAL_CLOSE_ERROR
    })
  }



  return (
    <Modal title='Edit Asset' width={1300} visible={visible} footer={null} onCancel={() => setVisible(false)} afterClose={makCloseAssetAEdit}>
      <div className='edit_asser__modal' style={{ flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>

        <Form
          name="basic"
          form={form}
          className="calc__form"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={data}
          layout="vertical"
        >
          <div className='stableCoin__content'>
            <Form.Item
              name="stableCoin"
              label="Stable coin:"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                  message: 'This field is required.'
                },
              ]}
            >
              <Checkbox></Checkbox>
            </Form.Item>
          </div>
          <Form.Item
            name="symbol"
            label="Asset Name (Symbol):"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder='Symbol...' />
          </Form.Item>
          <Form.Item
            name="name"
            label="Asset ID:"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder="Name..." />
          </Form.Item>


          <Form.Item
            name="defaultValue"
            label="Asset Default Risk:"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
              {
                pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                message: 'Invalid value.'
              }
            ]}
          >
            <Input placeholder='Default value...' />
          </Form.Item>
          <div className="lightcore_login_submit_button">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className='calc_modal_description' style={{ width: '100%' }}>
          {
            !data?.stableCoin ?
              <AssetRiskCalculationNoStableCoinModal element={data}/> : 
              <AssetRiskCalculationStableCoinModal element={data}/>
          }
        </div>
      </div>
    </Modal>
  );
};
