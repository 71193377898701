export const PRODUCT_RISK_CALCULATION_SUCCESS ='PRODUCT_RISK_CALCULATION_SUCCESS';
export const PRODUCT_RISK_CALCULATION_ERROR ='PRODUCT_RISK_CALCULATION_ERROR';
export const CLOSE_PRODUCT_RISK_CALCULATION_MODAL = 'CLOSE_PRODUCT_RISK_CALCULATION_MODAL';

const productRiskCalculationState={
    product_risk_calculation_error:null,
    product_risk_calculation_success:false,
}


export function ProductRiskCalculationReducer(state=productRiskCalculationState,action){
    if(action.type === PRODUCT_RISK_CALCULATION_SUCCESS){
        return {
            ...productRiskCalculationState,
            product_risk_calculation_success:true,
            product_risk_calculation_error:null,
    
        }
    }
    else if(action.type === PRODUCT_RISK_CALCULATION_ERROR){
        return {
            ...productRiskCalculationState,
            product_risk_calculation_success:false,
            product_risk_calculation_error:action.payload.error,
        }
    }
    
    else if(action.type === CLOSE_PRODUCT_RISK_CALCULATION_MODAL){
        return {
            ...productRiskCalculationState,
            product_risk_calculation_success:false,
            product_risk_calculation_error:null,
    
        }
    }
    return state;
}