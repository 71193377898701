import { React, useState, useEffect } from 'react';

import { Form, Input } from 'antd';

import { GetAssetMarketPriceRiskCalcParamsNoStableCoin, GetAssetCalcParamsById } from '../../../../Services/index';

export function AssetMarketCapRiskStableCoin({ assetMarketCapRiskProps, element }) {
    const [form] = Form.useForm();

    const [assetMarketCapRisk, setAssetMarketCapRisk] = useState({ type: '3', percent: '', scoreMultiplier: '' });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {

        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };


    useEffect(() => {
      
            GetAssetCalcParamsById(element?.id, 3)
                .then((result) => {
                    form.setFieldsValue({
                        percent: result?.data?.data?.percent || '',
                        scoreMultiplier: result?.data?.data?.scoreMultiplier || '',
                    })
                    setAssetMarketCapRisk({
                        type: '3',
                        percent: result?.data?.data?.percent,
                        scoreMultiplier: result?.data?.data?.scoreMultiplier,
                    })
                })

    }, [element]);

    return (
        <>
            <div className='no_stable_coin_form'>
                <Form
                    style={{ display: 'flex', alignItems: 'center' }}
                    form={form}
                    name="basic"
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <b style={{ margin: '0 2px' }}>Asset_MarketCap_Risk:</b> <p style={{ margin: '0 2px' }}>Every </p>
                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="percent"
                        rules={[{ required: false, message: 'Please input your percent!' }]}
                        onFocus={() => deleteErrorButton('percent')}
                    >
                        <Input onChange={((event) => {

                     
                                const changedData = {
                                    ...assetMarketCapRisk,
                                    type: '3',
                                    percent: event.target.value,
                                }
                                setAssetMarketCapRisk(changedData)
                                assetMarketCapRiskProps(changedData)
                            
                       

                        })} />
                    </Form.Item>  <p style={{ margin: '0 2px' }}>% decrease in</p> <b style={{ margin: '0 2px' }}>Asset_Current_MarketCap </b><p style={{ margin: '0 2px' }}>below</p> <b style={{ margin: '0 2px' }}>Asset_7_14DaysAvg_MarketCap</b> <p style={{ margin: '0 2px' }}>subtracts</p>
                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="scoreMultiplier"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your scoreMultiplier!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('scoreMultiplier')}
                    >
                        <Input onChange={((event) => {
                  
                                const changedData = {
                                    ...assetMarketCapRisk,
                                    type: '3',
                                    scoreMultiplier: event.target.value,
                                }
                                setAssetMarketCapRisk(changedData)
                                assetMarketCapRiskProps(changedData)
                        })} />
                    </Form.Item>  <p style={{ margin: '0 2px' }}>% from the initial </p><b style={{ margin: '0 2px' }}>Asset_Basic_Risk</b> <p style={{ margin: '0 2px' }}>estimate.</p>
                </Form>
            </div>
        </>
    )
}