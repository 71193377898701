import { React, useState, useEffect } from 'react';

import { Form, Input } from 'antd';

import { GetAssetMarketPriceRiskCalcParams, GetAssetCalcParamsById } from '../../Services/index';


export function AssetMarketCapPriceRisk({ assetMarketCapRiskProps, element }) {


    const [form] = Form.useForm();

    const [assetMarketCapRisk, setAssetMarketCapRisk] = useState({ type: '5', scoreDividend: '', scoreDivisor: '', percent: '',scoreMultiplier:'' });



    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {

        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };


    useEffect(() => {
        if (!element) {
            GetAssetMarketPriceRiskCalcParams()
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.calcParams[0].scoreDividend || '',
                        scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor || '',
                        percent: result?.data?.data?.calcParams[0].percent || '',
                        scoreMultiplier:result?.data?.data?.calcParams[0].scoreMultiplier || '',
                    })
                    setAssetMarketCapRisk({ type: '5', scoreDividend: result?.data?.data?.calcParams[0].scoreDividend, 
                    scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor, 
                    percent: result?.data?.data?.calcParams[0].percent,
                    scoreMultiplier:result?.data?.data?.calcParams[0].scoreMultiplier,
                 })
                })
        }
        else {
            GetAssetCalcParamsById(element?.id, 3)
                .then((result) => {
                    form.setFieldsValue({
                        scoreDividend: result?.data?.data?.scoreDividend || '',
                        scoreDivisor: result?.data?.data?.scoreDivisor || '',
                        percent: result?.data?.data?.percent || '',
                        scoreMultiplier:result?.data?.data?.scoreMultiplier || '',
                    })
                    setAssetMarketCapRisk({ type: '3', scoreDividend: result?.data?.data?.scoreDividend, scoreDivisor: 
                    result?.data?.data?.scoreDivisor, 
                    percent: result?.data?.data?.percent,
                    scoreMultiplier:result?.data?.data?.scoreMultiplier,
                 })
                })
        }


    }, [element]);




    return (
        <>
            <Form
                form={form}
                name="basic"
                // wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="asset__calculate_form_modal">
                    <p><b style={{ color: '#0070c0', margin: '0 8px' }}>Asset MarketCap Risk:</b> = <span style={{ margin: '0 8px' }}>Every</span></p>

                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="percent"
                        rules={[{ required: true, message: 'Please input your percent!' }]}
                        onFocus={() => deleteErrorButton('percent')}
                    >
                        <Input onChange={((event) => {
                            if (element) {
                                const changedData = {
                                    ...assetMarketCapRisk,
                                    type: '3',
                                    percent: event.target.value,
                                }
                                setAssetMarketCapRisk(changedData)
                                assetMarketCapRiskProps(changedData)
                            }

                            else {
                                const changedData = {
                                    ...assetMarketCapRisk,
                                    type: '5',
                                    percent: event.target.value,
                                }
                                setAssetMarketCapRisk(changedData)
                                assetMarketCapRiskProps(changedData)
                            }

                        })} />
                    </Form.Item>
                    <p style={{marginLeft:'5px'}}>%</p>

                    <p style={{ margin: '0 8px' }}>decrease in <b style={{ color: '#0070c0' }}>Asset Current MarketCap  </b> below </p>
                </div>
                <div className="asset__calculate_form_modal">
                    <b style={{ color: '#0070c0',marginLeft:'5px' }}>Asset_7_14DaysAvg_MarketCap </b>  <p style={{ margin: '0 5px' }}>subtracts</p> 
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            style={{ width: '50px',display:'none' }}
                            label=""
                            name="scoreDividend"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your scoreDividend!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('scoreDividend')}
                        >
                            <Input onChange={((event) => {
                                if (element) {
                                    const changedData = {
                                        ...assetMarketCapRisk,
                                        type: '3',
                                        scoreDividend: event.target.value,
                                    }
                                    setAssetMarketCapRisk(changedData)
                                    assetMarketCapRiskProps(changedData)
                                }
                                else {
                                    const changedData = {
                                        ...assetMarketCapRisk,
                                        type: '5',
                                        scoreDividend: event.target.value,
                                    }
                                    setAssetMarketCapRisk(changedData)
                                    assetMarketCapRiskProps(changedData)
                                }

                            })} />
                        </Form.Item> 
                        {/* <b className="score__line">/</b> */}
                        <Form.Item
                            style={{ width: '50px',display:'none' }}
                            name="scoreDivisor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your scoreDivisor!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('scoreDivisor')}
                        >
                            <Input onChange={((event) => {
                                if (element) {
                                    const changedData = {
                                        ...assetMarketCapRisk,
                                        type: '3',
                                        scoreDivisor: event.target.value,
                                    }
                                    setAssetMarketCapRisk(changedData)
                                    assetMarketCapRiskProps(changedData)
                                }
                                else {
                                    const changedData = {
                                        ...assetMarketCapRisk,
                                        type: '5',
                                        scoreDivisor: event.target.value,
                                    }
                                    setAssetMarketCapRisk(changedData)
                                    assetMarketCapRiskProps(changedData)
                                }


                            })} />
                        </Form.Item>

                        <Form.Item
                            style={{ width: '50px'}}
                            label=""
                            name="scoreMultiplier"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your scoreMultiplier!',
                                },
                            ]}
                            onFocus={() => deleteErrorButton('scoreMultiplier')}
                        >
                            <Input onChange={((event) => {
                                if (element) {
                                    const changedData = {
                                        ...assetMarketCapRisk,
                                        type: '3',
                                        scoreMultiplier: event.target.value,
                                    }
                                    setAssetMarketCapRisk(changedData)
                                    assetMarketCapRiskProps(changedData)
                                }
                                else {
                                    const changedData = {
                                        ...assetMarketCapRisk,
                                        type: '5',
                                        scoreMultiplier: event.target.value,
                                    }
                                    setAssetMarketCapRisk(changedData)
                                    assetMarketCapRiskProps(changedData)
                                }

                            })} />
                            </Form.Item>
                            <p style={{marginLeft:'5px'}}>%</p>
                        <p style={{marginTop:'10px',marginLeft:"5px"}}>from the initial <b>Asset Basic Risk</b> estimate.</p>
                    </div>
                </div>
            </Form>

        </>
    )
}