import {
  ApiGetListingMaturityKeys,
  ApiGetListingMaturityValue,
} from "../../Services";
import {
  LISTING_MATURITY_KEYS_FAIL,
  LISTING_MATURITY_KEYS_REQUEST,
  LISTING_MATURITY_KEYS_SUCCESS,
  LISTING_MATURITY_VALUE_REQUEST,
  LISTING_MATURITY_VALUE_SUCCESS,
  LISTING_MATURITY_VALUE_FAIL,
} from "../Actions";

export const ListingMaturityKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_MATURITY_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingMaturityKeys();
      const { data } = response;

      dispatch({
        type: LISTING_MATURITY_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_MATURITY_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingMaturityValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_MATURITY_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingMaturityValue();
      const { data } = response;
      dispatch({
        type: LISTING_MATURITY_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_MATURITY_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
