import { React, useState, useRef, useEffect } from 'react';

import { Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux'

import { AssetRiskColculationMultiplierComponet } from '../../Components/AssetRiskColculationMultiplierComponent';
import { AssetMarketCapRiskColculationMultiplierComponet } from '../../Components/AssetMarketCapRiskColculationMultiplierComponet';
import { AssetrPriceRisk } from '../../Components/AssetPriceRisk';
import { AssetMarketCapPriceRisk } from '../../Components/AssetMartketCapRiskCalculate';

import { AssetRiskCalculation,EditAssetRiskCalculation } from '../../Services/index';

import { ASSET_RISK_CALCULATION_ERROR, ASSET_RISK_CALCULATION_SUCCESS,CLOSE_ASSET_RISK_CALCULATION_MODAL,
    EDIT_CLOSE_ASSET_RISK_CALCULATION_MODAL} from '../../Store/Reducers/AssetRiskCalculationAllReducer';
import {STABLECOIN_EDIT_ASSET_CLOSE_MODAL} from '../../Store/Reducers/StableCoinEditAssetVisiblityReducer';

export function AssetRiskCalculationModal({element}) {

    const dispatch = useDispatch();

    const [assetScoreDividend, setAssetScoreDividend] = useState();
    const [assetScoreDivisor, setAssetScoreDivisor] = useState();


    const [marketCapScoreDividend, setMarketCapScoreDividend] = useState();
    const [marketCapScoreDivisor, setMarketCapScoreDivisor] = useState();


    const assetPriceRiskMultiplier = useRef({});

    const assetMarketCapRiskMultiplier = useRef({});

    const assetMarketCapRisk = useRef({});

    const assetPriceRisk = useRef({});



    const asset_risk_calc_error = useSelector((state) => {
        return state.AssetRiskCalculationAllReducer.asset_risk_calculation_error;
    });


    const asset_risk_calculation_success = useSelector((state) => {
        return state.AssetRiskCalculationAllReducer.asset_risk_calculation_success;
    });


    const [stateError, setStateError] = useState(null);


    useEffect(() => {

        if (asset_risk_calc_error) {
            setStateError(asset_risk_calc_error)

        }
        else {
            setStateError(null)
        }

    }, [asset_risk_calc_error]);




    // useEffect(() => {
    //     if (asset_risk_calculation_success) {
    //         form.resetFields();
    //         setStateError(null);
    //         GetPlatformCalcParams()
    //             .then((result) => {
    //             })
    //     }

    // }, [asset_risk_calculation_success]);



    return <>
        <div className='calc__modal'>
            {
                asset_risk_calc_error ?
                    <p className='error_container'>{stateError}</p> : null
            }
             <h2 className='calc_modal_description_title'>Asset Risk Calculation</h2>
            <div className='asset__risk__calculate__form__content'>
                <div className='modal__item'>
                {/* <div className='component__item asset__component__item'>
                    <AssetRiskColculationMultiplierComponet 
                    element={element}
                        scoreDividend={((event) => {
                            setAssetScoreDividend(event)
                        })}
                        scoreDivisor={((event) => {
                            setAssetScoreDivisor(event)
                        })}
                        setAssetPriceRiskMultiplierProps={((event) => {
                            assetPriceRiskMultiplier.current = event;
                        })}
                    />
                </div>  */}
                 {/* <div className='component__item'>
                    <AssetMarketCapRiskColculationMultiplierComponet
                         element={element}
                        assetMarketCapRiskMultiplierProps={(event) => {
                            assetMarketCapRiskMultiplier.current = event;
                        }}

                        scoreDividend={((event) => {
                            setMarketCapScoreDividend(event)
                        })}
                        scoreDivisor={((event) => {
                            setMarketCapScoreDivisor(event)
                        })}
                    />
                </div> */}
                </div>


                <div className='modal__item' style={{margin:'20px 0'}}>
                <div className='component__item'>
                    <AssetrPriceRisk
                         element={element}
                        assetPriceRiskProps={((event) => {
                            assetPriceRisk.current = event;
                        })}
                    />
                </div>
                </div>




                <div className='modal__item'>
                <div className='component__item'>
                    <AssetMarketCapPriceRisk
                     element={element}
                        assetMarketCapRiskProps={((event) => {
                            assetMarketCapRisk.current = event;
                        })}

                    />
                </div> 
                </div>
                <Button type="primary" htmlType="submit" 
                style={{margin:'10px 0'}}
                    onClick={(() => {
                        if(element && element?.id){
                            dispatch({
                                type: EDIT_CLOSE_ASSET_RISK_CALCULATION_MODAL
                            }) 
                            const dataArr = [assetPriceRiskMultiplier.current, assetMarketCapRiskMultiplier.current, assetMarketCapRisk.current, assetPriceRisk.current]
                            const requestData = dataArr.map(item => ({
                                ...item,
                                typeId: element?.id
                            }))
                            EditAssetRiskCalculation(requestData.filter(item => Object.keys(item).length && item.scoreDividend && item.scoreDivisor,))
                            .then((result) => {
                              dispatch({
                                    type: ASSET_RISK_CALCULATION_SUCCESS
                                })
                            })
                                .catch((error) => {
                                    dispatch({
                                        type: ASSET_RISK_CALCULATION_ERROR,
                                        payload: {
                                            error: error?.response?.data?.error?.message,
                                        }
                                    })
                                })
                        }
                        else{
                            dispatch({
                                type: CLOSE_ASSET_RISK_CALCULATION_MODAL
                            }) 
                            const dataArr = [assetPriceRiskMultiplier.current, assetMarketCapRiskMultiplier.current, assetMarketCapRisk.current, assetPriceRisk.current]
                            AssetRiskCalculation(dataArr.filter(item => Object.keys(item).length && item.scoreDividend && item.scoreDivisor))
                            .then((result) => {
                              dispatch({
                                    type: ASSET_RISK_CALCULATION_SUCCESS
                                })
                                dispatch({
                                    type: STABLECOIN_EDIT_ASSET_CLOSE_MODAL
                                })
                            })
                                .catch((error) => {
                                    dispatch({
                                        type: ASSET_RISK_CALCULATION_ERROR,
                                        payload: {
                                            error: error?.response?.data?.error?.message,
                                        }
                                    })
                                })
                        }
             
                    })}>Submit</Button>
            </div>
        </div>
    </>
}







