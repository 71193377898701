import { GET_PARTNER_GUARD_SUCCESS } from '../../Store/Reducers/GetPartnerGuardReducer';
import { ApiGetPartnerGuard } from '../../Services';

export function GetPartnerGuardThunk() {
    return (dispatch, getState) => {
        ApiGetPartnerGuard()
            .then((result) => {
                dispatch({
                    type: GET_PARTNER_GUARD_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
            .catch((error) => { })
    }
}