import { message } from "antd";

// render error message
export const showErrorMessage = (error) => {
    message.error(error);
};


export const showSuccessMessage = (text) => {
    message.success(text);
};

