import { USER_LIGHT_NTF_GET_SUCCESS } from '../Reducers/UserLightNFTReducer';

import { ApiGetUserLightNFT } from '../../Services';


export function UserLightNFTThunk(userId) {
    return (dispatch, getState) => {
        ApiGetUserLightNFT(userId)
            .then((result) => {
                dispatch({
                    type: USER_LIGHT_NTF_GET_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
    }
}