import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CircularProgressBar} from '@tomik23/react-circular-progress-bar';
import {useDispatch} from "react-redux";
import {
    AutoUnstackingUpdateThunk,
    DeFiShieldAlertUpdateThunk,
    DeFiShieldDeleteThunk, getDefishieldThunk,
    UnStakeThunk
} from "../../Store/Thunks/UserCardsThunk";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import MySlider from "./MySlider";
import {ApiAutoUnstackingUpdate, ApiDeFiShieldDelete} from "../../Services";
import {useParams} from "react-router-dom";
import {Modal} from "antd";
import {deleteUserThunk} from "../../Store/Thunks/LoginThunk";
import {cancelAction} from "../../Store/Actions";

import chart from '../../Assets/img/icons/chart__icon.svg';
import chart_open from '../../Assets/img/icons/chart__icon__open.svg';

import OutsideClickHandler from 'react-outside-click-handler';

import {ChartModal} from '../../Modals/ChartModal';

const DefiShieldCard = ({item}) => {
    const dispatch = useDispatch()
    const {id: userIdParam} = useParams();

    const [alertValue, setAlertValue] = useState(item?.alert);
    const [autoUnstackingValue, SetAutoUnstackingValue] = useState(item?.autoUnstacking ? item?.autoUnstacking : 0);

    const [alertVisible, setAlertVisible] = useState(false);
    const [autoUnstackingVisible, setAutoUnstackingVisible] = useState(false);

    const [openChart, setOpenChart] = useState(false);

    const CardValues = (value) => {
        if (value === "assetId") {
            return Math.round(item?.assetId?.value ? item?.assetId?.value : item?.assetId?.defaultValue)
        } else if (value === "blockchainId") {
            return Math.round(item?.blockchainId?.value ? item?.blockchainId?.value : item?.blockchainId?.defaultValue)
        } else if (value === "platformId") {
            return Math.round(item?.platformId?.value ? item?.platformId?.value : item?.platformId?.defaultValue)
        }
    }

    const coldprops = {
        percent: Math.round(item?.risks?.productRisk ? item?.risks?.productRisk : item.productRisk), // is require
        colorSlice: '#4DC7B5',
        fontColor: '#4DC7B5',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    };

    const riskprops = {
        percent: Math.round(item?.risks?.productRisk ? item?.risks?.productRisk : item.productRisk), // is require
        colorSlice: '#EFB661',
        fontColor: '#EFB661',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const holdprops = {
        percent: Math.round(item?.risks?.productRisk ? item?.risks?.productRisk : item.productRisk), // is require
        colorSlice: '#F27281',
        fontColor: '#F27281',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 108,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const whiteprops = {
        percent: 0, // is require
        colorSlice: '#f0f0f3',
        fontColor: '#f0f0f3',
        fontSize: "1.625em",
        fontWeight: 900,
        size: 178,
        boxShadow: 'inset 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%)',
        unit: "",
        textPosition: "0.35em",
        animationOff: false,
        inverse: false,
        round: false,
        number: false,
    }

    const renderClassName = (color) => {
        switch (color) {
            case 'green':
                return {
                    className: 'card cold defi',
                    props: coldprops
                };
            case 'yellow':
                return {
                    className: 'card hold defi',
                    props: riskprops
                };
            case 'red':
                return {
                    className: 'card risky defi',
                    props: holdprops
                };
            case 'white':
                return {
                    props: whiteprops,
                    color: '#C4C4C4'
                };
        }
    }

    const renderColorClassName = (risk) => {
        if (risk <= 100 && risk >= 80) {
            return {
                className: 'card__product__number card__product__green',
            };
        } else if (risk <= 79 && risk >= 60) {
            return {
                className: 'card__product__number card__product__yellow',
            };
        } else if (risk <= 59) {
            return {
                className: 'card__product__number card__product__red',
            };
        } else {
            return {
                className: 'card__product__number card__product__white',
            };
        }
    }

    const UnStakeBtn = () => {
        dispatch(UnStakeThunk(item?.id, item.userId.id))
    }

    const AlertChangeBtn = useCallback((alert) => {
        dispatch(DeFiShieldAlertUpdateThunk(item?.id, alert, item.userId.id))
    }, [])

    const AutoUnstackingChangeBtn = useCallback((val) => {
        dispatch(AutoUnstackingUpdateThunk(item?.id, val, item.userId.id))
    }, [])

    const autoUnstackingVisibleBtn = () => {
        setAutoUnstackingVisible(!autoUnstackingVisible)
        setAlertVisible(false)
    }

    const AlertVisibleBtn = () => {
        setAlertVisible(!alertVisible)
        setAutoUnstackingVisible(false)
    }


    const DeFiShieldDeleteBtn = (id) => {
        Modal.confirm({
            okText: 'Delete',
            onOk: async (close) => {
                await  ApiDeFiShieldDelete(item?.id,item.userId.id).then((date)=>{
                    if(date.data.success){
                        dispatch(getDefishieldThunk(userIdParam))
                    }
                })
                close();
            },
            onCancel: (close) => {
                close();
            },
            content: <div>You are going to delete the Investment Product. Are you sure?</div>
        });
    };


    return (
        <>
        <div className={renderClassName(item?.risks?.trustScore)?.className}>
            <div className="card__header">
                <div className="row">

                <div className="chart__open__btn" onClick={(() => {
                            setOpenChart(!openChart)
                        })}>

                            {
                                openChart ?
                                    <img src={chart} alt="" className="chart__opened__btn__icon" /> :
                                    <img src={chart_open} alt="" className="chart__open__btn__icon" />

                            }


                        </div>

                    <h2 className="card__title">
                        {item?.assetId?.symbol} Staking
                    </h2>
                    <span onClick={DeFiShieldDeleteBtn} className="card__close">
                                 <span></span><span></span>
                            </span>
                </div>
            </div>
            <div className="card__price">
                <div className="card__wallet__row">
                    <div className="card__wallet__rates">Staked amount</div>
                    <div className="card__wallet__usd">${item?.stakedAmount ? new Intl.NumberFormat('en-US').format(Math.round(item?.stakedAmount)) : 0}</div>
                </div>
            </div>
            <div className="card__product__wrapper">
                <div className="card__product__row">
                    {/*<div className="card__product__title">Asset:{item?.assetId?.symbol}</div>*/}
                    {/*<div  className={  renderColorClassName(CardValues("assetId")).className }>*/}
                    {/*    {CardValues("assetId")}*/}
                    {/*</div>*/}
                    <div className="card__product__title">Asset:</div>
                    <div className={renderColorClassName(CardValues("assetId"))?.className}>
                        <span className="card__product__title margin_right">{item?.assetId?.symbol}</span>
                        {CardValues("assetId")}
                    </div>
                </div>
                <div className="card__product__row">
                    <div className="card__product__title">Blockchain:</div>
                    <div className={renderColorClassName(CardValues("blockchainId"))?.className}>
                        <span className="card__product__title margin_right">{item?.blockchainId?.name}</span>
                        {CardValues("blockchainId")}
                    </div>
                </div>
                <div className="card__product__row">
                    <div className="card__product__title">Platform:</div>
                    <div className={renderColorClassName(CardValues("platformId"))?.className}>
                        <span className="card__product__title margin_right">{item?.platformId?.name}</span>
                        {CardValues("platformId")}
                    </div>
                </div>
            </div>
            <div style={{justifyContent: "end"}} className="card__product__stake__block">
                <button onClick={UnStakeBtn} className="card__product__unstacked">Unstake</button>
            </div>
            <div className="card__risk">
                <h3 className="card__risk__title">Product Risk</h3>
                <div className="card__risk__alert"
                     onClick={AlertVisibleBtn}
                >
                    <p className="card__risk__alert__title">Alert</p>
                    <p className="card__risk__alert__number">{"" + alertValue !== "null" ? "" + alertValue : 0}</p>
                </div>

                {alertVisible ?
                    <MySlider
                        colorBG={true}
                        value={alertValue}
                        setValue={setAlertValue}
                        onAfterChange={AlertChangeBtn}/>
                    : null}

                {autoUnstackingVisible ?
                    <MySlider
                        colorBG={false}
                        value={autoUnstackingValue}
                        setValue={SetAutoUnstackingValue}
                        onAfterChange={AutoUnstackingChangeBtn}/>
                    : null}


                <CircularProgressBar {...renderClassName(item?.risks?.trustScore)?.props}>
                    <div className='card__round__shadow'></div>
                    <div className='card__risk__chart__min__wrapper'>
                        <p className="card__risk__chart__number">{renderClassName(item?.risks?.trustScore)?.props.percent}</p>
                        <p className="card__risk__chart__number__of">of 100</p>
                    </div>
                </CircularProgressBar>


                {/*<div className="card__risk__chart">*/}
                {/*    <div className="card__risk__chart__min">*/}
                {/*        <p className="card__risk__chart__number">87</p>*/}
                {/*        <p className="card__risk__chart__number__of">of 100</p>*/}
                {/*    </div>*/}
                {/*</div>*/}


                <div className="card__risk__info">
                    <i className="icon-info-icon"/>
                </div>
            </div>
            <div onClick={autoUnstackingVisibleBtn} className="unstacking active">
                Auto Unstacking
                <span style={autoUnstackingValue!=0 ? {color: "#f27281"} : null}>{autoUnstackingValue}</span>
            </div>
        </div>
           <OutsideClickHandler onOutsideClick={(event) => {
             setOpenChart(false)

            }}>
                {
                    openChart ?

                        <ChartModal element={item} closeModal={(() => {
                            setOpenChart(false)
                        })} />

                        : ''
                }
            </OutsideClickHandler>

            </>
    );
};

export default DefiShieldCard;
