import {Blockchain_CALC_SUCCESS,Blockchain_CALC_ERROR}  from '../Reducers/BlockchainRiskCalcReducer';


import { lightNTFCalc,EditCalcParamsById } from '../../Services/index';

export function BlockchainRiskCalcThunk(type, scoreDividend, scoreDivisor, percent,scoreMultiplier,blockChainType,typeId) {
    return (dispatch, getState) => {

        if(blockChainType === 'create'){
            lightNTFCalc(type, +scoreDividend, +scoreDivisor, +percent,+scoreMultiplier)
            .then((result) => {
                dispatch({
                    type: Blockchain_CALC_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: Blockchain_CALC_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
        }
        else {
            EditCalcParamsById(typeId,type, +scoreDividend, +scoreDivisor, +percent,+scoreMultiplier)
            .then((result) => {
                dispatch({
                    type: Blockchain_CALC_SUCCESS
                })
            })
            .catch((error) => {
                dispatch({
                    type: Blockchain_CALC_ERROR,
                    payload: {
                        error: error?.response?.data?.error?.message,
                    }
                })
            })
        }
       
    }
}