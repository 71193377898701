import { React, useState, useEffect } from 'react';
import { Avatar, List, Button, Modal } from 'antd';

import { CreatePartnerGuardModal } from '../../Modals/CreatePartnerGuardModal';

import {CLOASE_MODAL_EDIT_PARTNER_GUARD} from '../../Store/Reducers/EditPartnerGuardReducer';

import { useDispatch,useSelector } from 'react-redux';

export function PartnerGuardItem({ element ,deleteItem}) {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const edit_partner_guard_success = useSelector((state) => {
        return state.EditPartnerGuardReducer.edit_partner_guard_success;
    });


    const showModal = () => {
        setIsModalOpen(true);
    }

    const handleCancel = () => { 
        setIsModalOpen(false);
    }

    const maskClosable = () => {
        dispatch({
            type: CLOASE_MODAL_EDIT_PARTNER_GUARD
        })
    }
    
    useEffect(() => {
        if (edit_partner_guard_success) {
            setIsModalOpen(false);
        }

    }, [edit_partner_guard_success]);


    return (
        <>
            <div>
                <List.Item className='light_defi_item'>
                    <List.Item.Meta

                        avatar={<Avatar src={element?.image} />}
                        title={<div style={{ marginTop: 5 }}>
                            <a>{element?.name}</a>
                            <span style={{ color: "#9d9fa1", marginLeft: 10 }}>Risk:  <span className='partner__cuard__title'>{Math.round(element?.defaultRisk)}</span></span>
                        </div>}
                    />
                    <Button onClick={showModal}>Edit</Button>
                    <Button style={{ margin: "0 10px 0" }}
                    onClick={(() => {
                        deleteItem(element)
                    })}
                    >Delete</Button>
                </List.Item>

                <Modal title="Edit Counterparty" visible={isModalOpen} footer={false} onCancel={handleCancel} afterClose={maskClosable} destroyOnClose={true}>
                    <CreatePartnerGuardModal element={element} />
                </Modal>
            </div>
        </>
    )
}