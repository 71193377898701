import { React } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button } from 'antd';
import BtnDelete from "../../Components/MyTag/BtnDelete";


export function TrashAssetsItem({ element, deleteItem, restoreItem }) {

    return (
        <>
            <div className='trash_asset__delete__item__content'>
             <div className='trash_asset__delete__delete__btns'>
             <p className='defi__shild__trash__title'>Asset ID: <span className="restore_title">{element?.id}</span></p>
                <p   className='defi__shild__trash__title'>Asset Name:  <span className="restore_title">{element?.name}</span></p>
                 <p className='defi__shild__trash__title defi__shild__trash__symbol__title'>Asset Symbol:<span className="restore_title">{element?.symbol}</span> </p>
              
             </div>
               
               
                <div className='trash_asset__delete__delete__btns'>

                <Button className="user_access_btn restore__btn" onClick={(() => {
                            restoreItem(element?.id)
                        })}>Restore</Button>
                        <Popconfirm
                            title={`Delete  ${element?.name}`}
                            description="Are you sure to delete this item?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(() => {
                                deleteItem(element?.id)
                            })}
                        >
                            <BtnDelete className="user_access_btn">Delete</BtnDelete>
                        </Popconfirm>
                       
                         </div>
            </div>

        </>
    )
}