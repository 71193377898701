import  {LIGHT_NTF_CALC_SUCCESS,LIGHT_NTF_CALC_ERROR } from '../Reducers/LightNtfCalcReducer';
import {lightNTFCalc} from '../../Services/index';



export function LightNtfCalcThunk(type,percent,scoreMultiplier) {
    return (dispatch, getState) => {

        lightNTFCalc(type,+percent,+scoreMultiplier)
            .then((result) => {
                dispatch({
                    type: LIGHT_NTF_CALC_SUCCESS
                })
            })
            .catch((error)=>{
                dispatch({
                    type:LIGHT_NTF_CALC_ERROR,
                    payload:{
                        error:error?.response?.data?.error?.message,
                    }
                })
            })
    }
}