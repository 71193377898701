import { React, useState } from "react";
import { Form, Button, Input } from "antd";

import { useEffect } from "react";

import { LightNtfCalcThunk } from '../Store/Thunks/LightNtfCalcThunk';
import { useDispatch, useSelector } from "react-redux";

import { GetCalcParams } from '../Services/index';

import { CLOSE_LIGHT_NTF_CALC_MODAL, EDIT_CLOSE_LIGHT_NTF_CALC_MODAL } from '../Store/Reducers/LightNtfCalcReducer';

export const CoreNfModal = ({ element }) => {


    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const [stateError, setStateError] = useState(null);
    const calc_light_ntf_success = useSelector((state) => {
        return state.LightNtfCalcReducer.calc_light_ntf_success;
    });

    const calc_light_ntf_error = useSelector((state) => {
        return state.LightNtfCalcReducer.calc_light_ntf_error;
    });


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };




    useEffect(() => {
        if (calc_light_ntf_success) {
            form.resetFields();
            setStateError(null);
            GetCalcParams()
                .then((result) => {
                })
        }

    }, [calc_light_ntf_success]);


    useEffect(() => {

        if (calc_light_ntf_error) {
            setStateError(calc_light_ntf_error)

        }
        else {
            setStateError(null)
        }

    }, [calc_light_ntf_error]);


    useEffect(() => {

        if (!element) {
            GetCalcParams()
                .then((result) => {
                    form.setFieldsValue({
                        percent: result?.data?.data?.calcParams[0].percent || '',
                        scoreMultiplier: result?.data?.data?.calcParams[0].scoreMultiplier || ''
                        // scoreDividend: result?.data?.data?.calcParams[0].scoreDividend || '',
                        // scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor || '',

                    })
                })
                .catch(() => {

                })
        }
    }, []);






    const onFinish = (values) => {
        dispatch(LightNtfCalcThunk(10, values.percent, values.scoreMultiplier));
        if (element && element.id) {
            dispatch({
                type: EDIT_CLOSE_LIGHT_NTF_CALC_MODAL
            })

        }
        else {
            dispatch({
                type: CLOSE_LIGHT_NTF_CALC_MODAL
            })
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            setStateError(null)
            form.resetFields();
        }

    };

    return (
        <>
            <div className='calc__modal'>
                <div>
                    {
                        calc_light_ntf_error ?
                            <p className='error_container'>{stateError}</p> : null
                    }

                    <h2 className='calc_modal_description_title'>NFT Collection Value Calculation</h2>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className='no_stable_coin_form calc__form__modal'>
                            <b style={{ margin: '0 2px' }}>NFT_Collection_Value:</b> <p style={{ margin: '0 2px' }}>Every </p>
                            <Form.Item
                                label=""
                                name="percent"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your percent!',
                                    },
                                ]}
                                onFocus={() => deleteErrorButton('percent')}
                            >
                                <Input style={{ width: '50px' }} />
                            </Form.Item>  <p style={{ margin: '0 2px' }}>%</p> <p style={{ margin: '0 2px' }}>decrease in</p> <b style={{ margin: '0 2px' }}>Floor_Price</b>
                            <p style={{ margin: '0 2px' }}>below</p>
                            <br></br>
                            <div className='no_stable_coin_form calc__form__modal'>
                                <b style={{ margin: '0 2px' }}>Seven_Day_Average_Price</b> <p style={{ margin: '0 2px' }}>subtracts</p>
                                <Form.Item
                                    label=""
                                    name="scoreMultiplier"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your score!',
                                        },
                                    ]}
                                    onFocus={() => deleteErrorButton('scoreMultiplier')}
                                >
                                    <Input style={{ width: '50px' }} />
                                </Form.Item> <p style={{ margin: '0 2px' }}>% </p> <p style={{ margin: '0 2px' }}>from the initial</p> <b style={{ margin: '0 2px' }}>Base_Collection_Value</b> <p style={{ margin: '0 2px' }}>estimate.</p>
                            </div>


                        </div>
                        <Form.Item
                            style={{ margin: '20px auto' }}
                        >
                            <Button type="primary" htmlType="submit">Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

        </>
    )
}

