import {
    CANCEL_ACTION,
    DELETE_FAILED_STATUS, DELETE_USER_FAIL, DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    LOGIN_FAIL,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_SUCESS_STATE, SOFT_DELETE_USER_REQUEST, SOFT_DELETE_USER_SUCCESS,
    USER_ACCESS_REQUEST,
    USER_ACCESS_SUCCESS, USER_ALL_REQUEST,
    USER_ALL_SUCCESS,
    USER_LOG_OUT
} from "../Actions";

const initialState = {
    loading: false,
    loginSuccess: false,
    loginError: '',
    token: localStorage.getItem('accessToken'),
    isAuth: localStorage.getItem('isAuth') ? localStorage.getItem('isAuth') : false,
    userAll: [],
    userAccessLoading: false,
    userDeleteSuccess: false,
    recycledUsers: [],
    errorMessage:null,
    userLoading:false

}

export const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state, loading: true, // isAuth:false,
                // loginSuccess:false
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('accessToken', action.payload);
            localStorage.setItem('refreshToken',action.refresh_token);
            return {
                ...state, loading: false, token: action.payload, loginSuccess: true, isAuth: true
            };
        case LOGIN_SUCESS_STATE:
            localStorage.setItem('isAuth', true);
            return {
                ...state, // loginSuccess: true,
                isAuth: true
            };
        case LOGIN_FAIL:
            return {
                ...state, loading: false, loginError: action.payload
            };
        case DELETE_FAILED_STATUS:
            return {
                ...state, loginError: '',
            };
        case USER_LOG_OUT:
            localStorage.removeItem('accessToken');
            localStorage.removeItem('isAuth');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('assetId');
            return {
                ...state, isAuth: false, token: null,
            }

        case  USER_ALL_REQUEST:{
           return { ...state, userLoading: true }}

        case USER_ALL_SUCCESS:
            return {
                ...state,
                userAll: action.payload.data,
                userLoading:false

            }

        case USER_ACCESS_REQUEST: {
            return {
                ...state, userAccessLoading: true
            }
        }

        case USER_ACCESS_SUCCESS:

            const {user} = action.payload.data

            const updatedUserList = state.userAll.map(item => {
                if (item.id === user.id) {
                    return {...user, fullName: user?.full_name}
                }
                return {...item}
            })
            return {
                ...state, userAll: updatedUserList, userAccessLoading: false
            }

        case DELETE_USER_REQUEST:
            return {
                ...state,
                userDeleteSuccess: false
            }

        case DELETE_USER_SUCCESS: {
            const {success, type, data} = action.payload
            const {userId, message} = data.data

            const updatedUser = state.userAll.filter((item) => item.id !== userId)
            const updateRecycledUsers = state.recycledUsers.filter((item) => item.id !== userId)

            return {
                ...state,
                userAll: updatedUser,
                recycledUsers: updateRecycledUsers,
                userDeleteSuccess: action.payload.data.success
            }
        }
        case DELETE_USER_FAIL: {
            return {
                ...state,
                errorMessage:action.payload
            }
        }
        case  SOFT_DELETE_USER_REQUEST:{
            return {
                ...state,
                userLoading: true
            }
        }

        case SOFT_DELETE_USER_SUCCESS: {
            return {
                ...state,
                recycledUsers: action.payload.data,
                userLoading: false
            }
        }

        case CANCEL_ACTION:
            return {
                ...state,
                userDeleteSuccess: false
            }

        default:
            return state;
    }
}
