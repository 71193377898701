export const GET_LIGHT_NTF_SUCCESS = 'get_light_ntf_success';

const getLightNtfState = {
    lightNtfResult: [],
}

export function GetLightNtfReducer(state = getLightNtfState, action) {
    if (action.type === GET_LIGHT_NTF_SUCCESS) {
        return {
            ...getLightNtfState,
            lightNtfResult: action.payload.result,
        }
    }
    return state;
}