export const USER_COUNTERPARTY_RISK_GET_SUCCESS = 'USER_COUNTERPARTY_RISK_GET_SUCCESS';


const userCounterpartyRiskState = {
    userCounterpartyRiskResult: [],
}

export const UserCounterpartyRiskReducer = (state=userCounterpartyRiskState, action) => {

    if (action.type === USER_COUNTERPARTY_RISK_GET_SUCCESS) {
        return {
            ...userCounterpartyRiskState,
            userCounterpartyRiskResult: action.payload.result,
        }

    }
    return state;
}