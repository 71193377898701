import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Table, Typography } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { ApiPutListingMaturityValue } from '../../Services';
import { ListingMaturityKeysThunk, ListingMaturityValueThunk } from '../../Store/Thunks/ListingMaturityThunk';
import _ from 'lodash';
import { _capitalize } from 'chart.js/helpers';
import { splitStringIntoTwoLines, stringToNumber } from '../../Helpers/ValueFormater';

const keysToCheck = [
  "projectMaturityParameter",
  "durationOfProjectsExistence",
  "durationOfDevelopersFullTime",
  "durationOfDevelopersTotal"
];
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode;
  const options = {
    mature: { value: "mature", style: { color: '#4DC7B5' }, label: "Mature" },
    medium: { value: "medium", style: { color: '#176bd3' }, label: "Medium" },
    immature: { value: "immature", style: { color: '#F5B452' }, label: "Immature" }
  };

  if (dataIndex === 'projectMaturityParameter') {
    inputNode = (
      <Select>
        {Object.keys(options).map(key => (
          <Select.Option key={options[key].value} value={options[key].value} style={options[key].style}>
            {options[key].label}
          </Select.Option>
        ))}
      </Select>
    );
  } else {
    inputNode = inputType === 'number' ? <InputNumber  className="editable-field" formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}/> : <Input  className="editable-field" formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}/>;
  }

  const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1);
  const isHasKey = keysToCheck.includes(dataIndex);
  return (
    
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
        >
         {isHasKey ? inputNode : record[dataIndex] }
        </Form.Item>
      ) : (
        dataIndex === 'projectMaturityParameter' && record[dataIndex]
          ? capitalize(record[dataIndex])
         :
        dataIndex === 'averageNumberOfTransactionsPer7Days' && record[dataIndex] ?
        record[dataIndex]!=0 ? `${Number(record[dataIndex])
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            :null
          :
       (dataIndex === 'numberOfAddressesWithBalance' || dataIndex==='newAddressesPer7Days' ) 
          ? record[dataIndex] != 0 ? stringToNumber(record[dataIndex])
          : null  
          :(dataIndex === 'sevenDayAverage' || dataIndex ==='ninetyDayAverage' ) && record[dataIndex]
          ? record[dataIndex]!= 0 ?`$${Number(record[dataIndex])
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
         :null
            : ( dataIndex ==='newAdoptionRate' && record[dataIndex] ) ? 
            record[dataIndex]!= 0 ?
            `$${Number(record[dataIndex]*100)
            .toFixed(2)
            .toString()
            }%`: null
             :
            ( dataIndex ==='volatility' && record[dataIndex] ) ?
            record[dataIndex] &&
            record[dataIndex] !== "0" ? `${Number(record[dataIndex]).toFixed(2)           
            .toString()
            }%`
            :""
            :children
      )}
    </td>  
  );
};


export default EditableCell;
export const Maturity = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [modifiedRows, setModifiedRows] = useState(new Set());

  useEffect(() => {
    dispatch(ListingMaturityKeysThunk());
    dispatch(ListingMaturityValueThunk());
  }, []);

  const {
    ListingMaturityKeyData,
    ListingMaturityKeyLoading,
    ListingMaturityValueData,
    ListingMaturityValueLoading,
  } = useSelector((state) => state.ListingMaturityReducer);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {   
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const dataSource = () => {
    const arr = [];
    ListingMaturityValueData.map((item, index) => {
      const obj = {};
      ListingMaturityKeyData.map((element) => {
        if (item.listingMaturity) {
         
          //obj[element.key] = (item.listingMaturity[element.key] && element.key=="projectMaturityParameter") ? _capitalize(item.listingMaturity[element.key]) : item.listingMaturity[element.key];
          obj[element.key] = item.listingMaturity[element.key];
          obj.id = item.listingMaturity.id;
        } else {       
          obj[element.key] = "";
          obj.id = item.id;
        }
      });
      arr.push({
        name: item.assetName,
        ...obj,
        key: index,
      });
    });
    setData(arr);
    return arr;
  };
  
  useEffect(() => {
    if (ListingMaturityKeyData?.length && ListingMaturityValueData.length) {
      dataSource();
    }
  }, [ListingMaturityKeyData, ListingMaturityValueData]);

  const editAssetValue = () => {
    setLoading(true);
    const editData = Array.from(modifiedRows).map((id) => {
      const row = data.find((item) => item.id === id);
      const newItem = {};
      for (const key in row) {
        if (key !== 'key' && key !== 'name') {
          if (row[key] && (key === 'sevenDayAverage' || key === 'ninetyDayAverage')) {
            newItem[key] = `${row[key].toString()}`;
          } else if (row[key] && (key === 'numberOfAddressesWithBalance' ||
                     key === 'averageNumberOfTransactionsPer7Days' ||
                     key === 'newAdoptionRate' || key==='newAddressesPer7Days' )) {
                     newItem[key] = row[key].toString();
          } else {
            newItem[key] = row[key];
          }
        }
      }
      return newItem;
    });
   

    ApiPutListingMaturityValue(editData).then(() => {
      setLoading(false);
      setModifiedRows(new Set());
    }).catch(() => {
      setLoading(false);
    });
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });        
        setData(newData);
        setModifiedRows(prev => new Set(prev).add(item.id)); 
        setEditingKey('');
      }     
      setIsSaveClicked(true)
   
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
    
  };

  useEffect(()=>{
    if(modifiedRows && isSaveClicked){
      editAssetValue();
    }
    setIsSaveClicked(false)
  },[modifiedRows,isSaveClicked])

  const columns = () => {
    return [
      {
        title: 'Asset',
        dataIndex: 'name',
        fixed: 'left',
        width: 80,

      },
      ...ListingMaturityKeyData.map((item) => ({
        title: splitStringIntoTwoLines(item.name),
        dataIndex: item.key,
        editable: true,    
        // width:130,    
        inputType: item.key === 'projectMaturityParameter' ? 'dropdown' : 
        (item.key=='numberOfAddressesWithBalance' ||          
        item.key=='averageNumberOfTransactionsPer7Days' || 
        item.key=='sevenDayAverage' ||
        item.key=='newAdoptionRate' ||
        item.key=='ninetyDayAverage' ||
        item.key=='newAddressesPer7Days') ? 'number':  'text',
        // render:(_,record)=>{
        //   console.log(record,"record")
        //               // return splitStringIntoTwoLines(record.key)
        // }
      
      })),
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        // width: 110,
        render: (_, record) => {        
        
          const editable = isEditing(record);         
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{ marginRight: 8 }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
                <a>Cancel</a>               
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
        
          );
       
        },
      },
    ];
  };

  const mergedColumns = columns().map((col) => {
    
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });




  return (
    <div>
      {/* <Button className='asset_save' onClick={editAssetValue}>
        SAVE
      </Button> */}
      <Form form={form} component={false} >
        <Table
          loading={ListingMaturityKeyLoading || ListingMaturityValueLoading || loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          style={{width:'83vw'}}
          className="custom-scroll maturity"
          pagination={false}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          // scroll={{
          //   x: '100vw', 
          //   y: 700, 
          // }}
          scroll={{ y: 500 }}
        />
      </Form>
    </div>
  );
};
