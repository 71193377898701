export const ASSET_RISK_CALCULATION_SUCCESS ='ASSET_RISK_CALCULATION_SUCCESS';
export const ASSET_RISK_CALCULATION_ERROR ='ASSET_RISK_CALCULATION_ERROR';
export const CLOSE_ASSET_RISK_CALCULATION_MODAL = 'CLOSE_ASSET_RISK_CALCULATION_MODAL';
export const EDIT_CLOSE_ASSET_RISK_CALCULATION_MODAL ='EDIT_CLOSE_ASSET_RISK_CALCULATION_MODAL';
export const CLOSE_ASSET_CALC_MODAL_CLOSE_ERROR ='CLOSE_ASSET_CALC_MODAL_CLOSE_ERROR';

const assetRiskCalculationState={
    asset_risk_calculation_success:false,
    asset_risk_calculation_error:null,
}


export function AssetRiskCalculationAllReducer(state=assetRiskCalculationState,action){
    if(action.type === ASSET_RISK_CALCULATION_SUCCESS){
        return {
            ...assetRiskCalculationState,
            asset_risk_calculation_success:true,
            asset_risk_calculation_error:null,
    
        }
    }
    else if(action.type === ASSET_RISK_CALCULATION_ERROR){
        return {
            ...assetRiskCalculationState,
            asset_risk_calculation_success:false,
            asset_risk_calculation_error:action.payload.error,
        }
    }
    
    else if(action.type === CLOSE_ASSET_RISK_CALCULATION_MODAL){
        return {
            ...assetRiskCalculationState,
            asset_risk_calculation_success:false,
            asset_risk_calculation_error:null,
    
        }
    }
    else if(action.type === EDIT_CLOSE_ASSET_RISK_CALCULATION_MODAL){
        return {
            ...assetRiskCalculationState,
            asset_risk_calculation_success:false,
            asset_risk_calculation_error:null,
    
        }
    }
    else if(action.type === CLOSE_ASSET_CALC_MODAL_CLOSE_ERROR){
        return {
            ...assetRiskCalculationState,
            asset_risk_calculation_error:null,
    
        }
    }
    return state;
}