import { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { ListingAction } from "../../Components";
import "./listing.css";
import { RealVolume } from "../../Components/AssetInsight/RealVolume";
import { HoldersConcentration } from "../../Components/AssetInsight/HoldersConcentration";
import { Maturity } from "../../Components/AssetInsight/Maturity";
import { useNavigate } from "react-router-dom";
import { AssetsLists } from "../../Components/AssetInsight/AssetsList";
import { useSelector } from "react-redux";
import { Security } from "../../Components/AssetInsight/Security";
import { General } from "../../Components/AssetInsight/General";

export const Listing = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const onSubmitTab = (tab) => {
    setActiveTab(tab);
  };
  const created = useSelector((state) => state.ListingAssetsReducer.created);
  useEffect(() => {
    if (created) {
      setOpen(false);
      success("The asset has been successfully added.");
      onSubmitTab(0);
    }
  }, [created]);
  return (
    <div className="tabs_container">
      {contextHolder}
      <div className="assets_nav">
        <Button
          onClick={() => {
            setOpen(true);
            setAssetId(null);
          }}
          className="add_assets"
        >
          Create Asset
        </Button>
        <Button
          className="add_assets"
          onClick={() => {
            onSubmitTab(0);
          }}
        >
          Assets List
        </Button>
      </div>
      <Modal
        title={`${!assetId ? "Create Asset" : "Update Asset"}`}
        width={700}
        visible={open}
        setVisible={setOpen}
        onCancel={() => setOpen(!open)}
        footer={false}
        destroyOnClose={true}
      >
        <div>
          <ListingAction assetId={assetId} />
        </div>
      </Modal>

      <div className="tab_assets_block">
        <Button onClick={() => onSubmitTab(1)} className="tab_assets">
          Real Volume
        </Button>
        <Button onClick={() => onSubmitTab(2)} className="tab_assets">
          Market Cap
        </Button>
        <Button onClick={() => onSubmitTab(3)} className="tab_assets">
          Holders Concentration
        </Button>
        <Button onClick={() => onSubmitTab(4)} className="tab_assets">
          Maturity
        </Button>
        <Button onClick={() => onSubmitTab(5)} className="tab_assets">
          Security
        </Button>
        <Button onClick={() => onSubmitTab(6)} className="tab_assets">
          General
        </Button>
      </div>
      {activeTab === 0 && (
        <AssetsLists setOpen={setOpen} setAssetId={setAssetId} />
      )}

      {activeTab === 1 && (
        <RealVolume setOpen={setOpen} setAssetId={setAssetId} />
      )}
      {activeTab === 2 && <span> Content of Tab Pane 2</span>}
      {activeTab === 3 && <HoldersConcentration />}
      {activeTab === 4 && <Maturity />}
      {activeTab === 5 && <Security />}
      {activeTab === 6 && <General />}
    </div>
  );
};
