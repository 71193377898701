import React from "react";
import { Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

const Sidebar = () => {
  const { pathname } = useLocation();
  const history = useNavigate();

  function getItem(label, key) {
    return { key, label };
  }

  const items = [
    getItem("Assets Insight", "0", 0),
    getItem("DeFi Shield / Light DeFi", "1", 1),
    getItem("Counterparty Risk ", "2", 2),
    getItem("Light NFT", "3", 3),
    getItem("Custody Shield", "4", 4),
    getItem("Clients", "5", 5),
    getItem("Recycle Bin", "6", 6),
  ];

  const navigateFnc = (key) => {
    switch (key) {
      case "0":
        return history("/");
      case "1":
        return history("/defi-shield");
      case "2":
        return history("/counterparty-risk");
      case "3":
        return history("/light-ntf");
      case "4":
        return history("/wallet-shield");
      case "5":
        return history("/clients");
      case "6":
        return history("/user/trash");

      default:
        return history("/");
    }
  };

  const selectedKeysFnc = () => {
    switch (pathname) {
      case "/":
        return "0";
      case "/defi-shield":
        return "1";
      case "/counterparty-risk":
        return "2";
      case "/light-ntf":
        return "3";
      case "/wallet-shield":
        return "4";
      case "/clients":
        return "5";
      case "/user/trash":
        return "6";

      default:
        return "0";
    }
  };
  return (
    <div className="sidebar_box">
      <Menu
        // style={{ width: 200 }}
        onSelect={(e) => navigateFnc(e.key)}
        onClick={(e) => navigateFnc(e.key)}
        defaultSelectedKeys={() => selectedKeysFnc()}
        mode="inline"
        items={items}
      />
    </div>
  );
};

export default Sidebar;
