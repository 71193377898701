export const Blockchain_CALC_SUCCESS ='Blockchain_CALC_SUCCESS';
export const Blockchain_CALC_ERROR ='Blockchain_CALC_ERROR';
export const CLOSE_Blockchain_CALC_MODAL = 'CLOSE_Blockchain_CALC_MODAL';
export const EDIT_CLOSE_Blockchain_CALC_MODAL ='EDIT_CLOSE_Blockchain_CALC_MODAL';
export const CLOSE_Blockchain_CALC_MODAL_CLOSE_ERROR = 'CLOSE_Blockchain_CALC_MODAL_CLOSE_ERROR';

const createBlockchainCalcState={
    calc_blockchain_success:false,
    calc_blockchain_error:null,
}


export function BlockchainRiskCalcReducer(state=createBlockchainCalcState,action){
     if(action.type === Blockchain_CALC_SUCCESS){
        return {
            ...createBlockchainCalcState,
            calc_blockchain_success:true,
            calc_blockchain_error:null,
    
        }
    }
    else if(action.type === Blockchain_CALC_ERROR){
        return {
            ...createBlockchainCalcState,
            calc_blockchain_success:false,
            calc_blockchain_error:action.payload.error,
        }
    }
    
    else if(action.type === CLOSE_Blockchain_CALC_MODAL){
        return {
            ...createBlockchainCalcState,
            calc_blockchain_success:false,
            calc_blockchain_error:null,
    
        }
    }

    else if(action.type === EDIT_CLOSE_Blockchain_CALC_MODAL){
        return {
            ...createBlockchainCalcState,
            calc_blockchain_success:false,
            calc_blockchain_error:null,
    
        }
    }
    else if(action.type === CLOSE_Blockchain_CALC_MODAL_CLOSE_ERROR){
        return {
            ...createBlockchainCalcState,
            calc_blockchain_error:null,
    
        }
    }

    return state;
}