import { Modal } from 'antd';
import { Form, Input, Button, Checkbox } from 'antd';
import './modal.css';
import {React,useEffect,useState} from 'react';
import { createAsset } from '../../Services';
import { showErrorMessage } from '../../Helpers/ErrorMessages';
import { useDispatch,useSelector } from 'react-redux';

import { STABLECOIN_EDIT_ASSET_SUCCESS } from '../../Store/Reducers/StableCoinEditAssetVisiblityReducer';
import { STABLECOIN_EDIT_ASSET_CLOSE_MODAL } from '../../Store/Reducers/StableCoinEditAssetVisiblityReducer';

import {StableCoinEditAssetModal} from '../../Modals/StableCoinEditAssetModal';


export const CreateAssetModal = ({ visible, setVisible, successCb }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();


  const [stableCoinEditAssetModal, setstableCoinEditAssetModal] = useState(false);

  const asset_risk_calculation_success = useSelector((state) => {
    return state.AssetRiskCalculationAllReducer.asset_risk_calculation_success;
});


  const onFinish = async (values) => {


    try {
      const response = await createAsset({ ...values, defaultValue: +values.defaultValue });
      form.resetFields();
      successCb();
      setVisible(false);
      if (!values.stableCoin) {
        localStorage.setItem('assetId', response.data?.data?.assets?.id);
        dispatch({
          type: STABLECOIN_EDIT_ASSET_SUCCESS
        })
        setstableCoinEditAssetModal(true)
      }

    }
    catch (error) {
      const { response } = error;
      const { data } = response;
      showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]);
    }
  }

  const cencelstableCoinEditAssetModal = () => {
    setstableCoinEditAssetModal(false);
  }



  const maskClosablestableCoinEditAssetModal = () => {
    dispatch({
      type: STABLECOIN_EDIT_ASSET_CLOSE_MODAL
    });
  }

  
  useEffect(()=>{
    if(asset_risk_calculation_success){
      setstableCoinEditAssetModal(false)
    }

  },[asset_risk_calculation_success])



  return (
    <>
      <Modal title='Add Asset' visible={visible} footer={null} onCancel={() => setVisible(false)}>
        <Form
          name="basic"
          className="lightcore_login_form"
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          layout="vertical"
        >

          <Form.Item
            name="stableCoin"
            label="Stable coin:"
            valuePropName="checked"
            rules={[
              {
                required: false,
                message: 'This field is required.'
              },
            ]}
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item
            name="symbol"
            label="Asset Name (Symbol):"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder='Symbol...' />
          </Form.Item>
          <Form.Item
            name="name"
            label="Asset ID:"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
            ]}
          >
            <Input placeholder="Name..." />
          </Form.Item>


          <Form.Item
            name="defaultValue"
            label="Default Risk:"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
              {
                pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                message: 'Invalid value.'
              }
            ]}
          >
            <Input placeholder='Default value...' />
          </Form.Item>
          <div className="lightcore_login_submit_button">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {
        stableCoinEditAssetModal ?
          <Modal title="Edit Asset" width={1300}
            visible={stableCoinEditAssetModal}
            footer={false}
            onCancel={cencelstableCoinEditAssetModal}
            afterClose={maskClosablestableCoinEditAssetModal}
            destroyOnClose={true}
          >
            <StableCoinEditAssetModal visiblete={stableCoinEditAssetModal} 
            closeModal={((event)=>{
              setstableCoinEditAssetModal(event)
            })} />
          </Modal> : ''
      }


    </>


  );
};
