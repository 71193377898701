import { React, useState, useEffect } from 'react';

import { Form, Input } from 'antd';

import { GetAssetPriceRiskCalcParamsNoStableCoin } from '../../../../Services/index';

import { GetAssetCalcParamsById } from '../../../../Services/index';


export function AssetPriceRiskNonStableCoins({ assetPriceRiskProps, element }) {

    const [form] = Form.useForm();
    const [assetPriceRisk, setAssetPriceRisk] = useState({ type: '5', percent: '', scoreMultiplier: '' });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };


    useEffect(() => {

        if (!element) {
            GetAssetPriceRiskCalcParamsNoStableCoin()
                .then((result) => {
                    form.setFieldsValue({
                        percent: result?.data?.data?.calcParams[0].percent || '',
                        scoreMultiplier: result?.data?.data?.calcParams[0].scoreMultiplier || '',
                    })
                    setAssetPriceRisk({ type: '5', percent: result?.data?.data?.calcParams[0].percent, scoreMultiplier:result?.data?.data?.calcParams[0].scoreMultiplier })
                })
        }
        else {

            GetAssetCalcParamsById(element?.id, 2)
                .then((result) => {
                    form.setFieldsValue({
                        percent: result?.data?.data?.percent || '',
                        scoreMultiplier: result?.data?.data?.percent || '',
                    })
                    setAssetPriceRisk({
                        type: '2',
                        percent: result?.data?.data?.percent,
                        scoreMultiplier: result?.data?.data?.scoreMultiplier,
                    })
                })
        }


    }, [element]);

    return (
        <>
            <div className='no_stable_coin_form'>
                {
                    element ? 
                    <h2>Asset Risk Calculation (Non-Stable Coins)</h2> :''
                }
                
                <Form
                    style={{ display: 'flex', alignItems: 'center' }}
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <b>Asset_Price_Risk (Non-Stable Coins):</b>
                    <p style={{ margin: '0 2px' }}>Every</p>
                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="percent"
                        rules={[{ required: false, message: 'Please input your percent!' }]}
                        onFocus={() => deleteErrorButton('percent')}
                    >
                        <Input style={{ margin: '0' }}
                            onChange={((event) => {
                                if (element) {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '2',
                                        percent: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }
                                else {
                                    const changedData = {
                                        ...assetPriceRisk,
                                        type: '5',
                                        percent: event.target.value,
                                    }
                                    setAssetPriceRisk(changedData)
                                    assetPriceRiskProps(changedData)
                                }


                            })} />
                    </Form.Item>
                    <p style={{ margin: '0 2px' }}>% decrease in</p> <b style={{ margin: '0 2px' }}>Asset_Current_Price</b> <p style={{ margin: '0 2px' }}>below</p> <b style={{ margin: '0 2px' }}> Asset_7_14DaysAvg_Price </b>
                    <p style={{ margin: '0 2px' }}>subtracts</p>
                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="scoreMultiplier"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your scoreMultiplier!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('scoreMultiplier')}
                    >
                        <Input onChange={((event) => {
                            if (element) {
                                const changedData = {
                                    ...assetPriceRisk,
                                    type: '2',
                                    scoreMultiplier: event.target.value,
                                }
                                setAssetPriceRisk(changedData)
                                assetPriceRiskProps(changedData)
                            }
                            else {
                                const changedData = {
                                    ...assetPriceRisk,
                                    type: '5',
                                    scoreMultiplier: event.target.value,
                                }
                                setAssetPriceRisk(changedData)
                                assetPriceRiskProps(changedData)
                            }
                           


                        })} />
                    </Form.Item>  <p style={{ margin: '0 2px' }}>% from the initial</p> <b style={{ margin: '0 2px' }}>  Asset_Basic_Risk</b> <p style={{ margin: '0 2px' }}>estimate.</p>
                </Form>
            </div>
        </>
    )
}






