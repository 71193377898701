import { Button } from 'antd';
import React from 'react';
import { deleteAsset, deleteBlockChainAndPlatform } from '../../Services';

export const TableColumn = ({ title, type, assetId, blockChainId, platformId, onPressEdit, cb, defaultValue }) => {

    const deleteAssetAPI = async () => {
        try {
            const response = await deleteAsset(assetId);
            cb();
        }
        catch (error) {
            //
        }
    }

    const deleteBlockChainAndPlatformAPI = async () => {
        try {
            const response = await deleteBlockChainAndPlatform(blockChainId.toString(), platformId.toString());
            cb();
        }
        catch (error) {
            //
        }
    }

    const onPressDelete = () => {
        if (type === 'asset') {
            deleteAssetAPI();
        }
        else {
            deleteBlockChainAndPlatformAPI();
        }
    }
    return (
        <div>
            <p>{title} <span style={{marginLeft:5}}>{defaultValue}</span></p>
            <Button onClick={onPressEdit} style={{marginRight: 5}}>Edit</Button>
            <Button onClick={onPressDelete}>Delete</Button>
        </div>
    )
}