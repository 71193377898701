import { USER_COUNTERPARTY_RISK_GET_SUCCESS } from '../Reducers/UserCounterpartyRiskReducer';

import { ApiGrtUserCounterparty } from '../../Services';


export function UserCounterpartyRiskThunk(userId) {
    return (dispatch, getState) => {
        ApiGrtUserCounterparty(userId)
            .then((result) => {
                dispatch({
                    type: USER_COUNTERPARTY_RISK_GET_SUCCESS,
                    payload: {
                        result: result.data.data
                    }
                })
            })
    }
}