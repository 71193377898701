import {
  ApiGetListingSecurityKeys,
  ApiGetListingSecurityValue,
} from "../../Services";
import {
  LISTING_SECURITY_KEYS_FAIL,
  LISTING_SECURITY_KEYS_REQUEST,
  LISTING_SECURITY_KEYS_SUCCESS,
  LISTING_SECURITY_VALUE_REQUEST,
  LISTING_SECURITY_VALUE_SUCCESS,
  LISTING_SECURITY_VALUE_FAIL,
} from "../Actions";

export const ListingSecurityKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_SECURITY_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingSecurityKeys();
      const { data } = response;

      dispatch({
        type: LISTING_SECURITY_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_SECURITY_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingSecurityValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_SECURITY_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingSecurityValue();
      const { data } = response;

      dispatch({
        type: LISTING_SECURITY_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_SECURITY_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
