import { React, useEffect, useState } from 'react';
import { Form, Button, Input } from "antd";
import { useDispatch, useSelector } from 'react-redux';

import { CounterpartyRiskcalculationThunk } from '../../Store/Thunks/CounterpartyRiskcalculationThunk';


import { CLOSE_COUNTER_PARTY_CALC_MODAL, EDIT_CLOSE_COUNTER_PARTY_CALC_MODAL } from '../../Store/Reducers/CounterpartyRiskcalculationReducer';

import { GetCounterPartyCalcParams } from '../../Services/index';

export function CounterpartyRiskCalculationModal({ element }) {
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const [stateError, setStateError] = useState(null);

    const calc_counter_party_success = useSelector((state) => {
        return state.CounterpartyRiskcalculationReducer.calc_counter_party_success;
    });

    const calc_counter_party_error = useSelector((state) => {
        return state.CounterpartyRiskcalculationReducer.calc_conter_party_error;
    });


    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
            setStateError(null)

        }
    };

    const onFinish = (values) => {
        dispatch(CounterpartyRiskcalculationThunk(11, values.percent, values.scoreMultiplier,));
        if (element && element.id) {
            dispatch({
                type: EDIT_CLOSE_COUNTER_PARTY_CALC_MODAL
            })

        }
        else {
            dispatch({
                type: CLOSE_COUNTER_PARTY_CALC_MODAL
            })
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            setStateError(null)
            form.resetFields();
        }

    };

    useEffect(() => {
        if (calc_counter_party_success) {
            form.resetFields();
            setStateError(null);
            GetCounterPartyCalcParams()
                .then((result) => {
                })
        }

    }, [calc_counter_party_success]);


    useEffect(() => {
        if (calc_counter_party_error) {
            setStateError(calc_counter_party_error)

        }
        else {
            setStateError(null)
        }

    }, [calc_counter_party_error]);

    useEffect(() => {
        GetCounterPartyCalcParams()
            .then((result) => {
                form.setFieldsValue({
                    percent: result?.data?.data?.calcParams[0].percent || '',
                    scoreMultiplier: result?.data?.data?.calcParams[0].scoreMultiplier || ''
                })
            })
            .catch(() => {

            })
    }, []);


    return (
        <>

            <div className='calc__modal'>
                <div>
                    {
                        calc_counter_party_error ?
                            <p className='error_container'>{stateError}</p> : null
                    }

                    <h2 className='calc_modal_description_title'>Counterparty Risk Calculation</h2>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className='no_stable_coin_form calc__form__modal'>
                            <b style={{ margin: '0 2px' }}>Counterparty_Risk:</b> <p style={{ margin: '0 2px' }}>Every</p>    <Form.Item
                                label=""
                                name="percent"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                                onFocus={() => deleteErrorButton('percent')}
                            >
                                <Input style={{ width: '50px' }} />
                            </Form.Item>  <p style={{ margin: '0 2px' }}>%</p>
                            <p style={{ margin: '0 2px' }}>decrease in</p> <b style={{ margin: '0 2px' }}>BTC_ETH10_Price</b>

                            <p style={{ margin: '0 2px' }}>below</p>
                            <br></br>
                            <div className='no_stable_coin_form calc__form__modal'>
                                <b style={{ margin: '0 2px' }}>BTC_ETH10_7_14DaysAvg_Price</b>
                                <p style={{ margin: '0 2px' }}>subtracts</p>  <Form.Item
                                    label=""
                                    name="scoreMultiplier"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                    onFocus={() => deleteErrorButton('scoreMultiplier')}
                                >
                                    <Input style={{ width: '50px' }} />
                                </Form.Item><p>%</p>
                                <p style={{ margin: '0 2px' }}>from the initial</p> <b style={{ margin: '0 2px' }}>Base_Counterparty_Risk</b>
                                <p style={{ margin: '0 2px' }}>estimate.</p>
                            </div>
                        </div>
                        <Form.Item style={{ margin: '20px auto' }}>
                            <Button type="primary" htmlType="submit">Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}