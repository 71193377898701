export const USER_LIGHT_DEFI_GET_SUCCESS = 'USER_LIGHT_DEFI_GET_SUCCESS';


const userLightDefiState = {
    userLightDefiResult: [],
}

export const UseLightDefiReducer = (state=userLightDefiState, action) => {

    if (action.type === USER_LIGHT_DEFI_GET_SUCCESS) {
        return {
            ...userLightDefiState,
            userLightDefiResult: action.payload.result,
        }

    }
    return state;
}