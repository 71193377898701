import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Table} from 'antd';

import { useDispatch, useSelector } from "react-redux";
import {
  ListingHoldersConcentrationValueThunk,
  ListingHoldersConcentrationKeysThunk,
} from "../../Store/Thunks/ListingHoldersConcentrationThunk";
import { ApiPutListingHoldersConcentrationValue } from '../../Services';
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber   className="editable-field" /> : <Input  className="editable-field" />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export const HoldersConcentration = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    dispatch(ListingHoldersConcentrationKeysThunk());
    dispatch(ListingHoldersConcentrationValueThunk());
  }, []);


const {
  ListingHoldersConcentrationKeyData,
  ListingHoldersConcentrationKeyLoading,
   ListingHoldersConcentrationValueData,
  ListingHoldersConcentrationValueLoading,
} = useSelector((state) => state.ListingHoldersConcentrationReducer);

 const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };

  const dataSource = () => {
    const arr = [];
    ListingHoldersConcentrationValueData.map((item, index) => {
      const obj = {};
       ListingHoldersConcentrationKeyData.map((element) => {
        obj[element.key] = item.listingHoldersConcentration[element.key]
        obj.id =  item.listingHoldersConcentration.id
     
      });
      arr.push({
        name: item.assetName,
        ...obj,
        key: index,
      });
    });
    setData(arr)
    return arr;
  };


  useEffect(() => {
    if (
      ListingHoldersConcentrationKeyData?.length &&
      ListingHoldersConcentrationValueData.length
    ) {
      dataSource()
    }
  }, [ListingHoldersConcentrationKeyData,ListingHoldersConcentrationValueData,]);


  const editAssetValue = ()=>{
    setLoading(true)
    const  editData = data.map(item => {
    const newItem = {};
    for (const key in item) {
        if (key !== 'key' && key !== 'name') {
            newItem[key] = parseFloat(item[key]);
        }
    }
    return newItem;
});
  ApiPutListingHoldersConcentrationValue(editData).then(()=>{
    setLoading(false)
  }).catch(()=>{
    setLoading(false)
  })
  }

  useEffect(() => {
    if (isSaveClicked) {
      editAssetValue();
    }
    setIsSaveClicked(false);
  }, [isSaveClicked]);
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
      setIsSaveClicked(true)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };



  const columns  =  ()=>{
    return[
      {
        title: 'Asset',
        dataIndex: 'name',
        fixed:'left',
        width:100
     
      },
      ...ListingHoldersConcentrationKeyData.map((item) => ({
        title: item.name,
        dataIndex: item.key,
        editable: true,
        render: (text) => (
          <span>{/^-|\d+(\.\d+)?$/.test(text)
            ? text && text != "0" ? Number(text).toFixed(0).toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      
    : null
    :text}  </span>
        ),   width:80
       
      })),
      // {
      //   title: 'Operation',
      //   dataIndex: 'operation',
      //   fixed:'right',
      //   width:100,
      //   render: (_, record) => {
      //     const editable = isEditing(record);
      //     return editable ? (
      //       <span>
      //         <Typography.Link
      //           onClick={() => save(record.key)}
      //           style={{
      //             marginRight: 8,
      //           }}
      //         >
      //           Save
      //         </Typography.Link>
      //         <Popconfirm title="Sure to cancel?" onConfirm={cancel} okText="Yes">
      //           <a>Cancel</a>
      //         </Popconfirm>
      //       </span>
      //     ) : (
      //       <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
      //         Edit
      //       </Typography.Link>
      //     );
      //   },
      // },
    ]
  }


  const mergedColumns = columns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  
  return (
    <div>
      {/* <Button className='asset_save' onClick={editAssetValue}>
        SAVE
      </Button> */}
    <Form form={form} component={false}>
      <Table
      loading={ListingHoldersConcentrationKeyLoading || ListingHoldersConcentrationValueLoading  || loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        pagination={false}
        bordered
        dataSource={data}
        className="custom-scroll"
        columns={mergedColumns}
        rowClassName="editable-row"
        // scroll={{
        //   x: '80vw', // Horizontal scroll
        //   // y: 500, // Vertical scroll
        // }}
        scroll={{ y: 500 }}
      />
    </Form>
    </div>

  );
};

