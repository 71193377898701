import  {COUNTER_PARTY_CALC_SUCCESS,COUNTER_PARTY_CALC_ERROR } from '../Reducers/CounterpartyRiskcalculationReducer';
import {CounterPartyCalc} from '../../Services/index';



export function CounterpartyRiskcalculationThunk(type,percent,scoreMultiplier) {
    return (dispatch, getState) => {
        CounterPartyCalc(type,+percent,+scoreMultiplier)
            .then((result) => {
                dispatch({
                    type: COUNTER_PARTY_CALC_SUCCESS
                })
            })
            .catch((error)=>{
                dispatch({
                    type:COUNTER_PARTY_CALC_ERROR,
                    payload:{
                        error:error?.response?.data?.error?.message,
                    }
                })
            })
    }
}