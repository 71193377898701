import { React, useState, useEffect } from 'react';

import { Button, Modal } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import { CreatePartnerGuardModal } from '../../Modals/CreatePartnerGuardModal';
import {CounterpartyRiskCalculationModal} from '../../Modals/CounterpartyRiskCalculationModal';

import { CLOSE_PARTNER_GUARD_CREATE_MODAL } from '../../Store/Reducers/CreatePartnerGuardReducer';

import { GetPartnerGuardThunk } from '../../Store/Thunks/GrtPartnerGuardThunk';

import { PartnerGuardItem } from '../../Components/PartnerGuardItem';

import { ApiDeletePartnerGuardItem } from '../../Services/index';

import {CLOSE_COUNTER_PARTY_CALC_MODAL_CLOSE_ERROR} from '../../Store/Reducers/CounterpartyRiskcalculationReducer';



export function PartnerGuard() {

    const dispatch = useDispatch();

    const [isModalOpen, setisModalOpen] = useState(false);

    const [isCounterpartyRiskCalculationModalOpen,setIsCounterpartyRiskCalculationModalOpen] = useState(false);


    const create_partner_guard_success = useSelector((state) => {
        return state.CreatePartnerGuardReducer.partner_guard_success;
    });

    const partnerGuardResult = useSelector((state) => {
        return state.GetPartnerGuardReducer.partnerGuardResult;
    })


    const edit_partner_guard_success = useSelector((state) => {
        return state.EditPartnerGuardReducer.edit_partner_guard_success;
    });


    const calc_counter_party_success = useSelector((state) => {
        return state.CounterpartyRiskcalculationReducer.calc_counter_party_success;
    });


    const handleCancel = () => {
        setisModalOpen(false);
    }

    const counterpartyCalculationhandleCancel = () =>{
        setIsCounterpartyRiskCalculationModalOpen(false);
    }



    const maskClosable = () => {
        dispatch({
            type: CLOSE_PARTNER_GUARD_CREATE_MODAL
        })
    };

    const counterpartyCalculationmaskClosable = () =>{
        dispatch({
            type:CLOSE_COUNTER_PARTY_CALC_MODAL_CLOSE_ERROR
        })
    }

    useEffect(() => {
        if (create_partner_guard_success) {
            setisModalOpen(false);
            dispatch(GetPartnerGuardThunk());

        }

    }, [create_partner_guard_success]);

    useEffect(() => {
        dispatch(GetPartnerGuardThunk());

    }, []);

    useEffect(()=>{
        if(edit_partner_guard_success){
            dispatch(GetPartnerGuardThunk());
        }
      

    },[edit_partner_guard_success]);

    useEffect(() => {
        if (calc_counter_party_success) {
            setIsCounterpartyRiskCalculationModalOpen(false);
        }

    }, [calc_counter_party_success]);



    return (
        <>
            <div className="tabs_container">
                <div className='header__container'>
                    <Button style={{ width: '25%' }} onClick={(() => {
                        setisModalOpen(true)
                    })}>Add Counterparty</Button>
                     <Button style={{ width: '25%',marginLeft:'10px' }} onClick={(() => {
                        setIsCounterpartyRiskCalculationModalOpen(true)
                    })}>Counterparty Risk calculation</Button>
                </div>

                <Modal   width={700} title="Counterparty Risk calculation"  visible={isCounterpartyRiskCalculationModalOpen} 
                footer={false} destroyOnClose={true} 
                onCancel={counterpartyCalculationhandleCancel}
                afterClose={counterpartyCalculationmaskClosable}

                >
                    <CounterpartyRiskCalculationModal/>
                </Modal>
                
                <Modal title="Add Counterparty" visible={isModalOpen} footer={false} onCancel={handleCancel} afterClose={maskClosable} destroyOnClose={true}>
                    <CreatePartnerGuardModal />
                </Modal>
                {
                    partnerGuardResult?.length ?
                        <>
                            {
                                partnerGuardResult?.map((element, index) => {
                                    return <PartnerGuardItem key={index + 1} element={element} deleteItem={((element) => {
                                        ApiDeletePartnerGuardItem(element.id)
                                            .then((result) => {
                                                if (result) {
                                                    dispatch(GetPartnerGuardThunk());
                                                }
                                            })
                                    })} />
                                })
                            } </> : <p>No Details</p>


                }
            </div>
        </>
    )
}
