import React from "react";
import { userShieldType } from "../Helpers/ApiParams";
import { Button } from "antd";
import { useSelector } from "react-redux";

const ShieldBox = ({ AbleBtn, DisableBtn, loadingTitle, title, text }) => {
  const { userShieldStates, userShieldsLoading } = useSelector(
    (state) => state.UserCardsReducer
  );

  return (
    <div className="tab_title_box">
      {!userShieldStates[text] ? (
        <Button
          loading={userShieldsLoading && loadingTitle.current === text}
          onClick={() => AbleBtn(text)}
          className="user_access_btn"
        >
          Enable
        </Button>
      ) : (
        <Button
          loading={userShieldsLoading && loadingTitle.current === text}
          onClick={() => DisableBtn(text)}
          className="user_shield_box_block_cancel"
        >
          Disable
        </Button>
      )}

      <span className="tab_pane_text" onClick={() => {}}>
        {title}
      </span>
    </div>
  );
};

export default ShieldBox;

// <div className="user_shield_box">
//     {
//         Object.keys(userShieldStates).length ? userShieldType.map((item, index) => (
//             <div key={index} className="user_shield_box_block">
//                 <span className="user_shield_box_block_text">{item[0]}</span>
//                 {!userShieldStates[item[1]] ?
//                     <Button loading={userShieldsLoading && loadingTitle.current === item[1]}
//                             onClick={() => AbleBtn(item[1])} className="user_access_btn">Enable</Button>
//                     :
//                     <Button loading={userShieldsLoading && loadingTitle.current === item[1]}
//                             onClick={() => DisableBtn(item[1])}
//                             className="user_shield_box_block_cancel">Disable</Button>
//                 }
//             </div>
//         )) : null
//     }
// </div>
