import {
    GET_DEFISHIELD_SUCCESS,
    GET_USER_SHIELDS_REQUEST,
    GET_USER_SHIELDS_SUCCESS, 
    USER_SHIELD_REQUEST,
    USER_SHIELD_SUCCESS,
    // USER_PARTNERS_GUARD_SUCCESS
} from "../Actions";

const initialState = {
    usersDefiShields: [],
    userShieldStates:{},
    userShieldSuccess:false,
    userShieldsLoading:false
}


export const UserCardsReducer = (state = initialState, action) => {
    switch (action.type){

        case GET_DEFISHIELD_SUCCESS:
            return {
                ...state,
                usersDefiShields:action.payload.data
            }
        case GET_USER_SHIELDS_REQUEST:{
            return {
                ...state,
                userShieldsLoading:true
                // userShieldStates:{}
            }
        }
        case GET_USER_SHIELDS_SUCCESS:{
                return {
                    ...state,
                    userShieldStates:action.payload.data,
                    userShieldSuccess:false,
                    userShieldsLoading:false
                }
            }

        case USER_SHIELD_REQUEST:{
            return {
                ...state,
                userShieldSuccess:false
            }
        }
        case USER_SHIELD_SUCCESS:{
            return {
                ...state,
                userShieldSuccess:action.payload.success
            }
        }
        // case USER_PARTNERS_GUARD_SUCCESS:
        //     return {
        //         ...state,
        //         usersPartnersGuard:action.payload.data
        //     }

        default:
            return state;
    }

}



