export const foundersTeamOptions = {
  Reliable: {
    value: "Reliable",
    style: { color: "#4DC7B5" },
    label: "Reliable",
  },
  Unreliable: {
    value: "Unreliable",
    style: { color: "#F27281" },
    label: "Unreliable",
  },
  Anonymous: {
    value: "Anonymous",
    style: { color: "#F5B452" },
    label: "Anonymous",
  },
};
export const reputationOptions = {
  Positive: {
    value: "Positive",
    style: { color: "#4DC7B5" },
    label: "Positive",
  },
  Negative: {
    value: "Negative",
    style: { color: "#F27281" },
    label: "Negative",
  },
};
export const amlOptions = {
  NoAMLActivities: {
    value: "No AML Activities",
    style: { color: "#4DC7B5" },
    label: "No AML Activities",
  },
  ConnectedtoAMLActivities: {
    value: "AML Activities",
    style: { color: "#F27281" },
    label: "AML Activities",
  },
};
export const lawsuitsOptions = {
  NoLawsuits: {
    value: "No Lawsuits",
    style: { color: "#4DC7B5" },
    label: "No Lawsuits",
  },
  LawsuitsExist: {
    value: "Lawsuits Exist",
    style: { color: "#F27281" },
    label: "Lawsuits Exist",
  },
};
export const communityMediaOptions = {
  StrongCommunity: {
    value: "Strong Community",
    style: { color: "#4DC7B5" },
    label: "Strong Community",
  },
  NoCommunity: {
    value: "No Community",
    style: { color: "#F5B452" },
    label: "No Community",
  },
};
