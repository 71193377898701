import { Modal, Select } from 'antd';
import { Form, Input, Button } from 'antd';
import './modal.css';
import React, { useEffect, useState } from 'react';
import { createBlockChain, getAssets } from '../../Services';
import { showErrorMessage } from '../../Helpers/ErrorMessages';

export const CreateBlockChain = ({ visible, setVisible, successCb }) => {
    const { Option } = Select;
    const [assets, setAssets] = useState([]);
    const [form] = Form.useForm();
    const [assetId, setAssetId] = useState(null);

    // get assets
    const getAssetsAPI = async () => {
        try {
            const response = await getAssets();
            setAssets(response.data.data);
        }
        catch(error) {
            //
        }
    }

    useEffect(() => {
        if (visible) {
          getAssetsAPI();
        }
    }, [visible]);

    const onFinish = async (values) => {
        const data = {
            assetId: +assetId,
            blockchain: {
                name: values.blockChainName,
                defaultValue: +values.blockChaindefaultValue,
                symbol: values.blockchainSymbol
            },
            platform: {
                name: values.platformName,
                defaultValue: +values.platformdefaultValue,
                symbol: values.platformSymbol
            }
        };
        try {
            const response = await createBlockChain({...data});
            form.resetFields();
            successCb();
            setVisible(false);
            
        }
        catch(error) {
            const { response } = error;
            const { data } = response;
            showErrorMessage(typeof data?.error?.message === 'string' ? data?.error?.message : data?.error?.message[0]);
        }
    }

    return (
        <Modal title='Add BlockChain/Platform' visible={visible} footer={null} onCancel={() => setVisible(false)}>
            <Form
              name="basic"
              form={form}
              className="lightcore_login_form"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Asset:"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'This field is required.'
                  },
                ]}
              >
                <Select onChange={(e) => setAssetId(e)}>
                    {assets.map(item => (
                        <Option key={item.id}>{item.symbol}</Option>
                    ))}
                </Select>
              </Form.Item>
        
              <Form.Item
              label="Blockchain Name:"
                name="blockChainName"
                rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                  ]}
              >
                <Input placeholder='Blockchain Name...'/>
              </Form.Item>

              <Form.Item
              label="Blockchain ID:"
                name="blockchainSymbol"
                rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                  ]}
              >
                <Input placeholder='Blockchain ID...'/>
              </Form.Item>

              <Form.Item
                label="BlockChain Default Risk:"
                name="blockChaindefaultValue"
                rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                    {
                      pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                      message: 'Invalid value.'
                    }
                  ]}
              >
                <Input placeholder='BlockChain Default value...'/>
              </Form.Item>

              <Form.Item
                   label="Platform Name:"
                name="platformName"
                rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                  ]}
              >
                <Input placeholder='Platform Name...'/>
              </Form.Item>

              <Form.Item
              label="Platform ID:"
                name="platformSymbol"
                rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                  ]}
              >
                <Input placeholder='Platform ID...'/>
              </Form.Item>

              <Form.Item
              label='Platform Default Risk:'
                name="platformdefaultValue"
                rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                    {
                      pattern: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                      message: 'Invalid value.'
                    }
                  ]}
              >
                <Input placeholder='Platform Default value...'/>
              </Form.Item>
              
            <div className="lightcore_login_submit_button">
            <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
            </Form>
        </Modal>
    );
};
