import { React, useEffect, useState } from 'react';

import { Form, Input } from 'antd';

import { GetProtuctRiskCalculatioanAssetParams } from '../../Services/index';

export function ProdictRiskCalculationAsset({ assetCalcProps }) {

    const [form] = Form.useForm();

    const [asseCalcRisk, setAssetCalcRisk] = useState({ type: '1', scoreDividend: '', scoreDivisor: '' });

    const deleteErrorButton = (fieldKey) => {
        if (fieldKey) {
        }
    };

    const onFinishFailed = (errorInfo) => {
        if (!errorInfo) {
            form.resetFields();
        }
    };


    useEffect(() => {
        GetProtuctRiskCalculatioanAssetParams()
            .then((result) => {
                form.setFieldsValue({
                    scoreDividend: result?.data?.data?.calcParams[0].scoreDividend || '',
                    scoreDivisor: result?.data?.data?.calcParams[0].scoreDivisor || '',
                })
                setAssetCalcRisk({type:'1',scoreDividend:result?.data?.data?.calcParams[0].scoreDividend,scoreDivisor:result?.data?.data?.calcParams[0].scoreDivisor})
            })
          
    }, []);


    return (
        <>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div style={{ display: 'flex' }}>
                    <Form.Item
                        style={{ width: '50px' }}
                        label=""
                        name="scoreDividend"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your scoreDividend!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('scoreDividend')}
                    >
                        <Input onChange={((event) => {

                            const changedData = {
                                ...asseCalcRisk,
                                type: '1',
                                scoreDividend: event.target.value,
                            }
                            setAssetCalcRisk(changedData)
                            assetCalcProps(changedData)

                        })} />
                    </Form.Item>
                    <b className="score__line">/</b>
                    <Form.Item
                        style={{ width: '50px' }}
                        name="scoreDivisor"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your scoreDivisor!',
                            },
                        ]}
                        onFocus={() => deleteErrorButton('scoreDivisor')}
                    >
                        <Input onChange={((event) => {

                            const changedData = {
                                ...asseCalcRisk,
                                type: '1',
                                scoreDivisor: event.target.value,
                            }
                            setAssetCalcRisk(changedData)
                            assetCalcProps(changedData)
                        })} />
                    </Form.Item>
                </div>
            </Form>
        </>
    )
}